import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { LegendBox, LegendAndTableBox, ResultsSubCategoryTitle } from "components/results/ResultsPartials";
import ChartBadge from "./ChartBadge";

ChartJS.register(ArcElement, Tooltip, Legend);

const CategoryResults = (props) => {
  const { className, title, data } = props;
  const titleAndChartClassName = className ? `results_category_title_and_charts ${className}` : "results_category_title_and_charts";
  let legend = [];
  // if (data[0]?.subCategories)
  //   data.map((s) => {
  //     s.subCategories.forEach((sub) => {
  //       return legend.push(sub);
  //     });
  //     return null;
  //   });
  legend = [...data];
  let sortedData = [...data];
  sortedData.sort((a, b) => b.impact - a.impact);
  return (
    <div className="w-full mt-3" id="categories">
      <div className={titleAndChartClassName}>
        <ResultsSubCategoryTitle title={title} />
        <p className="mb-5 to_print">Classement des catégories par niveau d'émissions, par ordre décroissant.</p>
        <LegendAndTableBox data={legend} />
        <ResultsCharts data={sortedData} />
      </div>
      <LegendBox data={legend} />
    </div>
  );
};

const ResultsCharts = ({ data }) => {
  console.log(data, "data");
  const options = { plugins: { legend: { display: false } } };

  const buildDonutData = (data) => {
    const obj = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
        },
      ],
    };
    data.forEach((item) => {
      obj.labels.push(item.name);
      obj.datasets[0].data.push(item.impact);
      obj.datasets[0].backgroundColor.push(item.color);
      obj.datasets[0].hoverBackgroundColor.push(item.color);
    });
    return obj;
  };

  return (
    <div className="flex mb-6 items-center justify-center gap-5 h-64">
      <Doughnut data={buildDonutData(data)} options={options} />

      {/* <ChartBadge title={`Répartition des émissions par postes du devis`}>
        <MyPieChart data={data} dataKey="impact" unit="kgCO2eq" />
      </ChartBadge> */}
    </div>
  );
};

export default CategoryResults;
