import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getFlattenParameters, getNbAnswers, getNbEmpty, getEstimationTitle } from "../../utils/index.js";
import { referencingInfos } from "../../utils/config.js";
import { BsDownload } from "react-icons/bs";

//Components
import Header from "../../components/partials/Header.jsx";
import HeaderEstimation from "./components/HeaderEstimation.jsx";
import MyIcon from "../../components/partials/MyIcon.jsx";
import NavEstimation from "./components/NavEstimation.jsx";
import SearchBox from "../../components/SearchBox.jsx";
import ProgressBar from "components/ProgressBar";
import TotalBadge from "./components/TotalBadge.jsx";
import { Loader } from "../../components/loader";
// import EstimationForm from "./components/EstimationForm.jsx";
import PayBanner from "../../components/PayBanner.jsx";

//Imports
import ModalChooseImport, { importByType } from "./components/ModalChooseImport.jsx";
import ModalImportFile from "./components/ModalImportFile.jsx";
import ModalImportingData from "./components/ModalImportingData.jsx";

//Hook
import useEstimation from "../../hooks/useEstimation.jsx";

import useStore from "Stores/zustand";
import Parameter from "./components/Parameter.jsx";
import ProjectInformation from "./components/ProjectInformation.jsx";
import slugify from "slugify";
import { Modal } from "components/modal.js";

//used to define what we display for category indexes : categoryCode or default index
const indexTypeCorrespondance = {
  // carbonFootprintType => indexType
  Prévisionnel: "defaultIndex",
  definitif: "defaultIndex",
  cnc: "categoryCode",
  alpha: "categoryCode",
  chedar: "categoryCode",
};

const TIME_LABELS = {
  Prévisionnel: "prévisionnel",
  definitif: "définitif",
};

export const getIndex = (indexType, categoryCode, defaultIndex) => {
  switch (indexType) {
    case "defaultIndex":
      return defaultIndex;
    case "categoryCode":
      return categoryCode;
  }
  return "";
};

const Estimation = () => {
  //user
  const { estimationId } = useParams();
  const { user } = useStore();

  //state
  const [searchedInfo, setSearchedInfo] = useState("");

  //hooks, contexts
  const { estimation, isDataAddedToEstim, dataImported, dataImportedError, importResults, dispatch } = useEstimation(estimationId);
  const navigate = useNavigate();

  //MODALS
  const [isChooseImportOpen, setIsChooseImportOpen] = useState(false);
  const [isImportFileOpen, setIsImportFileOpen] = useState(false);
  const [isImportingDataOpen, setIsImportingDataOpen] = useState(false);
  const [isFormNotCompleteOpen, setIsFormNotCompleteOpen] = useState(false);

  if (!estimation) {
    return <Loader text={"Espace de travail en cours de préparation"} fullPage />;
  }

  const title = getEstimationTitle(estimation); // TO UPDATE getEstimationTitle(estimation)

  const prefixTitle = TIME_LABELS[estimation.information.type] ? `Bilan ${TIME_LABELS[estimation.information.type]}` : "Bilan";
  const headerTitle = `${prefixTitle} - ${title}`;
  const tabTitle = title ? `Seco2 - ${title} - Formulaire de mon estimation carbone` : `Seco2 - Formulaire de mon estimation carbone`;

  const handleValidateEstimation = async () => {
    if (progress < 100) setIsFormNotCompleteOpen(true);
    else {
      await dispatch.validateEstimation();
      navigate(`/estimation/${estimationId}/results`);
    }
  };

  const fillParameters = (category) => {
    category.parameters.forEach((parameter) => {
      parameter.data.forEach((data) => {
        if (!data.value) data.value = "0";
      });
    });
  };

  const fillEstimation = async () => {
    const newEstimation = { ...estimation };
    newEstimation.form.forEach((category) => {
      if (category.parameters) fillParameters(category);
      if (category.subCategories)
        category.subCategories.forEach((subCategory) => {
          if (subCategory.parameters) fillParameters(subCategory);
          if (subCategory.subCategories)
            subCategory.subCategories.forEach((subSubCategory) => {
              if (subSubCategory.parameters) fillParameters(subSubCategory);
            });
        });
    });
    newEstimation.validated = true;
    await dispatch.saveEstimation(newEstimation);
    navigate(`/estimation/${estimationId}/results`);
  };
  const flattenParameters = getFlattenParameters(estimation.form);
  const nbAnswers = getNbAnswers(flattenParameters);
  const nbEmpty = getNbEmpty(flattenParameters);
  const progress = Math.floor((nbAnswers / flattenParameters.length) * 100);
  const indexType = indexTypeCorrespondance[estimation.information.carbonFootprintType];

  const renderImportButton = () => {
    if (!user.hasPaid && !estimation.hasPaid) return <></>;
    if (estimation.files?.length > 0 && !estimation.fileImportedAt)
      return (
        <div className="flex flex-col bg-[#6A96A5] p-5">
          <div className="text-l text-white mb-3">Avantages bilan premium</div>
          <button disabled className="bg-gray-400 text-white flex items-center justify-center gap-3 h-14">
            En cours d'import
          </button>
          <div className="bg-gray-400 text-white text-xs p-2">
            Vos données sont en train d’être importées. Cela peut prendre jusqu’à 24 heures. Vous recevrez un email de notification lorsque cela sera terminé.
          </div>
          <a href="https://calendly.com/charles-gachet-dieuzeide/onboarding-seco2" target="_blank" rel="noreferrer">
            <button className="bg-white w-full mt-2 gap-2 pl-2 h-14 hover:border border-[#103C4B]">Prendre rendez-vous</button>
          </a>
        </div>
      );
    else
      return (
        <div className="flex flex-col bg-[#6A96A5] p-5">
          <div className="text-l text-white mb-3">Avantages bilan premium</div>
          {importByType[estimation.information.carbonFootprintType]?.length > 0 && (
            <button className="blue-button flex items-center justify-center gap-3 h-14" onClick={() => setIsChooseImportOpen(true)}>
              <BsDownload className="text-white" />
              Importer mon budget
            </button>
          )}
          <a href="https://calendly.com/charles-gachet-dieuzeide/onboarding-seco2" target="_blank" rel="noreferrer">
            <button className="bg-white w-full mt-2 gap-2 pl-2 h-14 hover:border border-[#103C4B]">Prendre rendez-vous</button>
          </a>
        </div>
      );
  };

  return (
    <>
      <Helmet>
        <title>{tabTitle}</title>
        <meta name="description" content={referencingInfos.estimation.description} />
      </Helmet>
      <ModalChooseImport
        isOpen={isChooseImportOpen}
        close={() => {
          setIsChooseImportOpen(false);
          navigate(0);
        }}
        estimation={estimation}
        openNext={() => setIsImportFileOpen(true)}
        importExternalDatas={dispatch.importExternalDatas}
        setTestOnFileRead={dispatch.setTestOnFileRead}
        setImportSelected={dispatch.setImportSelected}
      />
      {/* <Modal isShowing={isImportFile} close={closeImportFile}>
        <ModalImportFile importExternalDatas={dispatch.importExternalDatas} openNext={openImportingData} close={closeImportFile} setTestOnFileRead={dispatch.setTestOnFileRead} />
      </Modal> */}

      <ModalImportingData
        isOpen={isImportingDataOpen}
        close={() => setIsImportingDataOpen(false)}
        dataImported={dataImported}
        progress={progress}
        dataImportedError={dataImportedError}
        resetImport={dispatch.resetImport}
        isDataAddedToEstim={isDataAddedToEstim}
        importResults={importResults}
      />

      <ModalFormNotComplete isOpen={isFormNotCompleteOpen} close={() => setIsFormNotCompleteOpen(false)} nbEmpty={nbEmpty} fillEstimation={fillEstimation} />

      <Header>
        <HeaderEstimation title={headerTitle} />
      </Header>
      <main id="estimation" className="w-full grid grid-cols-[calc(30%-5px)_calc(70%-5px)] h-[calc(100vh-4rem)] gap-2.5 p-2.5 bg-backgrounds">
        <section className="w-full h-full overflow-y-auto">
          <div className="w-full grid gap gap-y-4 ">
            {renderImportButton()}
            <SearchBox searchedInfo={searchedInfo} setSearchedInfo={setSearchedInfo} />
            <NavEstimation estimation={estimation} indexType={indexType} />
          </div>
        </section>
        <section className="bg-white p-4 w-full h-full grid overflow-y-auto">
          <PayBanner />
          <div id="form" className="scrollbar overflow-y-scroll">
            {/* <EstimationForm
              searchedInfo={searchedInfo}
              estimation={estimation}
              updateEstimation={dispatch.updateEstimation}
              saveEstimation={dispatch.setEstimation}
              indexType={indexType}
            /> */}

            {!searchedInfo && <ProjectInformation estimation={estimation} saveEstimation={dispatch.setEstimation} />}
            {estimation?.form?.map((category, categoryIndex) => (
              <div key={categoryIndex} className="firstCategory w-full" id={`firstCategory_${categoryIndex + 1}`}>
                <CategoryTitle title={category.name} depth={1} index={getIndex(indexType, category.code, categoryIndex + 1)} id={slugify(category.name)} color={category.color} />
                {category.parameters &&
                  category.parameters.length > 0 &&
                  category.parameters
                    .filter((p) => p.name.toLowerCase().includes(searchedInfo || ""))
                    // QUICK FIX TO REMOVE AFTER
                    .filter((p) => p.data.length > 0)
                    .map((parameter, id) => <Parameter parameter={parameter} key={id} updateEstimation={dispatch.updateEstimation} id={id} />)}
                {category.subCategories &&
                  category.subCategories.length > 0 &&
                  category.subCategories.map((subCategory, subCategoryIndex) => (
                    <div key={subCategoryIndex}>
                      <CategoryTitle
                        title={subCategory.name}
                        depth={2}
                        index={getIndex(indexType, subCategory.code, `${categoryIndex + 1}.${subCategoryIndex + 1}`)}
                        id={slugify(category.name + subCategory.name)}
                        color={category.color}
                      />
                      {subCategory.parameters &&
                        subCategory.parameters.length > 0 &&
                        subCategory.parameters
                          .filter((p) => p.name.toLowerCase().includes(searchedInfo || ""))
                          // QUICK FIX TO REMOVE AFTER
                          .filter((p) => p.data.length > 0)
                          .map((parameter, id) => <Parameter parameter={parameter} key={id} updateEstimation={dispatch.updateEstimation} id={id} />)}
                      {subCategory.subCategories &&
                        subCategory.subCategories.length > 0 &&
                        subCategory.subCategories.map((subSubCategory, subSubCategoryIndex) => (
                          <div key={subSubCategoryIndex}>
                            <CategoryTitle
                              title={subSubCategory.name}
                              depth={3}
                              index={getIndex(indexType, subSubCategory.code, `${categoryIndex + 1}.${subCategoryIndex + 1}.${subSubCategoryIndex + 1}`)}
                              id={slugify(category.name + subCategory.name + subSubCategory.name)}
                              color={category.color}
                            />
                            {subSubCategory.parameters &&
                              subSubCategory.parameters.length > 0 &&
                              subSubCategory.parameters
                                .filter((p) => p.name.toLowerCase().includes(searchedInfo || ""))
                                // QUICK FIX TO REMOVE AFTER
                                .filter((p) => p.data.length > 0)
                                .map((parameter, id) => <Parameter parameter={parameter} key={id} updateEstimation={dispatch.updateEstimation} id={id} />)}
                          </div>
                        ))}
                    </div>
                  ))}
              </div>
            ))}
          </div>
          <footer className="grid grid-cols-3 gap-x-6 items-center self-end pt-4">
            <ProgressBar progress={progress} />
            <TotalBadge number={estimation.form?.reduce((acc, cur) => acc + cur.impact, 0) || 0} />
            <button className="blue-button flex items-center justify-center gap-2 pl-2" onClick={handleValidateEstimation}>
              <MyIcon icon="check" />
              Valider et accéder <br /> à mes résultats
            </button>
          </footer>
        </section>
      </main>
    </>
  );
};

const CategoryTitle = ({ title, depth, index, id, color }) => {
  const titleBgColor = depth === 1 ? color : "transparent";
  const indexBgColor = depth === 2 ? color : "transparent";
  return (
    <div className={`grid grid-cols-[10%_60%_30%] items-center form_row form_row_${depth}`} id={id} style={{ backgroundColor: titleBgColor }}>
      <p style={{ backgroundColor: indexBgColor }}>{index}</p>
      <p className="pr-1.5">{title}</p>
    </div>
  );
};

export default Estimation;

const ModalFormNotComplete = ({ isOpen, close, nbEmpty, fillEstimation }) => {
  const handleValidate = async () => {
    fillEstimation();
    close();
  };
  return (
    <Modal isOpen={isOpen} onClose={close}>
      <h2 className="mb-6 font-bold text-2xl leading-8">Oups... Certaines cases sont restées vides !</h2>

      <p className="font-normal text-base leading-5 mb-4">
        <strong>{nbEmpty} lignes</strong> de données de votre budget sont restées vides.{" "}
      </p>

      <p className="font-normal text-base leading-5 mb-4">
        Si vous n'avez pas de dépenses associées, <strong>souhaitez-vous les remplir automatiquement avec des "0"</strong> ?
      </p>

      <div className="mt-10 gap-x-4 flex ml-auto w-2/3">
        <button className="empty-button flex items-center justify-center gap-2 pl-2 w-full" onClick={close}>
          <MyIcon icon="back" />
          Retour
        </button>
        <button className="blue-button flex items-center justify-center gap-2 pl-2 w-full" onClick={handleValidate}>
          <MyIcon icon="check" />
          Valider
        </button>
      </div>
    </Modal>
  );
};
