import { onlyUnique } from "utils";

const getAverageForm = (forms) => {
  const averageForm = [];

  forms.forEach((form) => {
    form.forEach((category) => {
      let catIndex = averageForm.findIndex((avCategory) => avCategory.name === category.name);
      if (catIndex === -1) {
        averageForm.push({
          name: category.name,
          color: category.color,
          impact: category.impact,
          subCategories: [],
        });
        catIndex = averageForm.length - 1;
      } else {
        averageForm[catIndex].impact += category.impact;
      }

      category.subCategories?.map((subCategory) => {
        const subCatIndex = averageForm[catIndex].subCategories.findIndex((avSubCat) => avSubCat.name === subCategory.name);
        if (subCatIndex === -1) {
          averageForm[catIndex].subCategories.push({
            name: subCategory.name,
            color: subCategory.color,
            impact: subCategory.impact,
          });
        } else {
          averageForm[catIndex].subCategories[subCatIndex].impact += subCategory.impact;
        }
      });
    });
  });

  averageForm.map((category) => {
    category.impact = category.impact / forms.length;
    category.subCategories.map((subCategory) => {
      subCategory.impact = subCategory.impact / forms.length;
      return subCategory;
    });
    return category;
  });
  return averageForm;
};

const getEstimationsInfos = (estimations, type) => {
  const estimationsValidated = estimations.filter((estimation) => estimation.validated);

  let estimationsInfos = {
    budget: [],
    duration: [],
    emissionsPerEuro: [],
    totalAmount: [],
    totalEmissions: [],
  };
  estimationsValidated.forEach((estimation) => {
    const totalImpact = estimation.form?.reduce((acc, cur) => acc + cur.impact, 0) || 0;
    const totalAmount = estimation.form?.reduce((acc, cur) => acc + cur.amount, 0) || 0;
    estimationsInfos.totalEmissions.push(totalImpact);
    estimationsInfos.totalAmount.push(totalAmount);
  });

  if (type === "perType") {
    const forms = estimationsValidated.map((estimation) => estimation.form);
    estimationsInfos.averageEstimation = {
      form: getAverageForm(forms),
    };
  }
  return estimationsInfos;
};

export const getEstimationsInfosForAdmin = (estimations) => {
  const estimationsInfos = getEstimationsInfos(estimations, "general");

  //set estimations per type
  const estimationsTypes = estimations
    .filter((estimation) => {
      if (!estimation.information) console.log(estimation);
      return estimation.validated && estimation.information.carbonFootprintType;
    })
    .map((estimation) => estimation.information?.carbonFootprintType)
    .filter(onlyUnique);

  let estimationsPerType = new Array(estimationsTypes.length);
  estimationsTypes.forEach((estimationType, i) => {
    estimationsPerType[i] = estimations.filter((estimation) => estimation.validated && estimation.information.carbonFootprintType === estimationType);
  });

  let estimationsInfosPerType = new Array(estimationsTypes.length);
  estimationsPerType.forEach((estimations, i) => {
    estimationsInfosPerType[i] = getEstimationsInfos(estimations, "perType");
  });

  return {
    estimationsInfos,
    estimationsPerType,
    estimationsInfosPerType,
  };
};
