import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import api from "services/api";

export default function ViewUsers() {
  const [user, setUser] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      api
        .get(`/admin/users/${id}`)
        .then((res) => {
          if (res.ok) {
            setUser(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setUser(undefined);
        });
    })();
  }, [id]);

  if (user === undefined) return <div className="p-6 bg-white overflow-auto rounded-lg">Utilisateur non trouvé</div>;
  if (!user) return <div className="">chargement...</div>;

  return (
    <div className="p-6 bg-white overflow-auto rounded-lg">
      {Object.keys(user).map((key) => (
        <div className="flex items-start space-x-2" key={key}>
          <span className="font-bold whitespace-nowrap">{key} : </span>
          <span className="break-words overflow-hidden">{user[key]}</span>
        </div>
      ))}
    </div>
  );
}
