import React, { useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import api from "../../../services/api";
import { BiCrown } from "react-icons/bi";

import { STRIPE_LINK_ONE_SHOT_FORMULA } from "../../../config";
import MyIcon from "components/partials/MyIcon";
import { Loader } from "components/loader";
import { Modal } from "components/modal";

const ModalBuyingBilanFormule = ({ close, isOpen }) => {
  const [estimations, setEstimations] = useState([]);

  const getEstimations = () => {
    api
      .get("/estimations/")
      .then((response) => {
        if (response.ok) setEstimations(response.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEstimations();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={close} className="h-60 w-[600px]">
      <h2 className="mb-6 font-bold text-2xl leading-8">Choisissez le bilan à passer en premium</h2>
      <div className="w-1/2">
        <SelectBilan
          options={estimations?.map((e) => {
            return { label: e.information.title, value: e._id, hasPaid: e.hasPaid };
          })}
          close={close}
        />
      </div>
    </Modal>
  );
};

export default ModalBuyingBilanFormule;

const SelectBilan = ({ options, close }) => {
  const [value, setValue] = useState("");
  const [done, setDone] = useState(true);
  const [error, setError] = useState("");

  const selectBilan = async (id) => {
    try {
      setDone(false);
      const res = await api.put("/estimations/select/" + id, { estimation: { isSelected: true } });
      setError("");
      setDone(true);
    } catch (e) {
      console.log(e);
      setDone(true);
      setError("Oups server error, an error occured. Please Try Again.");
    }
  };

  useEffect(() => {
    if (value.length) selectBilan(value);
  }, [value]);

  return (
    <>
      {done ? (
        <Listbox value={value} onChange={setValue}>
          <div className="relative">
            <Listbox.Button className="w-full h-10 border border-blue-dark rounded-sm px-1.5 py-2.5 relative">
              <span className="truncate text-left block absolute top-1/2 -translate-y-1/2">
                {value.length ? options.find((e) => e.value === value)?.label : "Choisis un bilan"}
              </span>
            </Listbox.Button>
            <div className="w-6 h-6 border-none rounded-sm top-1/2 -translate-y-1/2 absolute right-1.5 pointer-events-none bg-blue-dark">
              <img src="/images/icons/drop down.svg" alt="recherche" />
            </div>
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute mt-1 z-10 max-h-40 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-back-light-blue border border-blue-dark">
                {options.map((opt, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active }) => `relative cursor-default select-none py-2 px-1.5 pr-4 ${active ? "bg-back-light-blue text-blue-dark" : "text-blue-dark"}`}
                    value={opt.value}>
                    {({ selected }) => {
                      return (
                        <button className="flex justify-between w-full disabled:cursor-not-allowed" disabled={opt.hasPaid}>
                          <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{opt.label}</span>
                          {opt.hasPaid ? <BiCrown className="w-1/5" /> : <></>}
                        </button>
                      );
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          <p className="text-sm italic text-red">{error}</p>
        </Listbox>
      ) : (
        <Loader />
      )}
      <div className="absolute bottom-5 right-5 flex w-2/5 gap-4 justify-end ml-auto">
        <button className="empty-button w-full flex items-center gap-2 justify-center pl-2" onClick={close}>
          <MyIcon icon="back" />
          Annuler
        </button>
        <a href={`${value.length ? STRIPE_LINK_ONE_SHOT_FORMULA : "#"}`} className=" w-full  gap-2 pl-2 ">
          <button type="button" disabled={!value.length} className={`blue-button w-full flex items-center justify-center  disabled:cursor-not-allowed`}>
            <MyIcon icon="check" />
            Valider
          </button>
        </a>
      </div>
    </>
  );
};
