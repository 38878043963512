import XMLParser from "react-xml-parser";

const getAccounts = (children) => {
  let accounts = null;
  for (let i = 0; i < children.length; i++) {
    if (children[i].name === "accounts" && children[i].children) {
      // TODO : check if infos in accounts are ok
      return children[i].children;
    } else {
      if (children[i].children) {
        accounts = getAccounts(children[i].children);
        if (accounts) {
          return accounts;
        }
      }
    }
  }
};

export const LM_MagicMovieXML = {
  testOnFileSubmit: (file) => {
    return file.type.includes("xml");
  },
  importFunction: (file, setDataImported) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = (evt) => {
      const readerData = evt.target.result;
      var xmlJson = new XMLParser().parseFromString(readerData);
      setDataImported(xmlJson);
    };
  },
  getImportedData: (datas) => {
    return new Promise(function (resolve) {
      const accounts = getAccounts(datas.children);
      //get key / value pair from datas
      const formattedDatas = {};
      accounts.forEach((account) => {
        if (account.children) {
          const aNumberChild = account.children.filter((child) => child.name === "aNumber");
          const aTotalChild = account.children.filter((child) => child.name === "aTotal");
          const aDescription = account.children.filter((child) => child.name === "aDescription");
          if (aNumberChild && aTotalChild && parseInt(aTotalChild[0].value) > 0) {
            formattedDatas[aNumberChild[0].value] = {
              value: aTotalChild[0].value,
              description: aDescription[0].value,
            };
          }
        }
      });
      resolve(formattedDatas);
    });
  },
  testOnFileRead: (datas) => {
    const isAccountsInChildren = (children) => {
      if (Array.isArray(children) && children.filter((child) => child.name === "accounts").length >= 1) {
        return true;
      } else {
        if (children.children) {
          for (let i = 0; i < children.children.length; i++) {
            if (children.children[i].name === "accounts" && children.children[i].children) {
              // TODO : check if infos in accounts are ok
              return true;
            }
            if (isAccountsInChildren(children.children[i])) {
              return true;
            }
          }
        }
      }
      return false;
    };
    return isAccountsInChildren(datas);
  },
};
