import React, { useState } from "react";

import useStore from "Stores/zustand";
import plausibleEvent from "../../services/plausible";
import { IoCheckmarkSharp } from "react-icons/io5";

//Modals
import ModalBuyingBilanFormule from "./components/ModalBuyingBilanFormule";

import api from "../../services/api";

const Formulas = () => {
  const { user, setUser } = useStore();
  const [estimationSelected, setEstimationSelected] = useState();
  const [isBuyingOpen, setIsBuyingOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (formula) => {
    try {
      if (formula === "basic") plausibleEvent("RequestFormulaBasic");
      if (formula === "premium") plausibleEvent("RequestFormulaPremium");
      setLoading(true);
      const { ok, data } = await api.post(`/auth/${user._id}/formula-request`, { formulaRequest: formula });
      setLoading(false);
      if (ok) {
        setUser(data);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div>
      <ModalBuyingBilanFormule isOpen={isBuyingOpen} close={() => setIsBuyingOpen(false)} estimationSelected={estimationSelected} setEstimationSelected={setEstimationSelected} />

      <h2 className="font-bold leading-5 text-base mb-3">Nos formules Seco2</h2>
      {["basic", "premium"].includes(user.formulaRequest) && user.formula === "freemium" ? (
        <div className="p-6 bg-[#F1FEF3] border border-[#73AE7C] rounded-md mb-3 text-[#73AE7C]">
          <div>
            Votre demande de <b>formule payante a bien été envoyée à nos équipes</b>. Elles vont revenir vers vous par email dès que possible.
          </div>
          <div>
            Un email de confirmation vous a été envoyé sur <b>{user.email}</b>
          </div>
        </div>
      ) : null}
      <div className="md:grid md:grid-cols-3 gap-2.5">
        <div className="p-6 bg-[#A0ABC2] rounded-md h-fit mb-2">
          <div className="text-blue-dark text-2xl font-bold text-center">Accès Freemium</div>
          <div className="text-blue-dark text-base font-normal text-center">Gratuit</div>
          <div className="flex justify-center items-center h-28">
            <div className="text-white text-base font-bold text-center">Par défaut</div>
          </div>

          <div className="flex gap-2 text-blue-dark text-base font-normal">
            <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
              <IoCheckmarkSharp className="text-green-600" />
            </div>
            <div className="flex-1">
              <b>Accès Seco2</b> - homologué CNC
            </div>
          </div>
        </div>
        <div className="p-6 bg-[#D7FCB3] rounded-md h-fit mb-2">
          <div className="text-blue-dark text-2xl font-bold text-center">Accès Basique</div>
          <div className="text-blue-dark text-base font-normal text-center">800 € HT /Projet</div>
          <div className="flex justify-center items-center h-28">
            <button
              disabled={loading}
              onClick={() => handleSubmit("basic")}
              className="rounded-md bg-[#4A5DED] text-white font-bold py-2 px-5 disabled:cursor-not-allowed disabled:opacity-40">
              Obtenir l'accès
            </button>
          </div>
          <div>
            <div className="flex gap-2 text-blue-dark text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Accès Seco2</b> - homologué CNC
              </div>
            </div>
            <div className="flex gap-2 text-blue-dark text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Import automatique</b> des données monétaires prévisionnelles
              </div>
            </div>
            <div className="flex gap-2 text-blue-dark text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Aide au remplissage</b> des données physiques prévisionnelles
              </div>
            </div>
            <div className="flex gap-2 text-blue-dark text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>30 min de Kick-off</b> Projet
              </div>
            </div>
            <div className="flex gap-2 text-blue-dark text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Chat live 5/7</b>
              </div>
            </div>
          </div>
        </div>
        <div className="p-6 bg-[#1F2C40] rounded-md h-fit mb-2">
          <div className="text-white text-2xl font-bold text-center">Accès Premium</div>
          <div className="text-white text-base font-normal text-center">1800 € HT /Projet</div>
          <div className="flex justify-center items-center h-28">
            <button
              disabled={loading}
              onClick={() => handleSubmit("premium")}
              className="rounded-md bg-[#4A5DED] text-white font-bold py-2 px-5 disabled:cursor-not-allowed disabled:opacity-40">
              Obtenir l'accès
            </button>
          </div>
          <div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Accès Seco2</b> - homologué CNC
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Import automatique</b> des données monétaires prévisionnelles
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Aide au remplissage</b> des données physiques prévisionnelles
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>30 min de Kick-off</b> Projet
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Chat live 5/</b>7
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Accès à la méthodologie de collecte</b> des données Flying Secoya
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Aide au remplissage</b> des données <b>physiques prévisionnelles</b> à partir des données d'activités
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Intégration</b> des données <b>physiques définitives</b> dans Seco2
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Vérification & consolidation des données</b> complètes par un Expert Carbone Flying Secoya
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                30 min de <b>Closing Projet</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Formulas;
