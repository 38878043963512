import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconButton from "../../../components/partials/IconButton";
import HeaderTitle from "../../../components/partials/HeaderTitle";

//Modals
import ModalHelp from "./ModalHelp";
import { useNavigate } from "react-router-dom";
//Hook
import { useEffect } from "react";

const HeaderEstimation = ({ title, withHelp = true }) => {
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const search = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  useEffect(() => {
    if (search.get("help") === "true") {
      navigate(window.location.pathname);
      setIsHelpOpen(true);
    }
  }, []);
  return (
    <div id="header_estimation" className="grid grid-cols-[300px_auto_300px] items-center h-full">
      <ModalHelp isOpen={isHelpOpen} close={() => setIsHelpOpen(false)} />

      <Link to="/">
        <IconButton icon="back" text="Retour au tableau de bord" />
      </Link>
      <HeaderTitle title={title} />

      {withHelp && (
        <div className="flex items-center gap-10 justify-end">
          <div className="flex items-center gap-2.5 justify-end">
            <IconButton icon="check" color={"var(--turquoise)"} />
            <span className="text-[var(--turquoise)] font-semibold">
              Sauvegarde
              <br />
              automatique
            </span>
          </div>
          <IconButton icon="help" text="Aide" onClick={() => setIsHelpOpen(true)} />
        </div>
      )}
    </div>
  );
};

export default HeaderEstimation;
