export const getProgress = (matrice, progress) => {
  if (matrice.parameters && matrice.parameters.length > 0) {
    matrice.parameters.forEach((param) => {
      progress.total += param.options.reduce((acc, o) => (o.value !== undefined ? acc + o.value * o.factor : acc), 0);
      progress.filledCount += param.options.some((o) => o.value !== undefined) ? 1 : 0;
      progress.parametersCount++;
    });
  }
  if (matrice.categories && matrice.categories.length > 0) {
    matrice.categories.forEach((subCat) => getProgress(subCat, progress));
  }
  if (Array.isArray(matrice)) matrice.forEach((cat) => getProgress(cat, progress));
};

export const getImpact = (matrice) => {
  let res = 0;
  if (matrice.parameters && matrice.parameters.length > 0) {
    matrice.parameters.forEach((param) => {
      res += param.options.reduce((acc, o) => (o.value !== undefined ? acc + o.value * o.factor : acc), 0);
    });
  }
  if (matrice.categories && matrice.categories.length > 0) {
    matrice.categories.forEach((subCat) => {
      res += getImpact(subCat);
    });
  }
  if (Array.isArray(matrice)) matrice.forEach((cat) => (res += getImpact(cat)));

  return res;
};

export const getAmount = (matrice) => {
  let res = 0;

  if (matrice.parameters && matrice.parameters.length > 0) {
    matrice.parameters.forEach((param) => {
      res += param.options.reduce((acc, o) => (o.unit === "€" && o.value !== undefined ? acc + o.value : acc), 0);
    });
  }
  if (matrice.categories && matrice.categories.length > 0) {
    matrice.categories.forEach((subCat) => {
      res += getAmount(subCat);
    });
  }
  if (Array.isArray(matrice)) matrice.forEach((cat) => (res += getAmount(cat)));

  return res;
};

export const getUnit = (value, units) => {
  if (value === 0) return { number: 0, unit: units[0] };
  const log10 = Math.floor(Math.log10(value));
  const index = Math.min(Math.floor(log10 / 3), units.length - 1);
  const unit = units[index];
  const number = value / Math.pow(10, index * 3);
  return { number, unit };
};

export const getCO2Equivalence = (value) => {
  if (value > 1000) {
    return {
      value: value / 1000 / 0.5,
      unit: "",
      description: "vols Paris / New York",
    };
  } else {
    return {
      value: value * 5.181,
      unit: "kms",
      description: "en voiture (moyenne)",
    };
  }
};
