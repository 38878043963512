import React from "react";

const InfoBox = (props) => {
  const { children, error } = props;

  const errorStatuses = [400, 403, 500, 401];

  return (
    <div
      className={`border border-blue-border border-l-4 border-l-[#b1cf5f] bg-white flex flex-col py-4 px-2.5 mb-4 ${
        error === "frontError" || errorStatuses.includes(error.status) ? "border border-blue-border rounded-sm border-l-4 border-l-[#b1cf5f]" : "info"
      }`}>
      <p>{children}</p>
    </div>
  );
};

export default InfoBox;
