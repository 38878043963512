import React from "react";
import { ResponsiveTreeMapHtml } from "@nivo/treemap";

import { formatNumber } from "utils";

const Treemap = ({ data }) => {
  let newData = [];
  let index = 0;

  for (let i = 0; i < data.length; i++) {
    data[i].forEach((sub) => {
      index++;
      return newData.push({ ...sub, index });
    });
  }

  if (newData.reduce((a, b) => a + b.impact, 0) === 0) return null;

  return (
    <div id="treemap" className="w-full">
      <ResponsiveTreeMapHtml
        data={{ name: "root", children: newData }}
        identity="index"
        value="impact"
        valueFormat=".02s"
        margin={0}
        enableLabel={false}
        labelSkipSize={12}
        labelTextColor="var(--textDarkBlue)"
        parentLabelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        borderColor={{ from: "color", modifiers: [["darker", 0.1]] }}
        leavesOnly={true}
        innerPadding={5}
        borderWidth={0}
        colors={(node) => node.data.color}
        isInteractive={true}
        animate={false}
        nodeOpacity={0.9}
        tooltip={({ node }) => <SecoyaTooltip color={node.data.color} name={node.data.name} value={node.data.impact} unit={"kgCO2eq"} />}
        theme={{
          tooltip: {
            container: {
              padding: 0,
            },
          },
        }}
      />
    </div>
  );
};

export default Treemap;

const SecoyaTooltip = ({ color, name, value, unit }) => {
  return (
    <div className="flex items-center py-1 px-2 bg-white border-blue-border border rounded-sm">
      {color && <div style={{ backgroundColor: color }}></div>}
      <p>{name + " : "}</p>
      <strong>{formatNumber(value)}</strong>
      <p>{" " + unit}</p>
    </div>
  );
};
