import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BsFillFileEarmarkFill, BsChevronLeft } from "react-icons/bs";

import MyIcon from "components/partials/MyIcon";
import InfoBox from "components/Base/InfoBox";
import { LM_MagicMovieXML } from "../../../utils/Imports/LM_MagicMovieXML";

import api from "services/api";
import { Modal } from "components/modal";

// Ugly beause it was made like this before --> objective is to remove this
const magicMovieImport = {
  name: "Budget Movie Magic Budgeting XML",
  importFunction: LM_MagicMovieXML.importFunction,
  getImportedData: LM_MagicMovieXML.getImportedData,
  testOnFileSubmit: LM_MagicMovieXML.testOnFileSubmit,
  testOnFileRead: LM_MagicMovieXML.testOnFileRead,
  introText:
    "Si vous utilisez <strong>Movie Magic Budgeting</strong> pour établir votre budget de tournage, vous pouvez extraire vos données au format <b>.xml</b> afin de pré-remplir automatiquement votre estimation.",
  helperText: {
    introduction: "<b>Comment extraire mes données en .xml ?</b>",
    process: [
      "rendez-vous sur votre devis sur Movie Magic Budgeting",
      'cliquez sur "File"',
      'cliquez sur "Export XML (detailed)"',
      'votre fichier s\'enregistre sous la forme "MMB XML Export"',
      "vous n'aurez plus qu'à choisir ce fichier pour l'import dans Seco2!",
    ],
  },
  helperURL: "/TOBEDEFINED",
  inputAccepted: ".xml",
};

export const importByType = {
  Prévisionnel: ["magic-movie", "cnc-devis"],
  definitif: ["magic-movie", "cnc-devis"],
  cnc: ["magic-movie"],
  alpha: [],
  chedar: [],
};

const importLabel = {
  "magic-movie": "Budget Movie Magic Budgeting XML",
  "cnc-devis": "CNC Devis (csv/excel)",
};

const ModalChooseImport = ({ isOpen, close, openNext, estimation, importExternalDatas, setTestOnFileRead, setImportSelected }) => {
  const [selected, setSelected] = useState(importByType[estimation.information.carbonFootprintType][0] || "magic-movie");
  const [step, setStep] = useState(0);

  const handleSubmit = () => {
    if (!selected) return;
    if (selected === "magic-movie") {
      setImportSelected(magicMovieImport);
      setStep(1);
    } else if (selected === "cnc-devis") {
      setStep(1);
    }
  };

  if (step === 0)
    return (
      <Modal isOpen={isOpen} onClose={close}>
        <h2 className="mb-6 font-bold text-2xl leading-8">Imports de fichiers externes</h2>
        <p className="font-normal text-base leading-5 mb-4">Quel type de fichier souhaitez-vous importer ?</p>

        <form onSubmit={handleSubmit}>
          {importByType[estimation.information.carbonFootprintType].map((i) => (
            <div key={i} className="space-x-3.5">
              <input
                type="radio"
                id={i}
                className="w-4 h-4 border-blue-dark text-blue-dark focus:ring-indigo-600"
                value={i}
                checked={i === selected}
                onChange={(e) => setSelected(e.target.value)}
              />
              <label htmlFor={i}>{importLabel[i]}</label>
            </div>
          ))}

          <div className="flex items-center gap-x-4 mt-10">
            <button className="empty-button flex items-center justify-center w-full" type="button" onClick={close}>
              <BsChevronLeft className="mr-3" />
              Retour
            </button>
            <button className="blue-button flex items-center justify-center gap-2 pl-2 w-full" type="submit">
              <MyIcon icon="check" />
              Valider
            </button>
          </div>
        </form>
      </Modal>
    );
  else if (selected === "magic-movie")
    return <MagicMovieImport close={close} openNext={openNext} importExternalDatas={importExternalDatas} setTestOnFileRead={setTestOnFileRead} setStep={setStep} />;
  else return <CNCImport close={close} estimation={estimation} setStep={setStep} />;
};

const MagicMovieImport = ({ close, openNext, importExternalDatas, setTestOnFileRead, setStep }) => {
  const { testOnFileSubmit, introText, helperText, helperURL, inputAccepted, importFunction, testOnFileRead } = magicMovieImport;

  const [error, setError] = useState(null);
  const fileInput = useRef();

  setTestOnFileRead(() => testOnFileRead);

  const onChange = () => {
    const file = fileInput.current.files[0];
    if (testOnFileSubmit(file)) {
      importExternalDatas(importFunction, file);
      close();
      openNext();
    } else {
      setError({
        error: "frontError",
        message: "Le fichier importé n'est pas au format attendu",
      });
    }
  };

  const createMarkup = (string) => {
    return { __html: string };
  };

  return (
    <div id="modal_import_file">
      <h2 className="mb-6 font-bold text-2xl leading-8">Importez vos données budgétaires</h2>

      {error && <InfoBox error={error.error}>{error.message}</InfoBox>}

      <p className="font-bold text-base leading-5 mb-4" dangerouslySetInnerHTML={createMarkup(introText)}></p>

      <div className="mb-4 flex items-start">
        <img src="/images/icons/checkBlue.svg" alt="check" className="mr-4" />
        <Link to={helperURL}>
          <p dangerouslySetInnerHTML={createMarkup(helperText.introduction)} />
        </Link>
      </div>

      <ul>
        {helperText.process.map((step, i) => (
          <li key={i}>{step}</li>
        ))}
      </ul>

      <div className="w-2/3 flex items-center justify-end ml-auto mt-10 gap-2">
        <button className="empty-button flex items-center justify-center gap-2 pl-2 w-full" onClick={() => setStep(0)}>
          <BsChevronLeft className="mr-3" />
          Retour
        </button>

        <input accept={inputAccepted} id="contained-button-file" type="file" ref={fileInput} onChange={onChange} style={{ display: "none" }} value="" />
        <label htmlFor="contained-button-file">
          <button className="blue-button flex items-center justify-center gap-2 pl-2 w-full" onClick={() => fileInput.current.click()}>
            <MyIcon icon="importIcon" />
            Choix du fichier
          </button>
        </label>
      </div>
    </div>
  );
};

const CNCImport = ({ close, estimation, setStep }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleSubmit = async () => {
    try {
      if (!file || loading) return;
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      await api.postFormData(`/estimations/${estimation._id.toString()}/import-cnc`, formData);
      setDone(true);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <h2 className="mb-6 font-bold text-2xl leading-8">Importez vos données budgétaires</h2>
      {!done ? (
        <div className="mt-6">
          <p className="font-bold text-base leading-5 mb-4">CNC Devis (csv/excel)</p>
          <p className="font-normal text-base leading-5 mb-4">Vous pouvez importer un fichier au format csv ou excel</p>
          <label htmlFor="fileInput" className="block text-lg font-semibold text-gray-700">
            Télécharger un fichier
          </label>
          <div className="mt-2">
            <label className="flex items-center w-80 px-4 py-2 bg-white text-sm text-gray-700 rounded-md border border-gray-300 shadow-sm cursor-pointer">
              <BsFillFileEarmarkFill className="mr-4" />
              <span className="ml-2">Choisir un fichier (.csv, .xlsx, .xls)</span>
              <input id="fileInput" type="file" className="sr-only" accept=".csv, .xlsx, application/vnd.ms-excel" onChange={handleFileUpload} />
            </label>
          </div>
          {file && (
            <div className="mt-4">
              <p className="text-sm text-gray-500">Fichier sélectionné : {file.name}</p>
            </div>
          )}
          <div className="flex items-center gap-x-4 mt-10">
            <button className="empty-button flex items-center justify-center flex-1" type="button" onClick={() => setStep(0)}>
              <BsChevronLeft className="mr-3" />
              Retour
            </button>
            <button className="blue-button flex-1" onClick={handleSubmit} disabled={loading}>
              {loading ? "Chargement..." : "Importer"}
            </button>
          </div>
        </div>
      ) : (
        <>
          <p>Votre document a été transmis, il sera traité sous 24h</p>
          <button className="blue-button mt-6" onClick={close}>
            Terminer
          </button>
        </>
      )}
    </div>
  );
};

export default ModalChooseImport;
