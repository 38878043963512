import React, { useRef } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MyIcon from "components/partials/MyIcon";
import { Modal } from "components/modal";

const HeaderSlider = ({ close }) => {
  return (
    <header id="slider_header">
      <img src="/images/Logo-Secoya-01.png" alt="logo secoya" />
      <button className="w-[200px] flex items-center justify-center gap-2 pl-2" onClick={close}>
        <MyIcon icon="back" />
        Retour
      </button>
    </header>
  );
};

const sliderImages = ["/images/help/Step1.png", "/images/help/Step2.png", "/images/help/Step3.png", "/images/help/Step4.png", "/images/help/Step5.png", "/images/help/Step6.png"];

const ModalHelp = ({ isOpen, close }) => {
  const slider = useRef();

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    dotsClass: "slick-dots help_slider_dots",
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <div className="w-[56rem]">
        <Slider ref={slider} {...settings}>
          {sliderImages.map((sliderImage, i) => (
            <div key={i} className="slider_body">
              <HeaderSlider close={close} />
              <section className="slider_content">
                <img src={sliderImage} alt="check" />
              </section>
            </div>
          ))}
        </Slider>
      </div>
    </Modal>
  );
};

export default ModalHelp;
