import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import validator from "validator";
import { toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import useStore from "../../Stores/zustand";
import api from "services/api";
import Logo from "../../assets/img/logo-bleu.png";

const Signin = () => {
  const { setUser } = useStore();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const validate = (values, setErrors) => {
    const errors = {};
    if (!validator.isEmail(values.email)) errors.email = "Adresse email invalide";
    if (validator.isEmpty(values.password)) errors.password = "Mot de passe requis";
    setErrors(errors);
    return !errors.email && !errors.password;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    api
      .post("/auth/signin", values)
      .then((res) => {
        if (res.ok) {
          setUser(res.data);
          navigate("/");
        } else {
          setErrors({ email: "Adresse email ou mot de passe incorrect", password: "Adresse email ou mot de passe incorrect" });
        }
      })
      .catch(() => toast.error("Une erreur est survenue"));
  };

  return (
    <div className="flex flex-col items-center">
      <img src={Logo} alt="Seco2" className="w-44" />
      <p className="text-gray-500">Connectez-vous</p>

      <Formik initialValues={{ email: "", password: "" }} onSubmit={handleSubmit}>
        {({ values, errors, handleChange }) => (
          <Form className="my-4 flex flex-col items-center w-80">
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-1" htmlFor="email">
                E-mail
              </label>
              <input className="input border border-gray-400" name="email" value={values.email} onChange={handleChange} placeholder="john@ugc.fr" />
              {errors.email && <div className="flex items-center text-sm text-red-500">{errors.email}</div>}
            </div>
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-1" htmlFor="password">
                Mot de passe
              </label>
              <div className="relative w-full">
                <input
                  className="input w-full border border-gray-400 pr-10"
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Votre mot de passe"
                />
                {passwordVisible ? (
                  <AiFillEyeInvisible className="absolute right-3 top-1.5 z-10 cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(false)} />
                ) : (
                  <AiFillEye className="absolute right-3 top-1.5 z-10 cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(true)} />
                )}
              </div>
              {errors.password && <div className="flex items-center text-sm text-red-500">{errors.password}</div>}
            </div>
            <p>
              Mot de passe oublié ?{" "}
              <Link to="/forgot-password" className="font-bold text-[#2791B5] hover:underline">
                Réinitialisez votre mot de passe
              </Link>
            </p>

            <button className="blue-button my-8 w-full text-lg" type="submit">
              Connexion
            </button>
          </Form>
        )}
      </Formik>
      <p>
        Pas encore de compte ?{" "}
        <Link to="/signup" className="font-bold text-[#2791B5] hover:underline">
          Inscrivez-vous
        </Link>
      </p>
      <button className="text-xs text-white mt-3" onClick={() => methodDoesNotExistseco2()}>
        Break the world
      </button>
    </div>
  );
};
export default Signin;
