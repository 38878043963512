import React from "react";
import { ResponsiveTreeMapHtml } from "@nivo/treemap";
import { SecoyaTooltip } from "./ResultsPartials";

const Treemap = ({ data }) => {
  let newData = [];
  let index = 0;
  data.map((s) => {
    s.subCategories?.forEach((sub) => {
      index++;
      return newData.push({ ...sub, index });
    });
    return null;
  });
  const finalObject = {
    name: "root",
    children: newData,
  };

  const myColor = (node) => {
    return node.data.color;
  };

  const myTooltip = ({ node }) => {
    return <SecoyaTooltip color={node.data.color} name={node.data.name} value={node.data.impact} unit={"kgCO2eq"} />;
  };

  return (
    <ResponsiveTreeMapHtml
      data={finalObject}
      identity="index"
      value="impact"
      valueFormat=".02s"
      margin={0}
      enableLabel={false}
      labelSkipSize={12}
      labelTextColor="var(--textDarkBlue)"
      parentLabelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
      borderColor={{ from: "color", modifiers: [["darker", 0.1]] }}
      leavesOnly={true}
      innerPadding={5}
      borderWidth={0}
      colors={myColor}
      isInteractive={true}
      animate={false}
      nodeOpacity={0.9}
      tooltip={myTooltip}
      theme={{
        tooltip: {
          container: {
            padding: 0,
          },
        },
      }}
    />
  );
};

export default Treemap;
