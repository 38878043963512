import React, { useState } from "react";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

const ContentExpand = ({ title, children }) => {
  const [show, setShow] = useState(false);
  return (
    <section className="w-full flex flex-col pb-4">
      <div
        className={`${show && "bg-back-light-blue"} transition-colors h-14 px-4 border border-blue-border mb-4 flex justify-between items-center cursor-pointer`}
        onClick={() => setShow(!show)}>
        <p className="font-bold text-base leading-5">{title}</p>
        {show ? <MdExpandLess /> : <MdExpandMore />}
      </div>
      <div className={show ? "block ml-4" : "hidden"}>{children}</div>
    </section>
  );
};

export default ContentExpand;
