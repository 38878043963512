import React, { useState } from "react";
import validator from "validator";
import { Formik, Form } from "formik";
import { IoCheckmarkSharp } from "react-icons/io5";
import { toast } from "react-toastify";

import useStore from "../../../Stores/zustand";
import api from "../../../services/api";
import plausibleEvent from "../../../services/plausible";
import { Modal } from "../../../components/modal";
import { Loader } from "components/loader";
import Logo from "../../../assets/img/logo-bleu.png";

export const InfosModal = ({ isOpen, close }) => {
  const [step, setStep] = useState("infos");

  return (
    <Modal onClose={() => null} isOpen={isOpen} showCross={false} className="p-12 max-h-[90%] overflow-y-scroll">
      <div className="">
        <div className="flex flex-col items-center justify-center">
          <img className="w-44" src={Logo} />
          <div className="flex flex-col w-full items-center justify-center gap-4 mb-10">
            <p className="text-xl font-medium text-blue-dark">À propos de vous</p>
            <div className={"h-1 w-full rounded-lg bg-blue-dark"} />
          </div>
          {{
            infos: <UserInfos setStep={setStep} />,
            formula: <FormulaRequest close={close} setStep={setStep} />,
            sent: <FormulaRequestSent close={close} />,
          }[step || "infos"] || <UserInfos close={close} />}
        </div>
      </div>
    </Modal>
  );
};

const UserInfos = ({ setStep }) => {
  const { user, setUser } = useStore();
  const [loading, setLoading] = useState(false);

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.lastName)) errors.lastName = "Ce champ est requis";
    if (validator.isEmpty(values.firstName)) errors.firstName = "Ce champ est requis";
    if (validator.isEmpty(values.companyName)) errors.companyName = "Ce champ est requis";
    setErrors(errors);
    return !errors.lastName && !errors.firstName && !errors.companyName;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    if (loading) return;
    setLoading(true);
    api
      .put(`/auth/${user._id}/onboard`, values)
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          setUser(res.data);
          setStep("formula");
        } else {
          setErrors({ error: "Une erreur est survenue" });
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Une erreur est survenue");
      });
  };
  return (
    <Formik
      initialValues={{
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        companyName: user.companyName || "",
        companyRole: user.companyRole || "",
        phoneNumber: user.phoneNumber || "",
      }}
      onSubmit={handleSubmit}>
      {({ values, errors, handleChange }) => (
        <Form className="flex flex-col items-center">
          <div className="grid grid-cols-2 gap-x-6 gap-y-4 w-[48rem]">
            <div className="flex flex-col w-full">
              <label className="mb-1" htmlFor="firstName">
                Prénom*
              </label>
              <input className="input border border-gray-400" name="firstName" value={values.firstName} onChange={handleChange} placeholder="John" />
              {errors.firstName && <div className="flex items-center text-sm text-red-500">{errors.firstName}</div>}
            </div>
            <div className="flex flex-col w-full">
              <label className="mb-1" htmlFor="lastName">
                Nom*
              </label>
              <input className="input border border-gray-400" name="lastName" value={values.lastName} onChange={handleChange} placeholder="Doe" />
              {errors.lastName && <div className="flex items-center text-sm text-red-500">{errors.lastName}</div>}
            </div>
            <div className="flex flex-col w-full">
              <label className="mb-1" htmlFor="companyName">
                Société*
              </label>
              <input className="input border border-gray-400" name="companyName" value={values.companyName} onChange={handleChange} placeholder="UGC" />
              {errors.companyName && <div className="flex items-center text-sm text-red-500">{errors.companyName}</div>}
            </div>
            <div className="flex flex-col w-full">
              <label className="mb-1" htmlFor="phoneNumber">
                Téléphone
              </label>
              <input className="input border border-gray-400" name="phoneNumber" value={values.phoneNumber} onChange={handleChange} placeholder="06 12 34 56 78" />
              {errors.phoneNumber && <div className="flex items-center text-sm text-red-500">{errors.phoneNumber}</div>}
            </div>
            <div className="flex flex-col w-full">
              <label className="mb-1" htmlFor="companyRole">
                Poste
              </label>
              <input className="input border border-gray-400" name="companyRole" value={values.companyRole} onChange={handleChange} placeholder="Directeur" />
              {errors.companyRole && <div className="flex items-center text-sm text-red-500">{errors.companyRole}</div>}
            </div>
          </div>
          <button className="blue-button mt-10 mb-8 w-44 self-center text-lg" type="submit" disabled={loading}>
            {loading ? <Loader /> : "Continuer"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

const FormulaRequest = ({ close, setStep }) => {
  const { user, setUser } = useStore();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (formula) => {
    try {
      if (formula === "basic") plausibleEvent("RequestFormulaBasic");
      if (formula === "premium") plausibleEvent("RequestFormulaPremium");
      setLoading(true);
      const { ok, data } = await api.post(`/auth/${user._id}/formula-request`, { formulaRequest: formula });
      setLoading(false);
      if (ok) {
        setUser(data);
        setStep("sent");
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className="w-[64rem]">
      <div className="md:grid md:grid-cols-3 gap-3">
        <div className="p-6 bg-[#A0ABC2] flex flex-col items-center rounded-md h-fit mb-2">
          <div className="text-blue-dark text-2xl font-bold text-center">Accès Freemium</div>
          <div className="text-blue-dark text-base font-normal text-center">Gratuit</div>
          <button className="text-white my-4 text-base font-semibold py-2 px-5 disabled:cursor-not-allowed disabled:opacity-40 border border-white rounded-md" onClick={close}>
            Choisir
          </button>
          <div className="flex gap-2 text-blue-dark text-base text-left font-normal">
            <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
              <IoCheckmarkSharp className="text-green-600" />
            </div>
            <div className="flex-1">
              <b>Accès Seco2</b> - homologué CNC
            </div>
          </div>
        </div>
        <div className="p-6 bg-[#D7FCB3] flex flex-col items-center rounded-md h-fit mb-2">
          <div className="text-blue-dark text-2xl font-bold text-center">Accès Basique</div>
          <div className="text-blue-dark text-base font-normal text-center">800 € HT /Projet</div>

          <button
            disabled={loading}
            onClick={() => handleSubmit("basic")}
            className="rounded-md my-4 bg-[#4A5DED] text-white font-semibold py-2 px-5 disabled:cursor-not-allowed disabled:opacity-40">
            Recommandé
          </button>

          <div>
            <div className="flex gap-2 text-blue-dark text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Accès Seco2</b> - homologué CNC
              </div>
            </div>
            <div className="flex gap-2 text-blue-dark text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Import automatique</b> des données monétaires prévisionnelles
              </div>
            </div>
            <div className="flex gap-2 text-blue-dark text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Aide au remplissage</b> des données physiques prévisionnelles
              </div>
            </div>
            <div className="flex gap-2 text-blue-dark text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>30 min de Kick-off</b> Projet
              </div>
            </div>
            <div className="flex gap-2 text-blue-dark text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Chat live 5/7</b>
              </div>
            </div>
          </div>
        </div>
        <div className="p-6 bg-[#1F2C40] flex flex-col items-center rounded-md h-fit mb-2">
          <div className="text-white text-2xl font-bold text-center">Accès Premium</div>
          <div className="text-white text-base font-normal text-center">1800 € HT /Projet</div>

          <button
            disabled={loading}
            onClick={() => handleSubmit("premium")}
            className="text-white my-4 text-base font-semibold py-2 px-5 disabled:cursor-not-allowed disabled:opacity-40 border border-white rounded-md">
            Choisir
          </button>

          <div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Accès Seco2</b> - homologué CNC
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Import automatique</b> des données monétaires prévisionnelles
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Aide au remplissage</b> des données physiques prévisionnelles
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>30 min de Kick-off</b> Projet
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Chat live 5/</b>7
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Accès à la méthodologie de collecte</b> des données Flying Secoya
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Aide au remplissage</b> des données <b>physiques prévisionnelles</b> à partir des données d'activités
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Intégration</b> des données <b>physiques définitives</b> dans Seco2
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                <b>Vérification & consolidation des données</b> complètes par un Expert Carbone Flying Secoya
              </div>
            </div>
            <div className="flex gap-2 text-white text-base font-normal mb-2">
              <div className="flex justify-center items-center h-5 w-5 rounded-full bg-green-50">
                <IoCheckmarkSharp className="text-green-600" />
              </div>
              <div className="flex-1">
                30 min de <b>Closing Projet</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FormulaRequestSent = ({ close }) => {
  const { user } = useStore();

  return (
    <div className="w-[48rem] flex flex-col items-center">
      <div className="p-6 bg-[#F1FEF3] border border-[#73AE7C] rounded-md mb-3 text-[#73AE7C]">
        <p>
          Bien reçu ! Votre demande de <b>formule payante a été envoyée à nos équipes</b>. Elles vont revenir vers vous dès que possible. En attendant, vous avez accès à la version
          gratuite de la plateforme.
        </p>
        <p>
          Un email de confirmation vient de vous être envoyé sur <b>{user.email}</b>
        </p>
      </div>
      <button className="blue-button mt-10 mb-8 w-44 self-center text-lg" onClick={close}>
        Accéder à Seco2
      </button>
    </div>
  );
};
