import React from "react";

const NotFound = () => {
  return (
    <div>
      <p>Page non trouvée</p>
    </div>
  );
};

export default NotFound;
