import React from "react";
import { StatisticBadge } from "./results/StatisticsBox";
import { formatAndConvertNumber, getCO2Equivalence } from "../utils";
import { Loader } from "./loader";

const Statistics = ({ estimations, color }) => {
  if (!estimations) return <Loader />;
  if (estimations.length === 0) return null;
  const estimationsCompleted = estimations.filter((estimation) => !estimation.isDemo).filter((estimation) => estimation.validated);
  const nbEstimationsCompleted = estimationsCompleted.length;

  const totalEmissions = estimationsCompleted.reduce((total, estimation) => {
    return total + (estimation.form?.reduce((acc, cur) => acc + cur.impact, 0) || 0);
  }, 0);

  const { number: totalEmissionsFormated, unit: totalEmissionsUnit } = formatAndConvertNumber(totalEmissions, "emissions");
  const { number: meanEmissions, unit: meanEmissionsUnit } = formatAndConvertNumber(totalEmissions / nbEstimationsCompleted, "emissions");

  const equivalenceInfos = getCO2Equivalence(totalEmissions / nbEstimationsCompleted);

  return (
    <article className="w-full grid grid-cols-4 gap-5 items-center justify-between mb-[50px]">
      <StatisticBadge type="projets" number={nbEstimationsCompleted} color={color} />
      {nbEstimationsCompleted > 0 && (
        <>
          <StatisticBadge type="totalEmissions_general" number={totalEmissionsFormated} unit={totalEmissionsUnit} color={color} />
          <StatisticBadge type="meanEmissions" number={meanEmissions} unit={meanEmissionsUnit} color={color} />
          <StatisticBadge badgeInfos={equivalenceInfos} type={"equivalence"} color={color} />
        </>
      )}
    </article>
  );
};

export default Statistics;
