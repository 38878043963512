import React from "react";
import { modelInfoSynthesis } from "../../utils/config";
import { getEstimationValueFromName } from "../../utils";

const MainInfo = ({ parameter, value }) => {
  if (!value || !parameter) return null;
  if (parameter === "Date de tournage") value = value.split("-").reverse().join("/");
  return (
    <div className="w-1/2 flex items-center justify-center text-normal gap-8 mb-4">
      <p className="w-1/2 text-right font-normal">{parameter}</p>
      <p className="w-1/2 text-left font-bold text-base capitalize">{value}</p>
    </div>
  );
};

const MainInfosBox = ({ estimation }) => {
  return (
    <section className="mt-5 w-full px-16 pb-1 pt-5 flex flex-wrap">
      {modelInfoSynthesis.map((parameter, i) => (
        <MainInfo key={i} parameter={parameter.title} value={getEstimationValueFromName(estimation, parameter.name, false)} />
      ))}
    </section>
  );
};

export { MainInfosBox, MainInfo };
