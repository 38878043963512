import React from "react";
import { Helmet } from "react-helmet";
import ContentExpand from "../../components/ContentExpand";

const Help = () => {
  return (
    <article className="h-full p-4 bg-white overflow-auto">
      <Helmet>
        <title>Seco2 - Aide / FAQ</title>
        <meta
          name="description"
          content="Aide et FAQ Seco2 : toutes les informations utiles à l/'utilisation du service Seco2 : estimations carbones de mes projets audiovisuels (films, publicités, séries, etc.)}"
        />
      </Helmet>
      <h1 className="mb-4 font-bold">Aide / FAQ</h1>
      <ContentExpand title="Pourquoi faire une estimation carbone de son projet ?">
        <p className="text-justify">
          La première étape d'une réduction de son empreinte carbone, consiste à mesurer ses émissions, émises directement ou indirectement par son activité, sur une période
          donnée.
        </p>
      </ContentExpand>
      <ContentExpand title="Comment fonctionne le Seco2 ?">
        <div className="text-justify">
          <p>
            Le Seco2 se base sur un élément clé de tout projet audiovisuel: le devis prévisionnel. En amont de votre tournage, vous avez déterminé les dépenses à venir pour chaque
            poste, que ce soit les salaires, la location de matériel, ou encore les coûts de déplacement.
          </p>
          <p>
            Le Seco2 utilise un algorythme simple, efficace, et mis à jour très régulièrement: basé sur les données carbone de l'ADEME (Agence de l'Environnement et de la Maitrise
            d'Energie), l'outil analyse chacune des lignes de dépenses du devis, et y associe une \"dépense carbone\". Concrètement, cela signifie que pour 1€ dépensé, vous émettez
            X Kg/CO2eq.
          </p>
          <p>Le Seco2 compile ensuite toutes ces données, et vous indique le montant prévisionnel de CO2 que vous allez dépenser durant votre projet.</p>
          <p>
            Le Seco2 a ainsi vocation à sensibiliser autour de la problématique carbone. Une fois mesurée, vous pourrez ainsi mettre en place une stratégie bas carbone, qui vous
            permettra de réduire vos émissions de CO2 tout au long du projet.
          </p>
          <p>Mesurer, réduire, et compenser le cas échéant !</p>
        </div>
      </ContentExpand>
      <ContentExpand title="Comment remplir le Seco2 ?">
        <div className="text-justify">
          <p>
            Afin de remplir correctement le Seco2 pour un bilan prévisionnel, vous aurez besoin de votre devis prévisionnel le plus à jour. Bien évidemment, le devis étant une
            estimation, les résutalts de Seco2 font également office d'estimation. Afin d'être le plus cohérent possible, il s'agit donc d'utiliser les données financières les plus
            proches de la réalité.
          </p>
          <p>Une fois votre devis en main, vous remplirez les lignes correspondantes sur le Seco2. </p>
          <p>Pour un bilan réel, utilisez les factures afin d'avoir le bilan réel.</p>
          <p>
            Notre centre d'aide est disponible{" "}
            <a href="https://flyingsecoya.zendesk.com/hc/fr/" className="underline font-medium" rel="noreferrer" target="_blank">
              ici
            </a>
          </p>
          <p>Si vous utilisez une matrice de devis spécifique, et que vous souhaiteriez l'utiliser dans le Seco2, contactez-nous !</p>
        </div>
      </ContentExpand>
      <ContentExpand title="Pour aller plus loin">
        <p className="text-justify">
          Si vous souhaitez vous engager et réduire l'impact de votre projet audivoisuel, contactez-nous. Nous pouvons vous accompagner dans la création d'une stratégie bas
          carbone, et vous conseiller dans la création d'écosystèmes de production plus vertueux.
        </p>
      </ContentExpand>
    </article>
  );
};

export default Help;
