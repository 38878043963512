import { API_URL } from "../config";

class APIHandler {
  constructor() {
    this.name = "APIHandler";
    if (!API_URL) throw new Error("API_URL must be specified in .env");
    this.baseUrl = API_URL + "/api";
    this.headers = {
      "Content-Type": "application/json",
    };
  }

  async post(endpoint, data, options = {}) {
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...options.headers,
      },
      body: JSON.stringify(data),
      credentials: "include",
    });
    if (response.status === 401) window.location = "/signin";
    return await response.json();
  }

  async postFormData(endpoint, data) {
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: "POST",
      body: data,
      credentials: "include",
    });
    if (response.status === 401) window.location = "/signin";
    return await response.json();
  }

  async get(endpoint, options = {}) {
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: "GET",
      headers: {
        ...this.headers,
        ...options.headers,
      },
      credentials: "include",
    });
    if (response.status === 401) window.location = "/signin";
    return await response.json();
  }

  async patch(endpoint, data, options = {}) {
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: "PATCH",
      headers: {
        ...this.headers,
        ...options.headers,
      },
      body: JSON.stringify(data),
      credentials: "include",
    });
    if (response.status === 401) window.location = "/signin";
    return await response.json();
  }

  async put(endpoint, data, options = {}) {
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: "PUT",
      headers: {
        ...this.headers,
        ...options.headers,
      },
      body: JSON.stringify(data),
      credentials: "include",
    });
    if (response.status === 401) window.location = "/signin";
    return await response.json();
  }

  async delete(endpoint, id, options = {}) {
    const response = await fetch(`${this.baseUrl}${endpoint}/${id}`, {
      method: "DELETE",
      headers: {
        ...this.headers,
        ...options.headers,
      },
      credentials: "include",
    });
    if (response.status === 401) window.location = "/signin";
    return await response.json();
  }
}

export default new APIHandler();
