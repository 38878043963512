import React from "react";

const ChartBadge = ({ title, children }) => {
  const className = title && "no_title";

  return (
    <div className={`${className} w-full h-full bg-white rounded-sm grid grid-row-[200px_32px] gap-4 place-items-center`}>
      <div className="w-full h-full">{children}</div>
      {title && (
        <p className="font-normal text-center leading-[21px] text-base not-italic" style={{ width: "192px" }}>
          {title}
        </p>
      )}
    </div>
  );
};

export default ChartBadge;
