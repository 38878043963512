import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HiX } from "react-icons/hi";

// Show cross is a param used for the onboarding modal. We dont want the user to close the modal
export const Modal = ({ isOpen, children, onClose, className = "p-12", showCross = true }) => {
  const cancelButtonRef = useRef();

  if (!isOpen) return <Fragment />;

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-20" initialFocus={cancelButtonRef} open={isOpen} onClose={onClose}>
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 min-h-screen" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className={`absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform rounded bg-white ${className}`}>
              {showCross && (
                <>
                  <button type="button" /> {/* dummy focus */}
                  <div className="absolute top-0 right-0 cursor-pointer p-4">
                    <HiX className="text-xl text-blue-dark" onClick={onClose} />
                  </div>
                </>
              )}

              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
