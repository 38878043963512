import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { AiFillMessage } from "react-icons/ai";
import { toast } from "react-toastify";

import api from "../../services/api";
import Logo from "../../assets/img/logo-bleu.png";

const EmailSent = () => {
  const { email } = useLocation().state || { email: null };
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const handleSendEmail = () => {
    if (loading) return;
    if (!email) return setError(true);
    setLoading(true);
    api
      .post("/auth/resend-email", { email })
      .then((res) => {
        setLoading(false);
        if (res.ok) setSent(true);
        else setError(true);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Une erreur est survenue");
      });
  };

  return (
    <div className="flex flex-col items-center">
      <img src={Logo} alt="Seco2" className="w-44" />
      <p className="my-4 text-3xl font-semibold text-center text-gray-500">Nous vous avons envoyé un email de confirmation</p>
      {email ? (
        <p className="text-center">Pour valider votre compte, ouvrez l'email que nous venons de vous envoyer à {email}</p>
      ) : (
        <p className="text-center">Pour valider votre compte, ouvrez l'email que nous venons de vous envoyer</p>
      )}

      <div className="mt-10 flex w-fit flex-row items-center justify-center gap-2 bg-[#E2F3F9] py-3 px-4">
        <AiFillMessage className="text-[#2791B5] text-2xl" />
        <div div className="w-fit">
          {sent ? (
            <p className="font-bold text-[#15E0A6]">Lien renvoyé !</p>
          ) : (
            <div>
              Pas reçu l'email ?{" "}
              <button onClick={handleSendEmail} className="cursor-pointer font-bold text-[#2791B5] hover:underline" disabled={sent || loading}>
                Renvoyer le lien.
              </button>
            </div>
          )}
          <div>
            Si vous ne le recevez toujours pas, vérifiez votre email et/ou contactez{" "}
            <a href="mailto:support@flyingsecoya.io" className="cursor-pointer font-bold text-[#2791B5] hover:underline">
              support@flyingsecoya.io
            </a>
          </div>
        </div>
      </div>
      {error ? (
        <div className="mt-10 bg-red-100 text-black p-4">
          Une erreur est survenue. Veuillez contacter le support{" "}
          <a href="mailto:support@flyingsecoya.io" className="cursor-pointer font-bold hover:underline">
            support@flyingsecoya.io
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default EmailSent;
