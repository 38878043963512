import React from "react";
import { Link } from "react-router-dom";
import IconButton from "../partials/IconButton";
import HeaderTitle from "../partials/HeaderTitle";

const HeaderResults = ({ title }) => {
  return (
    <div className="w-full h-full grid grid-cols-3 items-center">
      <Link to="/">
        <IconButton icon="back" text="Retour au tableau de bord" />
      </Link>
      <HeaderTitle title={`Synthèse - ${title}`} />
    </div>
  );
};

export default HeaderResults;
