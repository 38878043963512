import React from "react";

const Header = ({ children, className = "" }) => {
  return (
    <div id="header" className={`${className} sticky bg-white md:h-16 md:px-6 z-[1000] h-16 px-4 `}>
      {children}
    </div>
  );
};

export default Header;
