import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { VscVerified } from "react-icons/vsc";
import { TbTrash } from "react-icons/tb";

import useStore from "../../Stores/zustand";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HiOutlineCubeTransparent } from "react-icons/hi";
import { RiInformationLine } from "react-icons/ri";
import { Tooltip } from "react-tooltip";

//Material UI
import TooltipUi from "@material-ui/core/Tooltip";
import api from "../../services/api";

//Components
import SearchBox from "../../components/SearchBox";
import PayBanner from "../../components/PayBanner";
import SortBox from "./components/SortBox";
import Statistics from "../../components/Statistics";
import MyPieChart from "./components/MyPieChart";
import { Loader } from "../../components/loader";
import ProgressBar from "../../components/ProgressBar";

//Utils
import { getProgress as getOldProgress, getEstimationTitle, getTotalEmission, sortEstimations, formatAndConvertNumber } from "../../utils";
import { referencingInfos } from "../../utils/config";

//Modals
import { Modal } from "../../components/modal";
import { Pie } from "../../components/charts";
import { getImpact, getProgress, getUnit } from "../bilan/utils/bilan";
import ModalDeleteEstimation from "./components/ModalDeleteEstimation";
import ModalCopyEstimation from "./components/ModalCopyEstimation";
import { InfosModal } from "./components/infos-modal";

const Badge = ({ icon, logo, text, type, color, uniqueId, premium }) => {
  if (!text || !type) {
    return <></>;
  }
  return (
    <div style={{ backgroundColor: color }} className={`h-8 rounded-sm flex px-2.5 ${type} w-fit relative group flex flex-row items-center gap-x-1 cursor-default`}>
      {logo && (
        <div className="w-6 h-6">
          <img src={`/images/icons/${logo}.svg`} alt="logo" className="w-full h-full" />
        </div>
      )}
      {icon}
      {type === "certified_badge" && <VscVerified className="w-4 h-4" />}
      <p className="font-semibold" style={premium ? { color: "white" } : {}}>
        {text}
      </p>
      {type === "certified_badge" && (
        <div className="absolute bottom-3 left-1/3 -translate-x-3 flex-col hidden mb-6 w-max group-hover:flex">
          <span className="relative z-10 px-4 py-2.5 text-base leading-none text-white whitespace-no-wrap bg-[#1C3C4A] shadow-lg rounded-xl">ID: {uniqueId}</span>
          <div className="w-3 h-3 -mt-2 rotate-45 bg-[#1C3C4A] ml-5"></div>
        </div>
      )}
    </div>
  );
};

const modelsName = {
  cnc: "Autre",
  chedar: "Format Chedar",
  alpha: "Format Alpha",
};

const EstimationBadge = ({ estimation, handleDeleteOneEstimation, updateDashboard, searchedInfo, user }) => {
  const [isModalCopyEstimationOpen, setIsModalCopyEstimationOpen] = useState(false);
  const [isModalDeleteEstimationOpen, setIsModalDeleteEstimationOpen] = useState(false);

  const navigate = useNavigate();
  if (!estimation.information) return null; // exclusion of former project type

  const bilan = estimation.information.carbonFootprintType;
  const title = getEstimationTitle(estimation);
  const workName = estimation.information.typeOfWork;
  const progress = getOldProgress(estimation);
  const premium = estimation.hasPaid || user.hasPaid;

  if (searchedInfo && !title?.toLowerCase().includes(searchedInfo?.toLowerCase())) {
    return <></>;
  }

  const isResultsBadge = getTotalEmission(estimation.form) > 0;

  const { number: emissions, unit: emissionsUnit } = isResultsBadge ? formatAndConvertNumber(getTotalEmission(estimation.form), "emissions") : { unit: "", number: 0 };

  return (
    <div className="p-10 w-full rounded-sm bg-white flex items-center border-b border-b-blue-border last:border-none">
      <Helmet>
        <title>{referencingInfos.dashboard.title}</title>
        <meta name="description" content={referencingInfos.dashboard.description} />
      </Helmet>

      <ModalCopyEstimation
        isOpen={isModalCopyEstimationOpen}
        title={title}
        close={() => setIsModalCopyEstimationOpen(false)}
        estimationId={estimation._id}
        updateDashboard={updateDashboard}
      />
      <ModalDeleteEstimation
        title={title}
        estimationId={estimation._id}
        handleDeleteOneEstimation={handleDeleteOneEstimation}
        isOpen={isModalDeleteEstimationOpen}
        close={() => setIsModalDeleteEstimationOpen(false)}
      />

      <div className="w-20 h-20 mr-5 flex justify-center items-center">
        {isResultsBadge && <MyPieChart data={estimation.form} dataKey={"impact"} type={"full"} unit={"kgCO2eq"} />}
      </div>
      <div className="flex flex-col mr-5 w-[calc(100%-320px)]">
        <div className="flex w-full justify-start">
          <p className="font-bold text-xl leading-[33px] mr-5">{title}</p>
          <Badge text={premium ? "Bilan premium" : "Bilan gratuit"} type={premium ? "bg-[#51B2BF]" : "bilan_badge"} premium={premium} />
        </div>

        <div className="flex mt-4 flex-wrap gap-2.5">
          <Badge text={modelsName[bilan]} type="bilan_badge" />

          {workName === "Cinéma" || (workName === "Cinématographique" && <Badge logo={"cinema"} text={workName} color={"#F6D7DB"} type="movie_type_badge" />)}
          {workName === "Télé/Séries" && <Badge logo={"ad"} text={workName} color={"#C9F1F6"} type="movie_type_badge" />}
          {workName === "Autre" && <Badge icon={<HiOutlineCubeTransparent className="w-4 h-4" />} text={workName} color={"#FFF3D2"} type="movie_type_badge" />}

          {isResultsBadge && <Badge text={`${emissions} ${emissionsUnit}`} type="amount_badge" />}
          {estimation.uniqueId && <Badge text={"Certifié CNC"} type="certified_badge" uniqueId={estimation.uniqueId} />}
        </div>
      </div>
      <div className="w-[220px] flex flex-col">
        <div className="flex justify-between w-full items-center mb-2.5">
          <TooltipUi title="Modifier les données">
            <Link to={`/estimation/${estimation._id}`}>
              <img src="/images/icons/edit.svg" alt="modifier estimation" className="w-[30px] h-[30px]" />
            </Link>
          </TooltipUi>
          <TooltipUi title="Consulter la synthèse">
            {estimation.validated ? (
              <Link to={`/estimation/${estimation._id}/results`}>
                <img src="/images/icons/document.svg" alt="consulter synthèse" className="w-[30px] h-[30px]" />
              </Link>
            ) : (
              <img className="opacity-50 w-[30px] h-[30px]" src="/images/icons/document.svg" alt="consulter synthèse" />
            )}
          </TooltipUi>
          <TooltipUi title="Télécharger la synthèse">
            {estimation.validated ? (
              <img
                src="/images/icons/exit.svg"
                className="rotate-90 w-[30px] h-[30px] cursor-pointer"
                alt="supprimer estimation"
                onClick={() => navigate(`/estimation/${estimation._id}/results?dl=${true}`)}
              />
            ) : (
              <img src="/images/icons/exit.svg" className="opacity-50 rotate-90 w-[30px] h-[30px] cursor-pointer" alt="supprimer estimation" />
            )}
          </TooltipUi>
          {/* <Tooltip title="Copier l'estimation">
            <img src="/images/icons/copy.svg" alt="Copier l'estimation" onClick={() => openModalCopyEstimation()} />
          </Tooltip> */}
          <TooltipUi title="Supprimer l'estimation">
            <img src="/images/icons/delete.svg" alt="supprimer estimation" className="w-[30px] h-[30px] cursor-pointer" onClick={() => setIsModalDeleteEstimationOpen(true)} />
          </TooltipUi>
        </div>
        <ProgressBar progress={progress} />
      </div>
    </div>
  );
};

const TYPE_LABELS = {
  Prévisionnel: "prévisionnel",
  definitif: "définitif",
  forecast: "prévisionnel",
  final: "définitif",
};

const Dashboard = () => {
  const [estimations, setEstimations] = useState(null);
  const [projects, setProjects] = useState();
  const [searchedInfo, setSearchedInfo] = useState("");
  const [sortMode, setSortMode] = useState("new");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState(null);
  const [isInfosModalOpen, setIsInfosModalOpen] = useState(false);
  const { user } = useStore();

  useEffect(() => {
    if (user.role !== "cnc" && (!user.firstName || !user.lastName)) setIsInfosModalOpen(true);
    getEstimations();
  }, []);

  useEffect(() => {
    if (estimations && sortMode) {
      let newEstimations = sortEstimations(estimations, sortMode);
      setEstimations(newEstimations);
    }
  }, [sortMode]);

  const getEstimations = () => {
    api
      .get("/estimations/")
      .then((response) => {
        if (response.ok) {
          setEstimations(response.data);
        }
      })
      .catch((err) => console.log(err));

    api
      .get("/project/")
      .then((response) => {
        if (response.ok) setProjects(response.data);
      })
      .catch((err) => console.log(err));
  };

  const updateDashboard = () => {
    getEstimations();
  };

  const handleDeleteOneEstimation = (estimationID) => {
    api
      .delete("/estimations", estimationID)
      .then(() => {
        getEstimations();
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteProject = () => {
    api
      .delete("/project", deleteProjectId)
      .then((res) => {
        if (res.ok) {
          setProjects(projects.filter((project) => project._id !== deleteProjectId));
          setDeleteProjectId(null);
        }
      })
      .catch((err) => console.log(err));
  };

  if (!projects) return <Loader />;
  return (
    <section className="w-full h-full overflow-y-auto">
      <InfosModal isOpen={isInfosModalOpen} close={() => setIsInfosModalOpen(false)} />
      <ConfirmDeleteModal isOpen={isDeleteModalOpen} close={() => setIsDeleteModalOpen(false)} onDelete={handleDeleteProject} />
      {/* TODO migrate */}
      <h2 className="text-[#0F3D4A] font-bold mb-4">Mon tableau de bord</h2>
      <Statistics estimations={estimations} />
      {/* <PayBanner /> */}
      {/* <div className="grid grid-cols-[auto_300px_250px] gap-5  items-center mb-6 w-full">
        <h2 className="font-bold text-[#103C4B]">Mes Projets ({estimations.length})</h2>
        <SearchBox searchedInfo={searchedInfo} setSearchedInfo={setSearchedInfo} />
        <SortBox setSortMode={setSortMode} sortMode={sortMode} />
      </div> */}

      <div className="w-full flex flex-col gap-6">
        {projects.map((project, i) => (
          <div key={i} className="bg-white p-10">
            <div className="flex items-center justify-between gap-3">
              <div className="flex items-center gap-3">
                <h2 className="text-xl font-bold text-blue-dark">{project.title}</h2>
                <div className="bg-[#F6D7DB] flex items-center rounded p-1">
                  <img src="/images/icons/cinema.svg" alt="Cinema" className="w-5 mr-1" />
                  <span className="text-sm">{project.type}</span>
                </div>
                <div className="bg-[#F6D7DB] flex items-center rounded p-1">
                  <img src="/images/icons/cinema.svg" alt="Cinema" className="w-5 mr-1" />
                  <span className="text-sm">{project.genre}</span>
                </div>
                <div className="bg-[#F6D7DB] flex items-center rounded p-1">
                  <img src="/images/icons/cinema.svg" alt="Cinema" className="w-5 mr-1" />
                  <span className="text-sm">{project.format}</span>
                </div>
                {project.certified && (
                  <div className="bg-[#D7F6DA] flex items-center rounded p-1">
                    <VscVerified className="w-6 mr-1" />
                    <span className="text-sm">Certifié CNC</span>
                  </div>
                )}
              </div>
              <button
                className="text-xl"
                onClick={() => {
                  setDeleteProjectId(project._id);
                  setIsDeleteModalOpen(true);
                }}>
                <TbTrash />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              {/* {project.estimations
                .sort((a, b) => (a.information.type !== b.information.type ? (a.information.type === "Prévisionnel" ? -1 : 1) : 0))
                .map((estimation, j) => (
                  <Estimation key={j} estimation={estimation} />
                ))} */}
              {project.bilans
                .sort((a, b) => (a.type !== b.type ? (a.type === "forecast" ? -1 : 1) : 0))
                .map((bilan, j) => (
                  <Bilan key={j} bilan={bilan} project={project} />
                ))}
            </div>
          </div>
        ))}

        {/* {estimations.map((estimation, i) => (
          <EstimationBadge
            user={user}
            key={i}
            estimation={estimation}
            handleDeleteOneEstimation={handleDeleteOneEstimation}
            searchedInfo={searchedInfo}
            updateDashboard={updateDashboard}
          />
        ))} */}
      </div>
    </section>
  );
};

const ConfirmDeleteModal = ({ isOpen, close, onDelete }) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    await onDelete();
    setLoading(false);
    close();
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <div className="flex flex-col items-center justify-center">
        <p className="text-center text-blue-dark text-xl font-bold">Êtes-vous sûr de vouloir supprimer ce projet ?</p>
        <div className="flex gap-4 mt-4">
          <button className="emtpy-button" onClick={close}>
            Non
          </button>
          <button className="blue-button" onClick={handleConfirm} disabled={loading}>
            {loading ? <Loader /> : "Oui"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const Estimation = ({ estimation }) => {
  const { number: emissions, unit: emissionsUnit } = formatAndConvertNumber(getTotalEmission(estimation.form), "emissions");
  const progress = getOldProgress(estimation);

  return (
    <>
      <Tooltip id="forecast-tooltip" style={{ maxWidth: "264px" }}>
        Faites votre bilan prévisionnel lorsque votre production <b>n’a pas encore démarré</b> et que vous n’avez qu’une estimation de vos grands postes de dépenses.
      </Tooltip>
      <Tooltip id="final-tooltip" style={{ maxWidth: "264px" }}>
        Faites votre bilan définitif lorsque votre production <b>est terminée</b> et que vous connaissez vos dépenses réelles.
      </Tooltip>
      <Link
        to={progress === 100 ? `/estimation/${estimation._id}/results` : `/estimation/${estimation._id}`}
        className="border m-px hover:border-2 hover:m-0 border-blue-dark rounded p-2 gap-2 flex">
        <div className="w-[20%] flex items-center justify-center">{emissions > 0 && <MyPieChart data={estimation.form} dataKey={"impact"} type={"full"} unit={"kgCO2eq"} />}</div>
        <div className="flex-1 flex flex-col justify-center">
          <div className="flex items-center gap-3">
            <h3>Bilan {TYPE_LABELS[estimation.information.type] || estimation.information.type}</h3>
            {estimation.information.type === "definitif" ? (
              <span data-tooltip-id="final-tooltip" className="cursor-pointer">
                <RiInformationLine className="text-blue-dark" />
              </span>
            ) : (
              <span data-tooltip-id="forecast-tooltip" className="cursor-pointer">
                <RiInformationLine className="text-blue-dark" />
              </span>
            )}
          </div>
          <div className="flex items-center gap-2">
            <div className="border border-[#DDE2E4] p-1 rounded-xs text-sm">
              {emissions} {emissionsUnit}
            </div>

            <div className="bg-[#DDE2E4] p-1 rounded-xs text-sm">{estimation.budget || 0} €</div>
          </div>
          <div className="p-1 justify-center flex flex-col">
            <p className="flex items-center self-center whitespace-pre justify-center">
              <b className="font-bold text-md mr-1">{progress}%</b>
              complétés
            </p>
            <div className="flex h-2 overflow-hidden rounded-sm bg-blue-dark-150 text-xs">
              <div style={{ width: `${progress}%` }} className="h-full bg-blue-dark" />
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

const COLORS = ["#B1D7FF", "#FAAA8D", "#51B2BF", "#E36D41", "#F6D7DB", "#DC7C7C", "#CFDDE4", "#DDE2E4", "#97BAC7", "#B0E1D6", "#5085A5", "#AFB4D6"];

const Bilan = ({ bilan, project }) => {
  const impact = getImpact(bilan.matrice);
  const impactUnit = getUnit(impact, ["kgCO2eq", "tCO2eq"]);
  // const progress = getProgress(bilan);
  const progress = { total: 0, filledCount: 0, parametersCount: 0 };
  getProgress(bilan.matrice, progress);
  const { number, unit } = getUnit(progress.total, ["kgCO2eq", "tCO2eq"]);
  progress.total = number;
  progress.unit = unit;

  const data = [];
  bilan.matrice.forEach((c, i) =>
    data.push({ name: c.name, value: getImpact(c), color: c.color || COLORS[i], legend: `${getImpact(c).toLocaleString("fr", { maximumFractionDigits: 0 })} kgCO2eq` }),
  );

  return (
    <>
      <Tooltip id="forecast-tooltip" style={{ maxWidth: "264px" }}>
        Faites votre bilan prévisionnel lorsque votre production <b>n’a pas encore démarré</b> et que vous n’avez qu’une estimation de vos grands postes de dépenses.
      </Tooltip>
      <Tooltip id="final-tooltip" style={{ maxWidth: "264px" }}>
        Faites votre bilan définitif lorsque votre production <b>est terminée</b> et que vous connaissez vos dépenses réelles.
      </Tooltip>
      <Link
        to={progress.filledCount === progress.parametersCount ? `/bilan/${bilan._id}/result` : `/bilan/${bilan._id}`}
        className="border m-px hover:border-2 hover:m-0 border-blue-dark rounded p-2 gap-2 flex">
        <div className="w-[20%] flex items-center justify-center">{impact > 0 && <Pie data={data} />}</div>
        <div className="flex-1 flex flex-col justify-center">
          <div className="flex items-center gap-3">
            <h3>Bilan {TYPE_LABELS[bilan.type] || bilan.type}</h3>
            {bilan.type === "final" ? (
              <span data-tooltip-id="final-tooltip" className="cursor-pointer">
                <RiInformationLine className="text-blue-dark" />
              </span>
            ) : (
              <span data-tooltip-id="forecast-tooltip" className="cursor-pointer">
                <RiInformationLine className="text-blue-dark" />
              </span>
            )}
          </div>
          <div className="flex items-center gap-2">
            <div className="border border-[#DDE2E4] p-1 rounded-xs text-sm">
              {impactUnit.number.toLocaleString("fr", { maximumFractionDigits: 0 })} {impactUnit.unit}
            </div>

            <div className="bg-[#DDE2E4] p-1 rounded-xs text-sm">{project.budget || 0} €</div>
          </div>
          <div className="p-1 justify-center flex flex-col">
            <p className="flex items-center self-center whitespace-pre justify-center">
              <b className="font-bold text-md mr-1">{((progress.filledCount * 100) / progress.parametersCount).toFixed(0)}%</b>
              complétés
            </p>
            <div className="flex h-2 overflow-hidden rounded-sm bg-blue-dark-150 text-xs">
              <div style={{ width: `${((progress.filledCount * 100) / progress.parametersCount).toFixed(0)}%` }} className="h-full bg-blue-dark" />
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Dashboard;
