import React from "react";

import { Routes, Route } from "react-router-dom";

import Users from "./Users";
import ViewUser from "./ViewUser";
import Stats from "./Stats";
import UserRequests from "./UserRequests";

export default () => (
  <Routes>
    <Route path="/users/:id" element={<ViewUser />} />
    <Route path="/users" element={<Users />} />
    <Route path="/stats" element={<Stats />} />
    <Route path="/userRequests" element={<UserRequests />} />
  </Routes>
);
