import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useState, useEffect, useRef } from "react";
import { HiOutlineTrash } from "react-icons/hi";
import { Combobox } from "@headlessui/react";

const MyTextField = withStyles({
  root: {
    textAlign: "right",
    "& .MuiOutlinedInput-root": {
      borderRadius: "2px",
      backgroundColor: "white",
      "& fieldset": {
        border: "1px solid var(--backDarkBlue)",
        fontFamily: "var(--mainPolice)",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid var(--backDarkBlue)",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 0px",
      textAlign: "right",
      color: "var(--textDarkBlue)",
      fontFamily: "var(--mainPolice)",
    },
    "& .MuiFilledInput-root": {
      borderRadius: "2px",
      backgroundColor: "var(--backBackBlue)",
      border: "1px solid transparent",
    },
    "& .MuiFilledInput-underline:before": {
      border: "none",
    },
    "& .MuiFilledInput-input": {
      padding: "10px 0px",
      textAlign: "right",
      fontFamily: "var(--mainPolice)",
      color: "var(--textDarkBlue)",
    },
  },
})(TextField);

const Parameter = ({ parameter, updateEstimation, id }) => {
  const comboBtn = useRef("");
  const [filter, setFilter] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const [visibleInputs, setVisibleInputs] = useState(parameter.data.length > 1 ? parameter.data.filter((d) => d.value !== "0" && d?.value !== "") : [parameter.data[0]]);
  // parameters without the ones in visibleInputs
  const [theRest, setTheRest] = useState(parameter.data.filter((country) => !visibleInputs.includes(country)));
  const hasMultipleParameters = parameter.data.length > 1;

  if (!parameter.data[0]) console.log(parameter);

  useEffect(() => {
    if (visibleInputs.length === 0 && !hasMultipleParameters) setVisibleInputs([parameter.data[0]]);

    if (parameter.data[0].option)
      setTheRest(parameter.data.filter((country) => !visibleInputs.map((c) => c.option).includes(country.option)).sort((a, b) => a.option.localeCompare(b.option)));
    else setTheRest(parameter.data.filter((country) => !visibleInputs.map((c) => c.country).includes(country.country)).sort((a, b) => a.country.localeCompare(b.country)));
  }, [visibleInputs]);

  useEffect(() => {
    if (!parameter.data[0].option) return;
    setFilteredList();
  }, [filter]);

  const setFilteredList = () => {
    const temp = theRest.filter((country) => country.option.toLowerCase().includes(filter?.toLowerCase()));
    let firstPlaceIndex = 0;
    if (temp.find((c) => c.option === "France continentale - mix moyen 2020")) {
      const idx = temp.findIndex((c) => c.option === "France continentale - mix moyen 2020");
      [temp[firstPlaceIndex], temp[idx]] = [temp[idx], temp[firstPlaceIndex]];
      firstPlaceIndex++;
    }
    if (temp.find((c) => c.option === "Royaume-Uni")) {
      const idx = temp.findIndex((c) => c.option === "Royaume-Uni");
      [temp[firstPlaceIndex], temp[idx]] = [temp[idx], temp[firstPlaceIndex]];
      firstPlaceIndex++;
    }
    if (temp.find((c) => c.option === "Italie")) {
      const idx = temp.findIndex((c) => c.option === "Italie");
      [temp[firstPlaceIndex], temp[idx]] = [temp[idx], temp[firstPlaceIndex]];
      firstPlaceIndex++;
    }
    if (temp.find((c) => c.option === "Belgique")) {
      const idx = temp.findIndex((c) => c.option === "Belgique");
      [temp[firstPlaceIndex], temp[idx]] = [temp[idx], temp[firstPlaceIndex]];
      firstPlaceIndex++;
    }
    if (temp.find((c) => c.option === "États-Unis")) {
      const idx = temp.findIndex((c) => c.option === "États-Unis");
      [temp[firstPlaceIndex], temp[idx]] = [temp[idx], temp[firstPlaceIndex]];
    }
    setFiltered(temp);
  };

  const changeValue = (idx, newValue) => {
    setVisibleInputs((prev) => {
      prev[idx].value = newValue;
      return [...prev];
    });
  };
  const handleInputFocus = () => {
    setFilter("");
    setFilteredList();
    comboBtn.current?.click();
  };

  const categoryCode = getCategoryCode(parameter);

  return (
    <>
      {hasMultipleParameters ? (
        <>
          <div className="form_row  mb-4" id={`parameter-country-${id}`}>
            <p className="col-start-1">{categoryCode}</p>
            <p className="col-start-2">{parameter.name}</p>
            <Combobox as="div" className="relative text-end mr-9" disabled={theRest.length === 0}>
              <Combobox.Input
                placeholder="Ajouter un élément..."
                onChange={(e) => setFilter(e.target.value)}
                disabled={theRest.length === 0}
                className="inline-flex items-center gap-2 border border-blue-dark py-1.5 px-2.5 disabled:opacity-50 w-56 rounded-[2px] text-base active:ring-0 focus:ring-transparent focus:border-blue-dark"
                onFocus={handleInputFocus}
              />
              <Combobox.Button className="hidden" ref={comboBtn} />
              {filtered?.length > 0 && (
                <Combobox.Options className="absolute border border-blue-dark right-0 mt-1 bg-white shadow-sm z-10 divide-y w-full  max-h-60 overflow-auto py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {filtered?.map((countryData, idx) => (
                    <Combobox.Option
                      key={idx}
                      className="py-2 px-2.5 hover:bg-backgroundColor text-blue-dark cursor-pointer text-left"
                      onClick={() =>
                        setVisibleInputs((prev) => {
                          return [...prev, countryData];
                        })
                      }>
                      {countryData.country || countryData.option}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </Combobox>
          </div>
          <div className="!mb-4 !pb-4 border-b border-blue-border mr-9">
            {visibleInputs.map((countryData, idx) => (
              <div key={idx} className="mb-4">
                <div className="flex items-center justify-between ml-auto max-w-3xl gap-5">
                  <div className="flex items-center justify-between w-full">
                    <p className="text-blue-dark">{countryData.country || countryData.option}</p>
                    {visibleInputs.length > 1 && (
                      <button
                        onClick={() => {
                          updateEstimation({
                            index: parameter.index,
                            value: "0",
                            country: countryData.country || countryData.option,
                            type: countryData.country ? "country" : "option",
                          });
                          setVisibleInputs((prev) => {
                            return prev.filter((_, index) => index !== idx);
                          });
                        }}>
                        <HiOutlineTrash className="w-5 h-5 text-blue-dark/50 hover:text-blue-dark transition-colors" />
                      </button>
                    )}
                  </div>
                  <ParameterInput parameter={parameter} countryData={countryData} updateEstimation={updateEstimation} idx={idx} changeValue={changeValue} />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="grid grid-cols-[10%_60%_30%] items-center form_row mb-4">
          <p className="col-start-1">{categoryCode}</p>
          <p className="flex-1 col-start-2">{parameter.name}</p>
          <div className="gap-2 flex flex-col mr-9">
            {visibleInputs.map((countryData, idx) => (
              <div key={idx} className="self-end">
                <ParameterInput parameter={parameter} countryData={countryData} updateEstimation={updateEstimation} idx={idx} changeValue={changeValue} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const getCategoryCode = (parameter) => {
  const data = parameter.data;
  return data
    .map((elem) => elem.categoryCode)
    .every((categoryCode) => {
      return categoryCode === data[0].categoryCode ? data[0].categoryCode : "";
    });
};

const ParameterInput = ({ parameter, countryData, updateEstimation, changeValue, idx }) => {
  const [value, setValue] = useState(countryData?.value);
  const { index } = parameter;

  useEffect(() => {
    if (countryData?.value !== value) setValue(countryData.value);
  }, [countryData]);

  const handleChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
    if (e.target.value === "") return;
    updateEstimation({ index, value: e.target.value, country: countryData?.country || countryData?.option, type: countryData?.country ? "country" : "option" });
    changeValue(idx, e.target.value);
  };

  const handlePaddingRight = ({ value, unit }) => {
    if (!value) return "0.5rem";
    if (unit === "passagers*km") return "7rem";
    if (unit === "repas") return "3.5rem";
    if (unit === "nuitée") return "3.5rem";
    if (unit === "t*km") return "3rem";
    if (unit === "m3*km") return "4rem";
    if (unit === "kWh") return "3rem";
    return "2rem";
  };

  return (
    <div className="text-blue-deep-dark flex items-center relative">
      <input
        onWheel={(e) => e.target.blur()}
        value={value === "0" ? 0 : value}
        type="number"
        onChange={handleChange}
        placeholder={countryData?.unit === "passagers*km" ? "Nombre de passagers*km effectués" : countryData?.unit}
        className={`bg-blue-light rounded w-72 py-2 pl-3 focus:bg-white text-right border focus:border-black border-blue-light`}
        style={{ paddingRight: handlePaddingRight({ value, unit: countryData?.unit }) }}
      />
      {!!value && <span className="absolute right-2">{countryData?.unit}</span>}
    </div>
  );
};

export default Parameter;
