import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as cinema } from "../../assets/icons/cinema.svg";
import { ReactComponent as close } from "../../assets/icons/close.svg";
import { ReactComponent as noIcon } from "../../assets/icons/close.svg";
import { ReactComponent as edit } from "../../assets/icons/edit.svg";
import { ReactComponent as add } from "../../assets/icons/add.svg";
import { ReactComponent as importIcon } from "../../assets/icons/import.svg";
import { ReactComponent as check } from "../../assets/icons/check.svg";
import { ReactComponent as help } from "../../assets/icons/help.svg";
import { ReactComponent as back } from "../../assets/icons/back.svg";
import { ReactComponent as co2 } from "../../assets/icons/co2.svg";
import { ReactComponent as tournage } from "../../assets/icons/tournage.svg";
import { ReactComponent as budget } from "../../assets/icons/budget.svg";
import { ReactComponent as balance } from "../../assets/icons/balance.svg";
import { ReactComponent as document } from "../../assets/icons/document.svg";
import { ReactComponent as settings } from "../../assets/icons/settings.svg";
import { ReactComponent as expand } from "../../assets/icons/expand more.svg";
import { ReactComponent as collapse } from "../../assets/icons/collapse.svg";

const icons = {
  cinema,
  co2,
  tournage,
  budget,
  balance,
  close,
  noIcon,
  edit,
  add,
  importIcon,
  check,
  back,
  help,
  document,
  settings,
  collapse,
  expand,
};

const MyIcon = ({ icon, color }) => {
  const finalIcon = icons[icon] ? icons[icon] : icons["noIcon"];

  const ColoredSvgIcon = withStyles(() => ({
    root: {
      fill: color,
    },
  }))(SvgIcon);

  if (color) {
    return <ColoredSvgIcon fill={color} component={finalIcon} />;
  }

  return <SvgIcon component={finalIcon} />;
};

export default MyIcon;
