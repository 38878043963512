import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { sortingModes } from "../../../utils/config";

const SortBox = ({ sortMode, setSortMode }) => {
  return (
    <Listbox value={sortMode} onChange={setSortMode}>
      <div className="relative">
        <Listbox.Button className="w-full h-10 border border-blue-dark rounded-sm px-1.5 py-2.5 relative">
          <span className="truncate text-left block absolute top-1/2 -translate-y-1/2">
            {sortingModes.find((obj) => Object.prototype.hasOwnProperty.call(obj, sortMode))[sortMode]}
          </span>
        </Listbox.Button>
        <div className="w-6 h-6 border-none rounded-sm top-1/2 -translate-y-1/2 absolute right-1.5 pointer-events-none bg-blue-dark">
          <img src="/images/icons/drop down.svg" alt="recherche" />
        </div>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute mt-1 z-10 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-back-light-blue border border-blue-dark">
            {sortingModes.map((mode, i) => (
              <Listbox.Option
                key={i}
                className={({ active }) => `relative cursor-default select-none py-2 px-1.5 pr-4 ${active ? "bg-back-light-blue text-blue-dark" : "text-blue-dark"}`}
                value={Object.keys(mode)[0]}>
                {({ selected }) => <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{Object.values(mode)[0]}</span>}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default SortBox;
