import DistributionHistogram from "components/partials/DistributionHistogram";
import ChartBadge from "components/results/ChartBadge";
import Treemap from "components/results/Treemap";
import React from "react";
//Utils
import { formatNumber } from "../../utils";

const TreemapSection = ({ className, title, data }) => {
  return (
    <>
      <div id="results" className={`w-full ${className}`}>
        <ResultsSubCategoryTitle title={title} />
        <p className="font-normal text-xs leading-4 mb-5 to_print">Classement des catégories par niveau d'émissions, par ordre décroissant.</p>
        <LegendBoxForTreemap data={data} />
      </div>
      <div id="treemap" className="w-full h-full">
        <Treemap data={data} />
      </div>
    </>
  );
};

const SecoyaTooltip = ({ color, name, value, unit }) => {
  return (
    <div className="flex items-center py-1 px-2 bg-white border-blue-border border rounded-sm">
      {color && <div style={{ backgroundColor: color }}></div>}
      <p>{name + " : "}</p>
      <strong>{formatNumber(value)}</strong>
      <p>{" " + unit}</p>
    </div>
  );
};

const DistributionChartSection = ({ estimationsInfos, color }) => {
  return (
    <>
      <ResultsSubCategoryTitle title="Distribution des émissions et budgets" />
      <div className="w-full grid grid-cols-[calc(50%-10px)_calc(50%-10px)] gap-5 mb-8">
        <ChartBadge>
          <DistributionHistogram data={estimationsInfos.totalEmissions} unit="tCO2" color={color} title={"Émissions (tCO2)"} />
        </ChartBadge>
        <ChartBadge>
          <DistributionHistogram data={estimationsInfos.totalAmount} unit="k€" color={color} title={"Budgets (k€)"} />
        </ChartBadge>
      </div>
    </>
  );
};

const LegendBox = ({ data }) => {
  return (
    <div className="to_not_print w-full grid grid-cols-4 items-center border border-blue-border bg-white px-2 pt-3 gap-x-4">
      {data
        .filter((category) => category.impact > 0)
        .map((category, i) => (
          <div key={i} className="flex items-center mb-3 w-[calc(100%/4_61px/4)] space-x-3.5">
            <div className="w-4 h-4 roudned-sm" style={{ backgroundColor: category.color }} />
            <p className="font-normal text-xs leading-4">{category.name}</p>
          </div>
        ))}
    </div>
  );
};

const LegendBoxForTreemap = ({ data }) => {
  let finalData = [];
  data.forEach((category) => {
    category.subCategories?.forEach((subCat) => {
      finalData.push(subCat);
    });
  });
  finalData.sort((a, b) => {
    return b.impact - a.impact;
  });
  const indexToSplit = Math.round(finalData.length / 2);
  const dataTable1 = finalData.slice(0, indexToSplit);
  const dataTable2 = finalData.slice(indexToSplit + 1);

  const TreemapTable = ({ data }) => {
    return (
      <table>
        <thead>
          <tr>
            <th colSpan="2">Catégorie</th>
            <th>Émissions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((category, i) => (
            <tr key={i}>
              <td>
                <div style={{ backgroundColor: category.color }}></div>
              </td>
              <td className="font-normal text-xs leading-4">{category.name}</td>
              <td className="font-normal text-xs leading-4">{`${formatNumber(category.impact)} kgCO2eq`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div id="treemap_legend_wrapper" className="to_print">
      <h2 className="font-bold text-[25px] leading-[33px]">Répartition des émissions</h2>
      <div className="results_legend_and_table treemap_legend">
        <TreemapTable data={dataTable1} />
        <TreemapTable data={dataTable2} />
      </div>
    </div>
  );
};

const LegendAndTableBox = ({ data }) => {
  const maxHeight = `${(data.length * 32 + 32) / 2}px`;
  return (
    <div className="mb-4">
      <div className="to_print flex flex-col border p-3 ">
        <div className="w-full flex items-center">
          <div className="w-1/4">Catégorie</div>
          <div className="w-1/4 pl-2">Émissions</div>
          <div className="w-1/4">Catégorie</div>
          <div className="w-1/4 pl-2">Émissions</div>
        </div>
        <div style={{ maxHeight: maxHeight }} className={`w-full flex flex-col flex-wrap`}>
          {data
            .sort((a, b) => b.impact - a.impact)
            .map((category, i) => (
              <div key={`category-${i}`}>
                {category.impact > 0 && (
                  <div className="w-1/2 p-1 flex items-center">
                    <div style={{ backgroundColor: category.color }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="font-normal pl-1 w-1/2 text-sm leading-4">{category.name}</div>
                    <div className="font-normal w-1/2 text-sm leading-4">{`${formatNumber(category.impact)} kgCO2eq`}</div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const ResultsSubCategoryTitle = ({ title, id }) => {
  return (
    <p id={id} className="font-bold text-base leading-5 w-full flex items-center border-b border-blue-border pb-4 my-6">
      {title}
    </p>
  );
};

export { LegendBox, ResultsSubCategoryTitle, SecoyaTooltip, LegendAndTableBox, LegendBoxForTreemap, TreemapSection, DistributionChartSection };
