import React, { useState } from "react";

import MyIcon from "components/partials/MyIcon";
import { Modal } from "components/modal";

const ModalDeleteAccount = ({ user, close, isOpen }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  console.log(isOpen);

  const handleValidate = async () => {
    user
      .deleteOne()
      .then(() => {
        setErrorMessage(null);
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <h2 className="mb-6 font-bold text-2xl leading-8">Suppression de votre</h2>
      <p className="font-normal text-base leading-5 mb-4">
        Vous êtes sur le point de <strong>supprimer votre compte</strong>. Êtes-vous sûr ? Toutes vos données seront supprimées, vous ne pourrez y accéder à nouveau.
      </p>
      {errorMessage && <p className="error_message">{errorMessage}</p>}
      <div className="mt-10 flex gap-4 justify-end">
        <button className="empty-button w-full flex items-center gap-2 justify-center pl-2" onClick={close}>
          <MyIcon icon="back" />
          Non, revenir
        </button>
        <button className="blue-button w-full flex items-center gap-2 justify-center pl-2" onClick={handleValidate}>
          <MyIcon icon="check" />
          Oui, confirmer
        </button>
      </div>
    </Modal>
  );
};

export default ModalDeleteAccount;
