import React from "react";
import { Helmet } from "react-helmet";

import ContentExpand from "../../components/ContentExpand";

const Help = () => {
  return (
    <article className="h-full p-4 bg-white overflow-auto">
      <Helmet>
        <title>Seco2 - Contact / A propos </title>
        <meta name="description" content="Contact / A propos Seco2 : toutes les informations utiles pour contacter Seco2 et connaître les informations principales de Seco2" />
      </Helmet>
      <h1 className="mb-4 font-bold">Contact / A propos</h1>
      <ContentExpand title="1. Qui a réalisé Seco2 ?">
        <p>
          Le calculateur SeCO2 est né de la vision de <strong>Charles Gachet-Dieuzeide</strong>, fondateur et Directeur Général de <strong>Secoya Eco-Tournage</strong>. <br />
          Pour le réaliser, Charles s'est entouré d'une équipe spécialisée sur les problématiques énergie-climat :{" "}
        </p>
        <ul>
          <li>
            <strong>Marie Grappin</strong> : développement des modèles de calcul des estimations carbones
          </li>
          <li>
            <strong>Pascal Besson</strong> : gestion du projet, conception de l'architecture et développement web
          </li>
          <li>
            <strong>Oriana Berthomieu</strong>, Product Designer : conception UX/UI de l'interface
          </li>
          <li>
            <strong>Franck Marmier</strong> : développement web
          </li>
        </ul>
      </ContentExpand>
      <ContentExpand title="2. Quelle est la méthodologie utilisée par le Seco2?">
        <p>
          Le Seco2 s'appuie sur les données publiques mises à disposition par l'ADEME (Agence de l'Environnement et de la Maitrise de l'Energie) et sa base carbone. Les données
          sont mises à jour régulièrement et l'équipe du Secoya fait le lien entre l'ADEME et le Seco2 afin que les facteurs d'émissions utilisés soient les plus actuels possibles.
        </p>
        <p>
          Les facteurs d'émission carbone étant basés sur des données financières, la marge d'incertidude est plus élevée qu'avec des données physiques. Le Seco2 a une vocation de
          sensibilisation et de compréhension des grands enjeux liés au carbone, il ne se substitue pas à un calcul carbone en bonne et due forme effectué après le projet.
        </p>
      </ContentExpand>
      <ContentExpand title="3. Conditions Générales de Services">
        <p>
          Vous pouvez consulter ici les{" "}
          <a href="CGS SECO2.pdf" rel="noreferrer" target="_blank" className="underline font-bold">
            {" "}
            Conditions Générales de Services{" "}
          </a>{" "}
          de Seco2.
        </p>
      </ContentExpand>
      <ContentExpand title="4. Mentions légales / Nous contacter">
        <h4>Édition du site</h4>
        <p>
          Seco2 est édité par : SECOYA SAS, au capital social de 10 000 €, immatriculée au RCS de Bobigny sour le numéro B 839 356 565 et dont le siège social est situé 16, rue
          Francois Arago, 93100 Montreuil (Siren: 839356565, Siret: 83935656500011 et TVA Intracommunautaire: FR50839356565)
        </p>

        <h4>Responsable de publication</h4>
        <p>Charles Gachet-Dieuzeide, Directeur Général Secoya</p>

        <h4>Hébergement</h4>
        <p>Le site seco2.fr est hébergé par la société Heroku Inc., 650 7th Street, San Francisco, CA (tel : +33 1 (877) 563-4311).</p>
        <p>
          Le stockage des données est réalisé par Amazon Web Services (AWS), dans un centre de données européen situé à Francfort en Allemagne. AWS est une société du groupe Amazon
          Web Services Inc., dont le siège social se situe 410 Terry Avenue North P.O. Box 81226 Seattle, WA 98108-1226 – USA
        </p>

        <h4>Nous contacter</h4>
        <p>
          contact@secoya-ecotournage.fr
          <br />
        </p>
      </ContentExpand>
    </article>
  );
};

export default Help;
