import ExcelJS from "exceljs";

const HEADERS = [
  "Calculateur",
  "Identifiant",
  "Secteur",
  "Nom Production",
  "Type d'oeuvre",
  "Titre d'oeuvre",
  "Genre",
  "Statut",
  "Minutes",
  "Lieux",
  "Année",
  "Nb jours",
  "Budget €",
  "Poste",
  "Donnée d'activité",
  "Donnée d'entrée",
  "Unité de mesure",
  "Emissions (kgCo2)",
];

const BOLDCELL = {
  border: {
    top: { style: "thin", color: { argb: "000000" } },
    left: { style: "thin", color: { argb: "000000" } },
    bottom: { style: "thin", color: { argb: "000000" } },
    right: { style: "thin", color: { argb: "000000" } },
  },
  font: {
    name: "Calibri",
    size: 8,
    color: { argb: "000000" },
    bold: true,
  },
  alignment: {
    horizontal: "center",
    vertical: "middle",
    wrapText: true,
  },
};

const NORMALCELL = {
  border: {
    top: { style: "thin", color: { argb: "000000" } },
    left: { style: "thin", color: { argb: "000000" } },
    bottom: { style: "thin", color: { argb: "000000" } },
    right: { style: "thin", color: { argb: "000000" } },
  },
  font: {
    name: "Calibri",
    size: 8,
    color: { argb: "000000" },
  },
  alignment: {
    horizontal: "center",
    vertical: "middle",
    wrapText: true,
  },
};

const FORMAT = {
  "Court-Métrage": "CM",
  "Long-Métrage": "LM",
};

const GENRES = {
  Documentaire: "D",
  Fiction: "F",
  Animation: "A",
};

const STATUS = {
  forecast: "P",
  final: "D",
};

export const exportAllEstimations = async (bilans) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Extraction Globale CNC");

  // Mise en forme du header du tableau
  for (let i = 0; i < HEADERS.length; i++) {
    const col = String.fromCharCode(65 + i);
    worksheet.getColumn(col).width = 12;
    worksheet.getCell(`${col}1`).value = HEADERS[i];
    worksheet.getCell(`${col}1`).style = BOLDCELL;
  }

  let row = 2;
  for (const bilan of bilans) {
    for (const category of bilan.categories) {
      if (category && category.categories) {
        for (const subCategory of category.categories) {
          worksheet.getCell(`A${row}`).value = "Seco2";
          worksheet.getCell(`B${row}`).value = bilan.uniqueId;
          worksheet.getCell(`C${row}`).value = bilan.productionType;
          worksheet.getCell(`D${row}`).value = bilan.productionName;
          worksheet.getCell(`E${row}`).value = FORMAT[bilan.productionFormat] || "CM"; // ?? TODO Clarify with CNC
          worksheet.getCell(`F${row}`).value = bilan.title;
          worksheet.getCell(`G${row}`).value = bilan.productionGenre.charAt(0).toUpperCase();
          worksheet.getCell(`H${row}`).value = STATUS[bilan.bilanType] || "";
          worksheet.getCell(`I${row}`).value = "";
          worksheet.getCell(`J${row}`).value = bilan.shootingLocation;
          worksheet.getCell(`K${row}`).value = bilan.shootingDate;
          worksheet.getCell(`L${row}`).value = bilan.shootingDays;
          worksheet.getCell(`M${row}`).value = bilan.budget ? `${bilan.budget} €` : "inconnu";
          worksheet.getCell(`N${row}`).value = category.name;
          worksheet.getCell(`O${row}`).value = subCategory.name;
          worksheet.getCell(`P${row}`).value = subCategory.amount;
          worksheet.getCell(`Q${row}`).value = subCategory.unit;
          worksheet.getCell(`R${row}`).value = subCategory.impact;

          for (let col = 0; col < HEADERS.length; col++) worksheet.getCell(`${String.fromCharCode(65 + col)}${row}`).style = NORMALCELL;
          row++;
        }
      }
    }
  }
  return await workbook.xlsx.writeBuffer();
};
