import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import api from "services/api";

//Components
import { DataGrid } from "@material-ui/data-grid";
import MyIcon from "components/partials/MyIcon";

const UserRequests = () => {
  const [userRequests, setUserRequests] = useState(null);
  const [sortModel, setSortModel] = useState(undefined);

  const fetchUserRequests = () => {
    api
      .get("admin/requests")
      .then((res) => {
        if(res.ok){
          const userRequestsTemp = res.data;
          userRequestsTemp.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          setUserRequests(userRequestsTemp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   console.log(userRequests);

  useEffect(() => {
    fetchUserRequests();
  }, []);

  if (!userRequests) {
    return <p>Loading</p>;
  }

  const onSortModelChange = (params) => {
    setSortModel(params.sortModel);
  };

  const columns = [
    { field: "date", headerName: "Date", width: 120 },
    { field: "type", headerName: "Demande", width: 150 },
    { field: "email", headerName: "email", width: 150 },
    { field: "name", headerName: "Nom", width: 150 },
    { field: "message", headerName: "Message", width: 300 },
  ];

  const headers = columns.map((column) => {
    return {
      label: column.headerName,
      key: column.field,
    };
  });

  const table = userRequests.map((request, i) => {
    return {
      ...request,
      id: i + 1,
      name: request.firstName + " " + request.lastName,
      date: new Date(request.createdAt).toLocaleDateString(),
    };
  });

  return (
    <div className="w-full h-full grid grid-rows-[40px_calc(100vh-134px)] gap-y-2.5">
      <ExportData data={table} headers={headers} />
      <div id="requests_table_wrapper" className="w-full h-full p-1.5 bg-white overflow-auto">
        <DataGrid
          getCellClassName={(params) => (params.value = "!max-h-[unset] !leading-[unset] !whitespace-normal")}
          getRowClassName={(params) => (params.value = "!max-h-[none]")}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          rows={table}
          columns={columns}
          pageSize={10}
          autoHeight
        />
      </div>
    </div>
  );
};

const ExportData = ({ data, headers }) => {
  const fileName = "Seco2 - Demandes Utilisateurs.csv";
  return (
    <button className="orange-button w-[200px] h-10 flex items-center justify-center gap-2 pl-2" width="200px" height="40px">
      <MyIcon icon="document" />
      <CSVLink data={data} filename={fileName} target="_blank" headers={headers}>
        Télécharger
      </CSVLink>
    </button>
  );
};

export default UserRequests;
