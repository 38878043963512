import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Loader } from "../../components/loader";
import api from "../../services/api";
import Logo from "../../assets/img/logo-bleu.png";
import useStore from "../../Stores/zustand";

const VerifyEmail = () => {
  const { setUser } = useStore();
  const [loading, setLoading] = useState(true);
  const [sent, setSent] = useState(false);
  const [expired, setExpired] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    api
      .post("/auth/verify-email", { token })
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          setUser(res.data);
          return navigate("/");
        } else {
          if (res.code === "TOKEN_EXPIRED") setExpired(true);
          else toast.error("Une erreur est survenue");
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Une erreur est survenue");
      });
  }, [location.search]);

  const handleSendEmail = () => {
    const token = new URLSearchParams(location.search).get("token");
    api
      .post("/auth/resend-email", { token })
      .then((res) => {
        if (res.ok) {
          toast.success("Lien renvoyé");
          setSent(true);
        } else toast.error("Une erreur est survenue");
      })
      .catch(() => {
        toast.error("Une erreur est survenue");
      });
  };

  if (loading)
    return (
      <div className="h-full w-full flex justify-center items-center">
        <Loader />
      </div>
    );

  if (expired)
    return (
      <div className="flex flex-col items-center">
        <img src={Logo} alt="Seco2" className="w-44" />
        <p className="my-4 text-3xl font-semibold text-center text-gray-500">Le lien de confirmation est expiré</p>
        <p className="text-center">Cliquez sur le bouton ci-dessous pour renvoyer l'email de confirmation</p>

        {!sent ? (
          <button className="blue-button w-full mt-10" onClick={handleSendEmail}>
            Renvoyer l'email
          </button>
        ) : (
          <p className="text-center font-semibold text-[#15E0A6] mt-10">Lien envoyé</p>
        )}
      </div>
    );

  return (
    <div className="flex flex-col items-center">
      <img src={Logo} alt="Seco2" className="w-44" />
      <p className="my-4 text-3xl font-semibold text-center text-gray-500">Une erreur est survenue</p>
      <div className="mt-10 bg-red-100 text-black p-4">
        Veuillez contacter le support{" "}
        <a href="mailto:support@flyingsecoya.io" className="cursor-pointer font-bold hover:underline">
          support@flyingsecoya.io
        </a>
      </div>
    </div>
  );
};

export default VerifyEmail;
