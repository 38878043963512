import React, { useEffect, useState } from "react";
import { BsCheckCircle } from "react-icons/bs";

import useStore from "Stores/zustand";

import api from "services/api";
import { Modal } from "./modal";
import { getProgress } from "utils";

const NPSModal = () => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [nps, setNps] = useState(0);
  const [comment, setComment] = useState("");
  const { user, setUser } = useStore();

  const submit = async (values) => {
    try {
      const { ok, data } = await api.patch(`/auth/${user._id}`, values);
      setUser(data);
    } catch (e) {}
  };

  const handleDisplayNPS = async () => {
    try {
      setOpen(true);
      const { ok, data } = await api.patch(`/auth/${user._id}`, { NpsDisplayedAt: new Date() });
      setUser(data);
    } catch (e) {}
  };

  const shouldOpenNPSModal = async () => {
    // check if the user has already answered the NPS and they have at least one estimations with 20% progress
    const res = await api.get("/estimations/");
    if(res.ok){
      const userEstimations = res.data
      const hasStartedEstimation = userEstimations.find((estimation) => getProgress(estimation) > 20);
      const shouldOpenNPSModal = !user.NpsDoneAt && hasStartedEstimation;
      if (shouldOpenNPSModal) handleDisplayNPS();
    }
  };

  useEffect(() => {
    shouldOpenNPSModal();
  }, []);

  const render = () => {
    if (step === 1)
      return (
        <div>
          <h2 className="mb-6 font-bold text-2xl leading-8">Recommanderiez-vous Seco2 autour de vous ?</h2>
          <p className="mb-6">Sur une échelle de 1 à 10 (1 étant "jamais", 10 étant "sans hésiter")</p>
          <div className="w-full flex justify-around">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
              <div key={value} className="group flex flex-col items-center cursor-pointer" onClick={() => setNps(value)}>
                <div className="text-blue-dark">{value}</div>
                <div className="flex items-center justify-center w-5 h-5 border border-blue-dark rounded-full">
                  <div className={`w-3 h-3 rounded-full ${value === nps ? "bg-blue-dark" : "bg-white group-hover:bg-blue-200"}`} />
                </div>
              </div>
            ))}
          </div>
          <div className="w-full flex items-center justify-end ml-auto mt-10 gap-2">
            <button
              className="blue-button flex items-center gap-2 justify-center disabled:grayscale disabled:cursor-not-allowed"
              onClick={() => {
                submit({ NpsReferralGrade: Number(nps), NpsDoneAt: new Date() });
                setStep(2);
              }}
              disabled={!nps}>
              <BsCheckCircle className="text-white" />
              Valider
            </button>
          </div>
        </div>
      );
    if (step === 2)
      return (
        <div>
          <h2 className="mb-6 font-bold text-2xl leading-8">Avez-vous des retours à nous partager ?</h2>
          <p className="mb-6">
            Vos retours d’expérience sont précieux. N’hésitez pas à nous les partager pour que nous puissions vous offrir <b>la meilleure expérience possible</b>.
          </p>
          <textarea
            rows={5}
            type="text"
            placeholder="Ecrivez..."
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            className="border border-blue-dark w-full focus:ring-0 bg-transparent gap-2.5 py-2.5 px-2.5 text-blue-deep-dark rounded-sm placeholder-blue-deep-dark"
          />
          <div className="w-full flex items-center justify-end ml-auto mt-10 gap-2">
            <button
              className="blue-button flex items-center gap-2 justify-center"
              onClick={() => {
                submit({ NpsDoneAt: new Date(), NpsFeedback: comment });
                setOpen(false);
              }}>
              <BsCheckCircle className="text-white" />
              Valider
            </button>
          </div>
        </div>
      );
  };

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} className="w-2/3 p-16" showCross={false}>
      {render()}
    </Modal>
  );
};

export default NPSModal;
