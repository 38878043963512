export const contactInfos = [
  {
    title: "Qui a réalisé Seco2 ?",
    content: `<p>Le calculateur SeCO2 est né de la vision de <strong>Charles Gachet-Dieuzeide</strong>, fondateur et Directeur Général de <strong>Secoya Eco-Tournage</strong>. <br/>Pour le réaliser, Charles s'est entouré d'une équipe spécialisée sur les problématiques énergie-climat : </p>
      <ul>
        <li><strong>Marie Grappin</strong> : développement des modèles de calcul des estimations carbones</li>
        <li><strong>Pascal Besson</strong> : gestion du projet, conception de l'architecture et développement web</li>
        <li><strong>Oriana Berthomieu</strong>, Product Designer : conception UX/UI de l'interface</li>
        <li><strong>Franck Marmier</strong> : développement web</li>
      </ul>`,
  },
  {
    title: "Quelle est la méthodologie utilisée par le Seco2?",
    content:
      "<p>Le Seco2 s'appuie sur les données publiques mises à disposition par l'ADEME (Agence de l'Environnement et de la Maitrise de l'Energie) et sa base carbone. Les données sont mises à jour régulièrement et l'équipe du Secoya fait le lien entre l'ADEME et le Seco2 afin que les facteurs d'émissions utilisés soient les plus actuels possibles.</p><p>Les facteurs d'émission carbone étant basés sur des données financières, la marge d'incertidude est plus élevée qu'avec des données physiques. Le Seco2 a une vocation de sensibilisation et de compréhension des grands enjeux liés au carbone, il ne se substitue pas à un calcul carbone en bonne et due forme effectué après le projet.</p>",
  },
  {
    title: "Conditions Générales de Services",
    content: '<p>Vous pouvez consulter ici les <a href="CGS SECO2.pdf" rel=="noreferrer" target="_blank" class="underline bold">Conditions Générales de Services</a> de Seco2.</p>',
  },
];

export const referencingInfos = {
  landing: {
    title: "Seco2 - estimation d'impact carbone pour l'audiovisuel",
    description:
      "Seco2 est un outil simple et innovant permettant d'estimer l'impact carbone prévisionniel d'une production de film. Dédié à l'audiovisuel, pour des production écoresponsables, un impact durable.",
  },
  dashboard: {
    title: "Seco2 - Tableau de bord de mes estimations carbone",
    description:
      "Tableau de bord Seco2 : liste des estimations carbones de mes projets audiovisuels (films, publicités, séries, etc.) avec statistiques détaillées : émissions CO2 moyennes par projet, budget moyen par projet et intensité carbone du budget",
  },
  credits: {
    title: "Seco2 - Mes crédits",
    description: "Achetez des crédits pour réaliser les estimations carbones de mes projets audiovisuels (films, publicités, séries, etc.)",
  },
  account: {
    title: "Seco2 - Mon Compte",
    description: "Mon compte Seco2 : actualiser mes informations personnelles, celles de ma société, changer de mot de passe ou supprimer mon compte",
  },
  uniqueId: {
    title: "Seco2 - Vérifier un bilan carbone",
    description: "Vérifier un bilan carbone Seco2 : vérifier l'authenticité d'un bilan carbone Seco2 en entrant le numéro de vérification unique du bilan carbone Seco2",
  },
  help: {
    title: "Seco2 - Aide / FAQ",
    description:
      "Aide et FAQ Seco2 : toutes les informations utiles à l'utilisation du service Seco2 : estimations carbones de mes projets audiovisuels (films, publicités, séries, etc.)",
  },
  contact: {
    title: "Seco2 - Contact / A propos ",
    description: "Contact / A propos Seco2 : toutes les informations utiles pour contacter Seco2 et connaître les informations principales de Seco2",
  },
  estimation: {
    description: "Formulaire d'entrée des informations pour réaliser une estimation Seco2, avec possibilité d'import de fichiers externes (budget Movie Magic Budgeting, etc.)",
  },
  results: {
    description: "Synthèse de mon estimation Seco2 pour mon projet audiovisuel (films, publicités, séries, etc.)",
  },
};

export const modelInfoSynthesis = [
  {
    title: "Titre",
    name: "title",
  },
  {
    title: "Type de bilan",
    name: "carbonFootprintType",
  },
  {
    title: "Format",
    name: "productionType",
  },
  {
    title: "Type d'oeuvre",
    name: "typeOfWork",
  },
  {
    title: "Genre",
    name: "genre",
  },
  {
    title: "Nom production",
    name: "productionName",
  },
  {
    title: "Lieu(x) de tournage",
    name: "shootingLocation",
  },
  {
    title: "Date de tournage",
    name: "shootingDate",
  },
  {
    title: "Budget",
    name: "budget",
  },
  {
    title: "Nombre de jours de tournage",
    name: "shootingDays",
  },
  {
    title: "Responsable estimation carbone",
    name: "carbonFootprintResponsible",
  },
];

export const getShortName = (name) => {
  switch (name) {
    case "Long-Métrage":
      return "LM";
    case "Court-Métrage":
      return "CM";
    case "Publicité":
      return "PUB";
    case "Série":
      return "SER";
    case "Unitaire":
      return "UNI";
    default:
      return "LM";
  }
};

export const badgeInfosCorrespondance = {
  LM: {
    icon: "cinema",
    name: "Cinéma",
    precision: "Long-Métrage",
    color: "#F6D7DB",
  },
  CM: {
    icon: "cinema",
    name: "Cinéma",
    precision: "Court-Métrage",
    color: "#f6d7ed",
  },
  UNI: {
    icon: "cinema",
    name: "Cinéma",
    precision: "Unitaire",
    color: "#e3828f",
  },
  PUB: {
    icon: "ad",
    name: "Publicité",
    precision: "Publicité",
    color: "#CAF1F6",
  },
  PUB_ALPHA: {
    icon: "ad",
    name: "Publicité",
    precision: "Publicité",
    color: "#CAF1F6",
  },
  SER: {
    icon: "tournage",
    name: "Cinéma",
    precision: "Série",
    color: "#F8CD98",
  },
  credits: {
    icon: "tournage",
    unit: "",
    precision: "Crédits restants",
  },
  projets: {
    icon: "tournage",
    unit: "",
    precision: "Projets complétés",
  },
  meanAmount: {
    icon: "budget",
    unit: "k€",
    precision: "Budget moyen par projet",
  },
  totalAmount_general: {
    icon: "budget",
    unit: "k€",
    precision: "Budget pris en compte pour l'estimation",
  },
  totalAmount_detailed: {
    icon: "budget",
    unit: "k€",
    precision: "Budget pris en compte pour la catégorie",
  },
  meanEmissions: {
    icon: "co2",
    unit: "kgCO2eq",
    precision: "Émissions moyennes / projet",
  },
  totalEmissions_general: {
    icon: "co2",
    unit: "kgCO2eq",
    precision: "Montant total des émissions estimées",
  },
  totalEmissions_detailed: {
    icon: "co2",
    unit: "kgCO2eq",
    precision: "Montant des émissions pour la catégorie",
  },
  emissionsPerEuro: {
    icon: "balance",
    unit: "kgCO2",
    precision: "pour 1€ dépensé",
  },
  equivalence: {
    icon: "balance",
    unit: "",
    precision: "vols Paris - New York",
  },
};

export const sortingModes = [
  {
    new: "Plus récents",
  },
  {
    alpha: "De A à Z",
  },
];

export const navFirstCategory = {
  results: "Résultats Généraux",
};

// landing page

export const clients = [
  "/images/clients/haut_et_court.jpg",
  "/images/clients/the_good_co.PNG.png",
  "/images/clients/Logo-bonne-pioche.jpg",
  "/images/clients/logo_boréales.PNG.png",
  "/images/clients/radar-films-logo.jpg",
  "/images/clients/logo_nolita.png",
  "/images/clients/logo_lucien.jpg",
  "/images/clients/Logo-Carré_satellite_my_love.png",
];

export const benefitsSlider = [
  {
    image: "/images/form.png",
    title: "1. Calcul précis : une méthodologie reconnue par l'ADEME",
    text: "Tous les calculs effectués par le Seco2 utilisent les facteurs d'émissions de la Base Carbone de l'ADEME, l'Agence de l'Environnement et de la Maitrise de l'Energie. Mis à jour régulièrement, ils garantissent une comptabilité précise et efficace.",
  },
  {
    image: "/images/import.png",
    title: "2. Import automatique de vos données budgétaires",
    text: "Le Seco2 se veut le plus simple d'utilisation, et le plus intuitif. Afin de vous accompagner au mieux, vous pouvez importer directement votre devis (selon format) afin de bénéficier d'un remplissage automatique du Seco2. Votre estimation sera ainsi réalisée en quelques secondes seulement !",
  },
  {
    image: "/images/results.png",
    title: "3. Résultats détaillés",
    text: "Le Seco2 analyse les lignes budgétaires de votre devis (parties techniques uniquement : les salaires, droits, etc… ne sont pas pris en compte pour vous permettre de garder ces informations confidentielles, et car elles n'impactent pas les émissions) et vous donne une vision précise de chacune de vos dépenses carbone. Une ligne, une dépense, un facteur d'émission, un résultat !",
  },
  {
    image: "/images/synthesis.png",
    title: "4. Export .pdf de la synthèse",
    text: "Le Seco2 vous permet d'exporter une synthèse au format PDF, résumant l'estimation carbone de votre projet. Grâce aux différents diagrammes et codes couleurs, vous verrez immédiatement où se situent vos gros postes d'émission: résultats généraux puis résultats détaillés par catégorie de dépenses. Un document à partager à vos parties prenantes !",
  },
];

export const testimonies = [
  {
    name: "Julien Tricard",
    role: "Producteur",
    production: "Lucien TV",
    logo: "/images/clients/logo_lucien.jpg",
    text: "“Nous avons pu utiliser le Seco2 sur différents projets (notamment des documentaires) et ainsi comprendre très facilement l'impact de nos films. Le Seco2 est simple et intuitif, ce fût plus que facile !”",
  },
  {
    name: "Philippe Gautier",
    role: "Directeur de Production",
    production: "Bonne Pioche",
    logo: "/images/clients/Logo-bonne-pioche.jpg",
    text: "“Grâce aux matrices très claires de Seco2, il a été simple et rapide de mesurer l'empreinte de notre long métrage. Nous avons alors réduit notre impact pendant le tournage, puis compensé le reste, grâce aux propositions ad hoc.”",
  },
  {
    name: "Luc Wise",
    role: "Founder",
    production: "The Good Company",
    logo: "/images/clients/the_good_co.PNG.png",
    text: "“L'outil de Secoya permet de mesurer le CO2 de nos campagnes et également de bénéficier d'un reporting annuel sur nos différentes mesures d'impact. Indispensable !”",
  },
  {
    name: "Karim Bekdache",
    role: "Line Producer",
    production: "Satellite My Love",
    logo: "/images/clients/Logo-Carré_satellite_my_love.png",
    text: "“Le Seco2 nous a permis de mesurer l'impact CO2 du tournage de notre dernière publicité et nous avons pu présenter le résultat au client, qui était très satisfait de cette innovation! Une vraie plus value.”",
  },
];

export const pricings = [
  {
    nbLogo: 1,
    recommended: 0,
    title: "1 estimation ponctuelle",
    price: {
      main: "150€",
      precision: "HT / 1 crédit",
    },
    textHtml: "<p>Estimez l'impact CO2 de votre projet <strong>avant le tournage</strong></p>",
    button: {
      text: "Acheter en ligne",
      type: "primary",
      action: {
        onboarding: {
          type: "internal",
          link: "/signup",
        },
        real: {
          type: "external",
          link: "https://stripe.com/",
        },
      },
    },
  },
  {
    nbLogo: 5,
    recommended: 1,
    title: "Lot de 5 estimations",
    price: {
      main: "600€",
      precision: "HT/ 5 crédits",
      tag: "une estimation offerte !",
    },
    textHtml: "<p>Estimez l'impact CO2 de 5 de vos projets !</p>",
    button: {
      text: "Acheter en ligne",
      type: "primary",
      action: {
        onboarding: {
          type: "internal",
          link: "/signup",
        },
        real: {
          type: "external",
          link: "https://stripe.com/",
        },
      },
    },
  },
  {
    nbLogo: 8,
    recommended: 0,
    title: "+ de 5 estimations",
    price: {
      main: "Devis personnalisé",
    },
    textHtml: "<p>Besoin de réaliser <strong>+ de 5 estimations</strong> ? Discutons ensemble de l'offre la plus adaptée !</p>",
    button: {
      text: "Nous contacter",
      type: "secondary",
      action: {
        onboarding: {
          type: "external",
          link: "https://a6x77gf6mzx.typeform.com/to/ty5blHJQ",
        },
        real: {
          type: "external",
          link: "https://a6x77gf6mzx.typeform.com/to/ty5blHJQ",
        },
      },
    },
  },
];

export const ResultsRecommendations = [
  {
    category: "Achats Responsables",
    items: [
      {
        category: "Achats Responsables",
        action: "Acheter du bois certifié",
        description: "Pour la construction des décors, en studio ou en décor naturel, privilégiez le bois certifié et/ou labélisé.",
        conseils: "Il existe deux références principales, qui sont la certification PEFC et le label FSC",
        url: "https://www.pefc-france.org/qu-est-ce-que-pefc/",
      },
      {
        category: "Achats Responsables",
        action: "Acheter du bois français",
        description: "Pour la construction des décors, en studio ou en décor naturel, privilégiez le bois d'origine française et transformé localement.",
        conseils: "Le recours au bois français permet de réduire considérablement l'impact carbone de la construction et favorise une économie locale",
        url: "https://franceboisforet.fr/le-bois/labels-et-distribution",
      },
      {
        category: "Achats Responsables",
        action: "Utiliser des matériaux et du mobilier d'occasion",
        description: "Pour la construction des décors, en studio ou en décor naturel, privilégiez les matériaux issus du réemploi et de l'économie circulaire.",
        conseils:
          "Intégrer l'économie circulaire et le réemploi dans la fabrication des décors permet d'optimiser le cycle de vie, de diminuer l'empreinte carbone et de réduire les coûtsLes réseaux de ressourceries se sont structurés et multipliés.",
        url: "https://ressourceries.info/?PagePrincipale",
      },
      {
        category: "Achats Responsables",
        action: "Louer ou acheter d'occasion les vêtements",
        description: "Pour limiter l'achat de vêtements neufs, privilégiez la location ou l'achat de vêtements de seconde main.",
        conseils: 'Aller dans des friperies solidaires, dans des "kilos-shop", ou encore sur le Bon Coin ou Vinted',
        url: "",
      },
      {
        category: "Achats Responsables",
        action: "Trouver des matériaux de protection d'occasion",
        description:
          "Pour la protection des décors (moquette/carton ondulé/scotch de protection), renseignez-vous pour en trouver de seconde main (ressourceries spécialisées ou stock de la production)",
        conseils: "Contacter des ressourceries ou encore aller faire un tour sur le Bon Coin",
        url: "",
      },
      {
        category: "Achats Responsables",
        action: "Utiliser des nettoyants labellisés",
        description: "Pour la construction des décors, en studio ou en décor naturel, privilégiez l'utilisation de nettoyants non toxiques et biodégradables",
        conseils: "Des nettoyants naturels évitent de polluer les sols et d'abîmer les éco-systèmes, et sont plus sains pour les personnes les utilisant.",
        url: "https://www.afise.fr/dossiers/comprendre-les-labels",
      },
      {
        category: "Achats Responsables",
        action: "Louer le gros outillage",
        description: "Pour les besoins ponctuels de gros outillages, favorisez la location à proximité.",
        conseils:
          "La location permet d'optimiser le cycle de vie du produit et la proximité permet de réduire l'impact économique et écologique du transport tout en favorisant l'économie locale.",
        url: "",
      },
      {
        category: "Achats Responsables",
        action: "Acheter des matériaux de protection écologiques",
        description:
          "Pour la protection des décors (moquette/carton ondulé/scotch de protection), optez pour des matériaux de protection éco-responsables (fabriqués en matières recyclées, sans solvant, recyclables après usage...)",
        conseils: "Eviter les matières non-recyclables qui ont un fort impact écologique",
        url: "",
      },
      {
        category: "Achats Responsables",
        action: "Choisir un pressing eco responsable",
        description: "Pour éviter la pollution chimique, faites nettoyer les costumes dans un pressing éco-responsable",
        conseils:
          'De nombreux pressing proposent maintenant des méthode de lavage et de repassage écologiques. Entre les produits utilisés, l\'électricité consommée ou encore la gestion des déchets, les pressing "éco" sont responsables et durables',
        url: "",
      },
    ],
  },
  {
    category: "Alimentation",
    items: [
      {
        category: "Alimentation",
        action: "Réduire la consommation de viande",
        description:
          'Pour diminuer l\'empreinte carbone des repas, réduisez les recettes carnées et organisez une journée entièrement végétarienne (par exemple, "les lundis sans viande")',
        conseils:
          "Pour que la \"Journée sans viande\" soit acceptée par tout le monde, expliquez les bienfaits de cette action à votre équipe. Par exemple, en leur disant que l'impact carbone d'un repas carné est 6 fois supérieur à celui d'un repas veggy",
        url: "https://www.lundi-vert.fr",
      },
      {
        category: "Alimentation",
        action: "Acheter des aliments locaux",
        description: "Pour diminuer l'empreinte carbone des repas, privilégier les circuits courts et l'alimentation locale",
        conseils:
          "Un avocat qui a fait 3 fois le tour de la Terre avant d'arriver dans notre assiette a une empreinte carbone énorme. En privilégiant le local, on garantie une proximité entre le producteur et l'assiette",
        url: "https://www.econo-ecolo.org/comment-manger-local-sain",
      },
      {
        category: "Alimentation",
        action: "Acheter des aliments de saison",
        description: "Pour diminuer l'empreinte carbone des repas, privilégier l'achat d'aliments de saison",
        conseils: "La fameuse tomate du mois de décembre a probablement grandi sous serre, boostée aux OGM et à la lumière artificielle. Mieux vaut éviter!",
        url: "https://www.greenpeace.fr/guetteur/calendrier/",
      },
    ],
  },
  {
    category: "Communication",
    items: [
      {
        category: "Communication",
        action: "Choisissez des voitures de jeu à zéro émission de CO2",
        description: "Pour sensibiliser aux enjeux de mobilité, choisissez des voitures de jeu électriques, hybrides ou retrofit.",
        conseils:
          "Les marques sont de plus en plus intéressées pour mettre en avant les nouvelles modèles moins émetteurs de CO2. Le placement de produit responsable, une nouvelle manière de faire!",
        url: "",
      },
    ],
  },
  {
    category: "Déchets",
    items: [
      {
        category: "Déchets",
        action: "Bien organiser le tri",
        description:
          "Pour faciliter votre gestion des déchets, identifiez les types de déchets principaux produits sur le tournage et axez le choix du tri sur ces déchets en particulier",
        conseils:
          "Privilégier la qualité du tri plutôt que la quantité de flux à trier. Ex: il vaut mieux un bon compost et une bonne récupération du bois à la déco qu'un tri 5 flux mal trié et donc invalorisable",
        url: "",
      },
      {
        category: "Déchets",
        action: "Acheter en grands volumes rechargeables",
        description:
          "Pour les achats de produits de nettoyage, privilégiez les conditionnements en grands volumes, rechargeables, avec des emballages recyclables ou biodégradables.",
        conseils:
          "De plus en plus de marques proposent des produits avec des recharges voire des produits consignés: vous pouvez vous approvisionner dans des boutiques en vrac à proximité",
        url: "",
      },
    ],
  },
  {
    category: "Empreinte Carbone",
    items: [
      {
        category: "Empreinte Carbone",
        action: "Faire de la compensation carbone",
        description: "Contribuer à la neutralité carbone avec un projet de compensation (de 1 à 100% du montant total de l'estimation carbone).",
        conseils:
          "De nombreux sites proposent de la contribution à la neutralité carbone (aussi appelée compensation carbone). Vous pouvez ainsi compenser une partie de vos émissions. Contactez Secoya pour des projets sur mesure!",
        url: "https://marketplace.goldstandard.org/collections/projects",
      },
    ],
  },
  {
    category: "Énergie",
    items: [
      {
        category: "Énergie",
        action: "Utiliser de la lumière à faible consommation électrique",
        description: "Sur l'ensemble du parc de matériel lumière, privilégier le matériel ayant une faible consommation énergétique (comme le LED par exemple)",
        conseils: "N'hésitez pas à prévenir votre loueur de matériel que vous souhaitez être au maximum équipé de matériel à faible consommation énergétique",
        url: "",
      },
      {
        category: "Énergie",
        action: "Utiliser de l'énergie alternative",
        description: "Essayer au maximum d'aller vers des solutions d'énergie alternative (non issue directement d'énergie fossile)",
        conseils:
          "De nombreuses solutions existent, en allant de la batterie rechargeable au groupe électrogène ne fonctionnant pas au diesel ou essence, en passant évidemment par les fournisseurs d'énergie verte",
        url: "",
      },
      {
        category: "Énergie",
        action: "Relever le chiffre lié à l'impact carbone de la consommation d'énergie",
        description: "Relever le chiffre lié à l'impact carbone de la consommation d'énergie du projet",
        conseils: "Effectuez un relevé du compteur électrique dans chaque lieu de consommation. En cas de difficulté, des solutions alternatives sont possibles, contactez-nous!",
        url: "",
      },
      {
        category: "Énergie",
        action: "Choisir un groupe électrogène adapté",
        description: "Pour réduire votre consommation énergétique, choisissez un groupe electrogène correspondant juste à la puissance nécessaire (et pas plus puissant)",
        conseils: 'Souvent, on chercher à se "protéger" en prenant le groupe le plus puissant. Ce n\'est pas nécessaire et cela engendre une pollution bien trop importante',
        url: "https://blog.materielelectrique.com/groupe-electrogene/",
      },
      {
        category: "Énergie",
        action: "Se raccorder au réseau électrique",
        description:
          "Pour l'alimentation électrique du plateau et des locaux annexes, raccordez-vous au réseau électrique (et avec un fournisseur d'énergie éco-responsable!) au maximum selon la faisabilité",
        conseils: "Anticiper la demande de branchement provisoire auprès des fournisseurs d'energie",
        url: "",
      },
      {
        category: "Énergie",
        action: "Utiliser des groupes électrogènes récents ou des batteries",
        description:
          "Pour limiter votre empreinte carbone et les nuisances aux riverains lorsqu'un racccordement au réseau électrique n'est pas possible, choisissez un groupe électrogène dernière génération ou utilisez des valises énergie ou des batteries",
        conseils: "Les petits groupes électrogènes sont très polluants et interdits dans certaines villes (Paris par exemple)",
        url: "https://www.afcinema.com/Dossier-l-energie-electrique-sur-les-plateaux.html",
      },
      {
        category: "Énergie",
        action: "Utiliser des services LED",
        description: "Pour réduire votre consommation énergétique, utilisez des services LED plutôt que Tungstène (mandarines)",
        conseils: 'Plus efficaces et beaucoup moins énergivores. Demandez à votre loueur de privilégier les LED, y compris pour les "services" de la régie!',
        url: "",
      },
      {
        category: "Énergie",
        action: "Eteindre les appareils en veille",
        description:
          'Pour réduire votre consommation énergétique, pensez à éteindre toute alimentation électrique "en veille" lorsque vous ne les utilisez pas et avant de partir (pensez aux affichages de sensibilisation ad hoc!)',
        conseils: "Une multiprise allumée continue de consommer, un tout petit peu d'énergie. A l'échelle d'un tournage et vu le nombre de multiprises, cela a son impact!",
        url: "https://www.ecoconso.be/fr/content/les-appareils-en-veille-ca-consomme-et-ca-coute-cher",
      },
      {
        category: "Énergie",
        action: "Préférez un refroidisseur d'air plutôt qu'un climatiseur",
        description: "",
        conseils: "Les refroiddisseurs d'air sont disponibles chez vos loueurs de matériel habituels: renseignez-vous!",
        url: "https://www.quechoisir.org/guide-d-achat-rafraichisseur-d-air-n100656/",
      },
      {
        category: "Énergie",
        action: "Baisser le système de chauffage et de refroidissement de l'air",
        description:
          "Pour réduire votre consommation énergétique, pensez à baisser le chauffage ou la ventilation / climatisation lorsque vous ne les utilisez pas et avant de partir (pensez aux affichages de sensibilisation!)",
        conseils: "Vos appareils peuvent avoir un mode vieille préconisé par le constructeur (car moins de consommation qu'en rédémarrant). A vérifier!",
        url: "https://agirpourlatransition.ademe.fr/particuliers/maison/economies-denergie/20-solutions-reduire-consommation-delectricite",
      },
      {
        category: "Énergie",
        action: "Fermer les portes en cas de chauffage ou refroidissement de l'air",
        description: "Pour réduire votre consommation énergétique, veillez à ce que les portes soient refermées lorsque la climatisation ou le chauffage est en marche",
        conseils: "Afficher de la signalétique de rappel pour sensibiliser votre équipe: les pertes d'énergies dans les couloirs sont terribles, par exemple!",
        url: "",
      },
      {
        category: "Énergie",
        action: "Utiliser les ressources du groupe électrogène",
        description:
          "Pour réduire votre consommation énergétique, optimisez la production d'énergie du groupe électrogène (un groupe électrogène tourne obligatoirement à 75% de sa capacité, valorisez l'énergie qui pourrait être perdue notamment en rechargeant des batteries)",
        conseils: "Ne gaspillons pas l'énergie produite!",
        url: "",
      },
    ],
  },
  {
    category: "Mobilité",
    items: [
      {
        category: "Mobilité",
        action: "Encourager la mobilité douce",
        description: "Pour les déplacements domicile-lieu de tournage, encourager la mobilité douce (transports en commun, vélo, marche...)",
        conseils:
          "Encouragez vos salariés à utiliser des mobilités bas carbone (dites douces ou alternatives) afin de réduire l'impact carbone du projet. Transports en commun, vélo, trottinette, co voiturage, de nombreuses solutions existent et sont facilement activables",
        url: "",
      },
      {
        category: "Mobilité",
        action: "Louer au moins un véhicule hybride ou électrique",
        description: "Pour diminuer l'empreinte carbone dûe à l'utilisation des véhicules (thermiques) de location, louer au moins un véhicule électrique ou hybride",
        conseils:
          "Les loueurs de véhicules ou d'utilitaires proposent aujourd'hui souvent des véhicules moins émetteurs de CO2. N'hésitez pas à les solliciter et s'ils ne proposent pas encore ce genre de solutions, à les amener à réfléchir à les proposer par la suite",
        url: "",
      },
      {
        category: "Mobilité",
        action: "Organiser du covoiturage",
        description: "Pour diminuer l'empreinte carbone dûe aux transports, organisez du covoiturage pour les techniciens et les figurants.",
        conseils:
          "Il est important d'adapter le type de véhicule aux distances à parcourir. Une camionnette qui ferait 1000 km par jour sur l'autoroute = diesel! Un véhicule de tourisme qui fait 30 km par jour en ville = éléctrique! De plus, si vous utilisez des véhicules éléctriques, les conducteurs peuvent télécharger des applications comme ChargeMap pour connaitre l'emplacement des bornes de recharge et leurs disponbilités",
        url: "",
      },
      {
        category: "Mobilité",
        action: "Voyager en train",
        description: "Pour limiter l'empreinte carbone dûe aux déplacements (moyenne et longue distances), privilégiez le train à l'avion",
        conseils:
          "Toujours privilégier les voyages nationaux en train; et si l'avion est obligatoire (pour l'étranger par exemple), choissisez des compagnies aériennes plus engagées dans le développement durable et privilégiez la classe éco (beaucoup moins d'impact par voyageur que la classe business)",
        url: "",
      },
      {
        category: "Mobilité",
        action: "Choisir des taxi green",
        description: "Pour diminuer l'empreinte carbone dûe aux déplacements en taxis, optez pour des taxis green",
        conseils: "Ouvrir un compte au nom de la production auprès de sociétés proposant ce service (tous les services de VTC proposent maintenant une option green)",
        url: "",
      },
      {
        category: "Mobilité",
        action: "Encourager la mobilité douce",
        description: "Pour diminuer l'empreinte carbone dûe aux déplacements de l'équipe, encouragez la mobilité douce quand cela est possible",
        conseils: "Si la distance entre les décors et les loges le permet, prévoir des vélos ou trottinettes pour faire les navettes",
        url: "",
      },
      {
        category: "Mobilité",
        action: "Louer un triporteur electrique",
        description:
          "Pour diminuer l'empreinte carbone dûe aux déplacements de l'équipe en tournage, louez un triporteur électrique pour les trajets courts chez les fournisseurs ou entre les décors",
        conseils: "Cette nouvelle forme de mobilité fait son arrivée sur les plateaux de tournage: rapprochez vous de votre loueur pour voir s'il propose ce genre de solutions",
        url: "",
      },
      {
        category: "Mobilité",
        action: "Prévoir des navettes en minibus",
        description:
          "Pour diminuer l'empreinte carbone dûe à l'usage des véhicules personnels dans les déplacements de l'équipe, prévoir une navette (ou covoiturage) dès que la distance à pied est trop importante et/ou que les transports en commun sont inadaptés",
        conseils: "Il est possible de mettre un système de navette entre la station de transport en commun et le décor, entre les loges et le décor, entre deux décors etc..",
        url: "",
      },
      {
        category: "Mobilité",
        action: "Mutualiser les transports comédiens",
        description: "Pour diminuer l'empreinte carbone dûe aux transports des comédiens, privilégiez des transports mutualisés (convocation et pick-ups groupés)",
        conseils:
          "Voir ce qu'il est possible de faire contractuellement parlant: même si les comédien.nes ont des contrats bétonnés, il est souvent possible de trouver des solutions comme par exemple ajuster les horaires de convocations",
        url: "",
      },
      {
        category: "Mobilité",
        action: "Utiliser l'auto-partage",
        description: "Pour diminuer l'empreinte carbone dûe aux déplacements de l'équipe, proposez un abonnement à des véhicules électriques en auto-partage",
        conseils: "Ouvrir un compte au nom de la production auprès des sociétés proposant ce service",
        url: "",
      },
      {
        category: "Mobilité",
        action: "Pratiquer l'éco-conduite",
        description: "Pour un comportement responsable sur la route, sensibilisez les conducteurs à l'éco-conduite",
        conseils: "L'eco-conduite est bonne pour la planète, mais également pour le portefeuille et pour réduire le stress des conducteurs et passagers",
        url: "www.ecoconduite.org",
      },
      {
        category: "Mobilité",
        action: "Faciliter l'usage du vélo",
        description:
          "Pour faciliter l'usage des vélos par l'équipe, prévoyez des aménagements: kit de reparation (pompe à velo, rustine) emplacements pour accrocher les vélos, douches si possible",
        conseils: "Sur les tournages parisiens par exemple, le nombre d'utilisateurs de vélo ne fait qu'augmenter!",
        url: "",
      },
    ],
  },
  {
    category: "Numérique",
    items: [
      {
        category: "Numérique",
        action: "Empreinte numérique",
        description:
          "La phase de diffusion de votre projet n'est pas prise en compte par l'outil pour le calcul du bilan. Cependant il est important de prendre conscience que la diffusion génère également des émissions de CO2. Voici plusieurs études et ressources pour vous aider à anticiper cette phase et réduire votre impact.",
        conseils: "",
        url: "",
      },
      {
        category: "Numérique",
        action: "",
        description:
          "Ce guide répond à un besoin des organisations d'avoir un outil pour les accompagner dans la mise en œuvre de leur démarche et de leur plan d'action pour un numérique plus responsable.",
        conseils: "",
        url: "https://ecoresponsable.numerique.gouv.fr/publications/bonnes-pratiques/",
        urlName: "Guide de bonnes pratiques numérique responsable",
      },
      {
        category: "Numérique",
        action: "",
        description:
          "Rapport du groupe de travail réuni par Hugues Ferreboeuf pour The Shift Project afin de mener une réflexion collective sur les possibilités de synergie entre Transition Numérique et transition énergétique. Le développement exponentiel du Numérique, et la façon dont ce développement peut interagir avec les objectifs de décarbonation de nos sociétés, constitue l'un des plus importants de ces enjeux.",
        conseils: "",
        url: "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/d1cb11a3-7055-4a0f-b8ac-03fe4a232616/Rapport_sur_la_Polution_numrique_-_The_Shift_Project.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20230102%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20230102T130244Z&X-Amz-Expires=86400&X-Amz-Signature=fe572fb5de294d81c435bdf2020dd75e96e2707e46cd163b5c519cfd0bdb6bce&X-Amz-SignedHeaders=host&response-content-disposition=filename%3D%22Rapport%2520sur%2520la%2520Polution%2520num%25C3%25A9rique%2520-%2520The%2520Shift%2520Project.pdf%22&x-id=GetObject",
        urlName: "Rapport sur la pollution numérique - The Shift Project (pdf)",
      },
    ],
  },
];
