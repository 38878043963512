import React, { useState } from "react";

const SearchBox = ({ setSearchedInfo, searchedInfo }) => {
  const [value, setValue] = useState(searchedInfo);
  const handleChange = (e) => {
    const searchedInfo = e.target.value;
    setValue(searchedInfo);
    searchedInfo === "" ? setSearchedInfo("") : setSearchedInfo(searchedInfo);
  };
  const handleInit = () => {
    setValue("");
    setSearchedInfo("");
  };
  return (
    <div id="research" className="w-full h-10 border border-blue-dark flex items-center gap-2.5 py-2.5 px-2.5 rounded-sm relative">
      <img src="/images/icons/search.svg" alt="recherche" className="col-span-4" />
      <input
        key={"searchInput"}
        type="text"
        placeholder="Recherche par mot clé..."
        onChange={handleChange}
        value={value}
        className="flex-1 w-full focus:ring-0 px-0 bg-transparent border-none text-blue-deep-dark placeholder-blue-deep-dark"
      />
      <img src="/images/icons/close.svg" alt="recherche" onClick={handleInit} className="active:scale-[0.8] cursor-pointer transition-transform" />
    </div>
  );
};

export default SearchBox;
