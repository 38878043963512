import { useState, useEffect } from "react";
import { addSubTotals, resetValues } from "../utils/index.js";
import api from "../services/api.js";

// const modificationWarning = (nbModifications) => {
//     if (nbModifications <= 3) {
//         return true
//     }
//     if (nbModifications % 10 === 0) {
//         return true
//     }
//     return false
// }

const useEstimation = (estimationId) => {
  const [estimation, setEstimation] = useState(null);
  // const [spreadsheetCopyId, setSpreadsheetCopyId] = useState(null);
  const [results, setResults] = useState(null);
  const [importResults, setImportResults] = useState(null);
  const [dataImported, setDataImported] = useState(null);
  const [dataImportedError, setDataImportedError] = useState(null);
  const [isDataAddedToEstim, setIsDataAddedToEstim] = useState(false);
  // const [isModificationWarning, setIsModificationWarning] = useState(null);
  const [testOnFileRead, setTestOnFileRead] = useState(null);
  const [importSelected, setImportSelected] = useState(null);

  useEffect(() => {
    if (!estimation) fetchEstimation();
  }, []);

  useEffect(() => {
    if (estimation) {
      saveEstimation();
    }
  }, [estimation]);

  useEffect(() => {
    const addExternalDataToEstimation = async () => {
      let estimationTemp = { ...estimation };
      if (testOnFileRead(dataImported)) {
        const importedData = await importSelected.getImportedData(dataImported);
        const { updatedEstimation, importResults } = addImportedData(estimationTemp, importedData);
        setImportResults(importResults);
        setEstimation(updatedEstimation);
        setIsDataAddedToEstim(true);
      } else {
        setDataImportedError({
          error: "frontError",
          message: "Le fichier importé ne comprend pas les données attendues. Pour plus d'informations, veuillez nous contacter.",
        });
      }
    };
    if (dataImported) {
      addExternalDataToEstimation();
    }
  }, [dataImported]);

  const resetImport = () => {
    setIsDataAddedToEstim(false);
    setDataImported(null);
    setDataImportedError(null);
  };

  const importExternalDatas = (importFunction, file) => {
    importFunction(file, setDataImported);
  };

  const fetchEstimation = async () => {
    const response = await api.get("/estimations/" + estimationId);
    if(response.ok){
      const estimation = response.data;
      setEstimation(estimation);
    }
  };

  const addImportedDataToParameter = (param, importedData) => {
    if (!param || !param.data || param.data.length === 0) return;
    return param.data.map((option) => {
      const code = option.categoryCode;
      if (code && importedData[code] !== undefined) {
        importedData[code].found = true;
        const value = importedData[code].value;
        option.value = value;
        try {
          option.impact = JSON.parse(option.FE.replace(",", ".")) * parseInt(value || 0);
          if (option.unit === "€") option.amount = parseInt(value || 0);
        } catch (error) {
          console.error(error);
        }
      }
      return option;
    });
  };

  const addImportedData = (estimation, importedData) => {
    const updatedForm = estimation.form.map((category) => {
      let categoryImpact = 0;
      let categoryAmount = 0;
      category.parameters?.map((param) => {
        param = addImportedDataToParameter(param, importedData);
        return param;
      });
      const { impact: categoryParamsImpact, amount: categoryParamsAmount } = getParametersImpactAndAmount(category.parameters);
      categoryImpact += categoryParamsImpact;
      categoryAmount += categoryParamsAmount;
      category.subCategories?.map((subCategory) => {
        let subCategoryImpact = 0;
        let subCategoryAmount = 0;
        subCategory.parameters?.map((param) => {
          param = addImportedDataToParameter(param, importedData);
          return param;
        });
        const { impact: subCategoryParamsImpact, amount: subCategoryParamsAmount } = getParametersImpactAndAmount(subCategory.parameters);
        subCategoryImpact += subCategoryParamsImpact;
        subCategoryAmount += subCategoryParamsAmount;
        subCategory.subCategories?.map((subSubCategory) => {
          subSubCategory.parameters?.map((param) => {
            param = addImportedDataToParameter(param, importedData);
            return param;
          });
          const { impact: subSubCategoryImpact, amount: subSubCategoryAmount } = getParametersImpactAndAmount(subSubCategory.parameters);
          subSubCategory.impact = subSubCategoryImpact;
          subSubCategory.amount = subSubCategoryAmount;
          subCategoryImpact += subSubCategoryImpact;
          subCategoryAmount += subSubCategoryAmount;
          return subSubCategory;
        });
        subCategory.impact = subCategoryImpact;
        subCategory.amount = subCategoryAmount;
        categoryImpact += subCategoryImpact;
        categoryAmount += subCategoryAmount;
        return subCategory;
      });
      category.impact = categoryImpact;
      category.amount = categoryAmount;
      return category;
    });
    const updatedEstimation = {
      ...estimation,
      form: updatedForm,
    };
    const importResults = [];
    for (const [key, value] of Object.entries(importedData)) {
      importResults.push({
        description: value.description,
        value: value.value,
        category: key,
        found: value.found ? true : false,
      });
    }
    return {
      updatedEstimation,
      importResults,
    };
  };

  const getParametersImpactAndAmount = (parameters) => {
    if (!parameters || parameters.length === 0) return { impact: 0, amount: 0 };
    return parameters.reduce(
      (total, parameter) => {
        const parameterData = parameter.data.reduce(
          (data, countryData) => {
            return { impact: data.impact + countryData.impact, amount: data.amount + countryData.amount };
          },
          { impact: 0, amount: 0 },
        );
        return { impact: total.impact + parameterData.impact, amount: total.amount + parameterData.amount };
      },
      { impact: 0, amount: 0 },
    );
  };

  const getValue = ({ parameter, value, country, type }) => {
    for (let iCountry = 0; iCountry < parameter.data.length; iCountry++) {
      if (parameter.data[iCountry][type] === country) {
        const countryData = parameter.data[iCountry];
        let impact = 0;
        let amount = 0;
        try {
          impact = JSON.parse(countryData.FE.replace(",", ".")) * parseInt(value || 0);
          if (countryData.unit === "€") amount = parseInt(value || 0);
        } catch (error) {
          console.error(error);
        }
        parameter.data[iCountry] = {
          ...countryData,
          impact,
          amount,
          value,
        };
        break;
      }
    }
    return parameter;
  };

  const updateValueAndImpacts = ({ estimation, value, index, country, type }) => {
    let finalEstimation = { ...estimation };
    finalEstimation.form.forEach((category) => {
      let categoryImpact = 0;
      let categoryAmount = 0;
      if (category.parameters && category.parameters.length > 0) {
        category.parameters.forEach((param) => {
          if (param.index === index) {
            param = getValue({ parameter: param, value, country, type });
          }
        });
        const { impact: categoryParamsImpact, amount: categoryParamsAmount } = getParametersImpactAndAmount(category.parameters);
        categoryImpact += categoryParamsImpact;
        categoryAmount += categoryParamsAmount;
      }
      if (category.subCategories && category.subCategories.length > 0) {
        category.subCategories.forEach((subCategory) => {
          let subCategoryImpact = 0;
          let subCategoryAmount = 0;
          if (subCategory.parameters && subCategory.parameters.length > 0) {
            subCategory.parameters.forEach((param) => {
              if (param.index === index) {
                param = getValue({ parameter: param, value, country, type });
              }
            });
            const { impact: subCategoryParamsImpact, amount: subCategoryParamsAmount } = getParametersImpactAndAmount(subCategory.parameters);
            subCategoryImpact += subCategoryParamsImpact;
            subCategoryAmount += subCategoryParamsAmount;
          }
          if (subCategory.subCategories && subCategory.subCategories.length > 0) {
            subCategory.subCategories.forEach((subSubCategory) => {
              if (subSubCategory.parameters && subSubCategory.parameters.length > 0) {
                subSubCategory.parameters.forEach((param) => {
                  if (param.index === index) {
                    param = getValue({ parameter: param, value, country, type });
                  }
                });
                const { impact: subSubCategoryImpact, amount: subSubCategoryAmount } = getParametersImpactAndAmount(subSubCategory.parameters);
                subSubCategory.impact = subSubCategoryImpact;
                subSubCategory.amount = subSubCategoryAmount;
                subCategoryImpact += subSubCategoryImpact;
                subCategoryAmount += subSubCategoryAmount;
              }
            });
          }
          subCategory.impact = subCategoryImpact;
          subCategory.amount = subCategoryAmount;
          categoryImpact += subCategoryImpact;
          categoryAmount += subCategoryAmount;
        });
      }
      category.impact = categoryImpact;
      category.amount = categoryAmount;
    });
    return finalEstimation;
  };

  const updateEstimation = ({ index, value, country, type = "country" }) => {
    const finalEstimation = updateValueAndImpacts({ estimation, value, index, country, type });
    setEstimation(finalEstimation);
  };

  const saveEstimation = async (updatedEstimation) => {
    const estimationToSave = updatedEstimation ? updatedEstimation : estimation;
    await api.put("/estimations/" + estimationId, { estimation: estimationToSave });
  };

  const validateEstimation = async () => {
    const updatedEstimation = { ...estimation };
    updatedEstimation.validated = true;
    await saveEstimation(updatedEstimation);
  };

  const fillEstimation = async (value) => {
    const strValue = value.toString();
    const updatedEstimation = { ...estimation };
    updatedEstimation.modelInfos.map((info) => {
      if (info.input && info.value === "") {
        info.value = strValue;
      }
      return info;
    });
    // await setEstimation(updatedEstimation)
  };

  // const closeModificationWarning = () => {
  //     setIsModificationWarning(false)
  // }

  return {
    estimation,
    results,
    // isModificationWarning,
    importSelected,
    dataImported,
    dataImportedError,
    isDataAddedToEstim,
    importResults,
    dispatch: {
      setEstimation,
      // closeModificationWarning,
      updateEstimation,
      saveEstimation,
      validateEstimation,
      importExternalDatas,
      fillEstimation,
      resetImport,
      setTestOnFileRead,
      setImportSelected,
    },
  };
};

export default useEstimation;
