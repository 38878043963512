import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

const MyTextField = withStyles({
  root: {
    textAlign: "right",
    "& .MuiOutlinedInput-root": {
      borderRadius: "2px",
      backgroundColor: "white",
      "& fieldset": {
        border: "1px solid var(--backDarkBlue)",
        fontFamily: "var(--mainPolice)",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid var(--backDarkBlue)",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 0px",
      textAlign: "right",
      color: "var(--textDarkBlue)",
      fontFamily: "var(--mainPolice)",
    },
    "& .MuiFilledInput-root": {
      borderRadius: "2px",
      backgroundColor: "var(--backBackBlue)",
      border: "1px solid transparent",
    },
    "& .MuiFilledInput-underline:before": {
      border: "none",
    },
    "& .MuiFilledInput-input": {
      padding: "10px 0px",
      textAlign: "right",
      fontFamily: "var(--mainPolice)",
      color: "var(--textDarkBlue)",
    },
  },
})(TextField);
const ProjectInformation = ({ estimation, saveEstimation }) => {
  const [values, setValues] = useState(estimation?.information);

  const handleBlur = (e) => {
    e.preventDefault();
    saveEstimation({ ...estimation, information: values });
  };

  return (
    <div id="firstCategory_0">
      <div className="h-16 px-4 py-5 bg-[#CFDCE3] font-bold text-xl grid grid-cols-[10%_60%_30%]" id={values._id}>
        <p>{0}</p>
        <p>Informations projet</p>
      </div>
      <div className="flex flex-row justify-between mx-9 mt-4">
        <p>Titre du film</p>
        <MyTextField
          type="text"
          InputProps={{ endAdornment: <InputAdornment position="end"></InputAdornment> }}
          className="w-56"
          variant="outlined"
          value={values.title}
          onChange={(e) => setValues((prev) => ({ ...prev, title: e.target.value }))}
          onBlur={(e) => handleBlur(e)}
        />
      </div>
      <div className="flex flex-row justify-between mx-9 mt-4">
        <p>Type de bilan</p>
        <MyTextField
          type="text"
          InputProps={{ endAdornment: <InputAdornment position="end"></InputAdornment> }}
          className="w-56"
          variant="outlined"
          value={values.carbonFootprintType}
          disabled={true}
        />
      </div>
      <div className="flex flex-row justify-between mx-9 mt-4">
        <p>Type d'oeuvre</p>
        <MyTextField
          type="text"
          InputProps={{ endAdornment: <InputAdornment position="end"></InputAdornment> }}
          className="w-56"
          variant="outlined"
          value={values.typeOfWork}
          disabled={true}
        />
      </div>
      <div className="flex flex-row justify-between mx-9 mt-4">
        <p>Format</p>
        <MyTextField
          type="text"
          InputProps={{ endAdornment: <InputAdornment position="end"></InputAdornment> }}
          className="w-56"
          variant="outlined"
          value={values.productionType}
          disabled={true}
        />
      </div>
      <div className="flex flex-row justify-between mx-9 mt-4">
        <p>Genre</p>
        <MyTextField
          type="text"
          InputProps={{ endAdornment: <InputAdornment position="end"></InputAdornment> }}
          className="w-56"
          variant="outlined"
          value={values.genre}
          disabled={true}
        />
      </div>
      <div className="flex flex-row justify-between mx-9 mt-4">
        <p>Nom production</p>
        <MyTextField
          type="text"
          InputProps={{ endAdornment: <InputAdornment position="end"></InputAdornment> }}
          className="w-56"
          variant="outlined"
          value={values.productionName}
          onChange={(e) => setValues((prev) => ({ ...prev, productionName: e.target.value }))}
          onBlur={(e) => handleBlur(e)}
        />
      </div>
      <div className="flex flex-row justify-between mx-9 mt-4">
        <p>Lieu(x) de tournage</p>
        <MyTextField
          type="text"
          InputProps={{ endAdornment: <InputAdornment position="end"></InputAdornment> }}
          className="w-56"
          variant="outlined"
          value={values.shootingLocation}
          onChange={(e) => setValues((prev) => ({ ...prev, shootingLocation: e.target.value }))}
          onBlur={(e) => handleBlur(e)}
        />
      </div>
      <div className="flex flex-row justify-between mx-9 mt-4">
        <p>Date de tournage</p>
        <MyTextField
          type="date"
          InputProps={{ endAdornment: <InputAdornment position="end"></InputAdornment> }}
          className="w-56"
          variant="outlined"
          value={values.shootingDate}
          onChange={(e) => setValues((prev) => ({ ...prev, shootingDate: e.target.value }))}
          onBlur={(e) => handleBlur(e)}
        />
      </div>
      <div className="flex flex-row justify-between mx-9 mt-4">
        <p>Budget</p>
        <MyTextField
          type="number"
          className="w-56"
          variant="outlined"
          InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
          value={values.budget}
          onChange={(e) => setValues((prev) => ({ ...prev, budget: e.target.value }))}
          onBlur={(e) => handleBlur(e)}
        />
      </div>
      <div className="flex flex-row justify-between mx-9 mt-4">
        <p>Nombre de jours de tournage</p>
        <MyTextField
          type="number"
          InputProps={{ endAdornment: <InputAdornment position="end"></InputAdornment> }}
          className="w-56"
          variant="outlined"
          value={values.shootingDays}
          onChange={(e) => setValues((prev) => ({ ...prev, shootingDays: e.target.value }))}
          onBlur={(e) => handleBlur(e)}
        />
      </div>
      <div className="flex flex-row justify-between mx-9 mt-4">
        <p>Responsable estimation carbone</p>
        <MyTextField
          type="text"
          InputProps={{ endAdornment: <InputAdornment position="end"></InputAdornment> }}
          className="w-56"
          variant="outlined"
          value={values.carbonFootprintResponsible}
          onChange={(e) => setValues((prev) => ({ ...prev, carbonFootprintResponsible: e.target.value }))}
          onBlur={(e) => handleBlur(e)}
        />
      </div>
    </div>
  );
};

export default ProjectInformation;
