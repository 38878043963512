import React from "react";
import { Chart as ChartJS, BarElement, Tooltip, CategoryScale, LinearScale } from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(BarElement, Tooltip, CategoryScale, LinearScale);

const getHistogramDatas = (data) => {
  const nbBars = 10;

  const finalData = data.map((v) => Math.round(v / 1000));

  const dataMin = Math.round(Math.min(...finalData));
  const dataMax = Math.round(Math.max(...finalData));
  let step = (dataMax - dataMin) / (nbBars - 1);

  let rangeMin = null;
  let rangeMax = null;
  if (dataMin - step / 2 < 0) {
    rangeMin = 0;
    rangeMax = dataMax + dataMin - rangeMin;
    step = rangeMax / 10;
  } else {
    rangeMin = dataMin - step / 2;
    rangeMax = dataMax + step / 2;
  }

  let barDatas = [];

  if (data.length === 1) {
    barDatas = [
      {
        value: data[0],
        nb: 1,
      },
    ];
  } else {
    // 1. Build an array with all values possible
    const values = [rangeMin + step / 2];
    let i = 0;

    while (values[i] < rangeMax - step / 2) {
      i++;
      values.push(values[0] + i * step);
    }

    // 2. Build the expected array
    values.forEach((value) => {
      const nbValues = finalData.filter((v) => v >= value - step / 2 && v <= value + step / 2).length;
      barDatas.push({
        value: value,
        nb: nbValues,
      });
    });
  }

  return barDatas;
};

const DistributionHistogram = ({ data, unit, color, title }) => {
  const barDatas = getHistogramDatas(data);

  return (
    <div className="w-full mb-4">
      <Bar
        data={{
          datasets: [
            {
              data: barDatas.map((e) => e.value),
            },
          ],
          labels: barDatas.map((e) => parseInt(e.value)),
        }}
        title={`${title} - ${unit}`}
        color={color}
        options={{
          plugins: { legend: { display: false } },
          scales: {
            y: {
              title: {
                display: true,
                text: "Nb Résultats",
              },
              grid: {
                display: false,
              },
              ticks: {
                callback: (value, index) => index,
              },
            },
            x: {
              title: {
                display: true,
                text: title,
              },
              grid: {
                display: false,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default DistributionHistogram;
