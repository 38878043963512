import React from "react";
import { Route, Routes } from "react-router-dom";

import New from "./new";
import Edit from "./edit";
import Result from "./result";

const Admin = () => {
  return (
    <Routes>
      <Route path="/new" element={<New />} />
      <Route path="/:id" element={<Edit />} />
      <Route path="/:id/result" element={<Result />} />
    </Routes>
  );
};

export default Admin;
