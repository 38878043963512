/* eslint-disable array-callback-return */
import api from "../services/api";

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const average = (arr) => {
  if (arr.length === 0) {
    return null;
  }
  const total = arr.reduce((acc, val) => {
    return acc + val;
  }, 0);
  return total / arr.length;
};

export const median = (values) => {
  if (values.length === 0) return 0;

  values.sort(function (a, b) {
    return a - b;
  });

  let half = Math.floor(values.length / 2);
  if (values.length % 2) return values[half];
  return (values[half - 1] + values[half]) / 2.0;
};

export const standardDeviation = (values) => {
  var avg = average(values);
  var squareDiffs = values.map(function (value) {
    var diff = value - avg;
    var sqrDiff = diff * diff;
    return sqrDiff;
  });

  var avgSquareDiff = average(squareDiffs);

  var stdDev = Math.sqrt(avgSquareDiff);
  return stdDev;
};

export const isInfoInArray = (array, searchedInfo) => {
  if (!searchedInfo) {
    return true;
  }
  for (let i = 0; i < array.length; i++) {
    if (array[i].toLowerCase().includes(searchedInfo.toLowerCase())) {
      return true;
    }
  }
  return false;
};

export const getCO2Equivalence = (number) => {
  // if (number > 100000) {
  //     return {
  //         number: formatNumber(number/25000),
  //         unit: "années",
  //         precision: "d'un foyer français",
  //         icon: "balance"
  //     }
  // }
  if (number > 1000) {
    return {
      number: formatNumber(number / 1000 / 0.5),
      unit: "",
      precision: "vols Paris / NY",
      icon: "balance",
    };
  } else {
    return {
      number: formatNumber(number * 5.181),
      unit: "kms",
      precision: "en voiture (moyenne)",
      icon: "balance",
    };
  }
};

export const formatNumber = (number) => {
  if (isNaN(number)) {
    if (!isNaN(Math.round(number))) {
      number = Math.round(number);
    } else {
      return number; //could be a string
    }
  }
  number > 10 ? (number = Math.round(number)) : (number = Math.round(number * 10) / 10);
  number = number.toLocaleString();
  return number;
};

export const formatAndConvertNumber = (number, type) => {
  if (isNaN(number)) {
    return 0;
  } else {
    let unit = "";
    const units = {
      emissions: ["kgCO2eq", "tCO2eq", "ktCO2eq"],
      amount: ["€", "k€", "M€"],
    };
    if (number < 1000) {
      unit = units[type][0];
    }
    if (number >= 1000) {
      number = number / 1000;
      unit = units[type][1];
      if (number > 1000 && type === "amount") {
        number = number / 1000;
        unit = units[type][2];
      }
    }
    number = formatNumber(number);
    return { number, unit };
  }
};

export const resetValues = (estimation) => {
  estimation.modelInfos.map((infos) => {
    if (infos.input) {
      infos.value = "";
    }
    return infos;
  });
};

export const getFlattenParameters = (form) => {
  let flattenParameters = [];
  form.forEach((category) => {
    if (category.parameters && category.parameters.length > 0) flattenParameters.push(...category.parameters);
    if (category.subCategories) {
      category.subCategories.forEach((subCategory) => {
        if (subCategory.parameters && subCategory.parameters.length > 0) flattenParameters.push(...subCategory.parameters);
        if (subCategory.subCategories) {
          subCategory.subCategories.forEach((subSubCategory) => {
            if (subSubCategory.parameters && subSubCategory.parameters.length > 0) flattenParameters.push(...subSubCategory.parameters);
          });
        }
      });
    }
  });
  return flattenParameters;
};

export const getNbAnswers = (flattenParameters) => {
  return flattenParameters.filter((parameter) => {
    for (let i = 0; i < parameter.data.length; i++) {
      if (parameter.data[i].value !== "") return true;
    }
    return false;
  }).length;
};

export const getNbEmpty = (flattenParameters) => {
  return flattenParameters.filter((parameter) => {
    for (let i = 0; i < parameter.data.length; i++) {
      if (parameter.data[i].value !== "") return false;
    }
    return true;
  }).length;
};

export const getProgress = (estimation) => {
  if (!estimation) {
    return 0;
  }
  const flattenParameters = getFlattenParameters(estimation.form);
  const totalInputs = flattenParameters.length;
  const answeredInputs = flattenParameters.filter((parameter) => {
    for (let i = 0; i < parameter.data.length; i++) {
      if (parameter.data[i].value !== "") return true;
    }
    return false;
  }).length;
  return Math.floor((answeredInputs / totalInputs) * 100);
};

export const modificationLimit = (nbModifications) => {
  if (nbModifications === 0) {
    return true;
  }
  return false;
};

//DASHBOARD

export const getTotalEmission = (categories) => {
  if (!categories || categories.length === 0) return 0;
  return categories.reduce((total, category) => total + category.impact, 0);
};

export const getTotalAmount = (categories) => {
  if (!categories || categories.length === 0) return 0;
  return categories.reduce((total, category) => total + category.amount, 0);
};

export const getTimeFromStringDate = (stringDate) => {
  return new Date(stringDate).getTime();
};

export const sortEstimations = (estimations, sortMode) => {
  let newEstimations = JSON.parse(JSON.stringify(estimations));
  if (sortMode === "new") {
    newEstimations.sort(function (a, b) {
      const dateA = getTimeFromStringDate(a.createdAt);
      const dateB = getTimeFromStringDate(b.createdAt);
      return dateB - dateA;
    });
  } else if (sortMode === "alpha") {
    newEstimations.sort(function (a, b) {
      const titleA = getEstimationTitle(a);
      const titleB = getEstimationTitle(b);
      return titleA.localeCompare(titleB);
    });
  } else if (sortMode === "budget") {
    newEstimations.sort(function (a, b) {
      return b.results.totalAmount - a.results.totalAmount;
    });
  }
  return newEstimations;
};

export const deleteSheet = () => {
  if (sessionStorage.getItem("spreadsheetCopyInfos")) {
    const spreadsheetCopyId = JSON.parse(sessionStorage.getItem("spreadsheetCopyInfos"))._id;
    sessionStorage.removeItem("spreadsheetCopyInfos");
    api.delete("/models/sheet", spreadsheetCopyId).catch((err) => console.log(err));
  }
};

export const addSubTotals = (estimation) => {
  const getSubTotal = (category, categoryHierarchy) => {
    const rowsOfCategory = estimation.modelInfos.filter((infos) => infos.categories[categoryHierarchy - 1] === category);
    const subTotal = rowsOfCategory.reduce((acc, val) => {
      if (val.input && val.type === "amount") {
        return acc + Number(val.value);
      } else {
        return acc;
      }
    }, 0);
    return subTotal;
  };
  estimation.modelInfos.map((row) => {
    if (!row.input) {
      row.subTotal = getSubTotal(row.categories[row.hierarchy - 1], row.hierarchy);
    }
    return row;
  });
};

export const getCategoryProgress = (estimation, category, categoryHierarchy) => {
  const rowsOfCategory = estimation.modelInfos.filter((info) => info.categories[categoryHierarchy - 1] === category);
  const totalInputs = rowsOfCategory.filter((info) => info.input).length;
  const answeredInputs = rowsOfCategory.filter((info) => info.input && info.value !== "").length;
  return { answeredInputs, totalInputs };
};

export const getEstimationTitle = (estimation) => {
  return estimation?.information?.title && estimation.information.title.length > 0 ? estimation.information.title : "Mon Estimation";
};

export const getEstimationValueFromName = (estimation, name) => {
  if (!estimation) return "";
  const keys = Object.keys(estimation.information);
  const found = keys.findIndex((info) => info === name);
  return Object.values(estimation.information)[found];
};

export const getRangeIntersection = (range1, range2) => {
  if (range1[1] < range2[0] || range1[0] > range2[1]) {
    return 0;
  }
  const min = Math.max(range1[0], range2[0]);
  const max = Math.min(range1[1], range2[1]);
  return [min, max];
};

export const getRangeLength = (range) => {
  if (range === 0) {
    return 0;
  }
  return range[1] - range[0];
};

export const getMostVisibleCategory = (categoryIDs, scrollPosition, startOffSet, endOffset) => {
  if (!document.getElementById(categoryIDs[0])) {
    return categoryIDs[0];
  }

  const windowHeight = window.innerHeight;
  const windowRange = [scrollPosition + startOffSet, windowHeight + scrollPosition - endOffset];

  let intersectionLength = [];
  categoryIDs.forEach((categoryID) => {
    const categoryElement = document.getElementById(categoryID);
    const categoryPosition = categoryElement.offsetTop;
    const categoryHeight = categoryElement.offsetHeight;
    const categoryRange = [categoryPosition, categoryPosition + categoryHeight];
    const rangeIntersection = getRangeIntersection(categoryRange, windowRange);
    intersectionLength.push(getRangeLength(rangeIntersection));
  });
  const iMax = intersectionLength.indexOf(Math.max(...intersectionLength));
  return categoryIDs[iMax];
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function capitalizeFirstLetter(string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
