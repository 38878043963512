import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchBox from "components/SearchBox";

import api from "services/api";
import { isInfoInArray } from "utils";

//Components
import ModalNewUser from "components/admin/ModalNewUser";
import ModalNotEnoughCredits from "components/admin/ModalNotEnoughCredits";
import MyIcon from "components/partials/MyIcon";
import TextField from "components/Base/TextField";
import { Formik, Form } from "formik";
import { CSVLink } from "react-csv";
import useStore from "Stores/zustand";

const Users = () => {
  const query = new URLSearchParams(window.location.search);
  const [users, setUsers] = useState(null);
  const [searchedInfo, setSearchedInfo] = useState("");
  const [filtre, setFiltre] = useState(query.get("role") || "all");
  const [hasPaidFilter, setHasPaidFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [isNewUserOpen, setIsNewUserOpen] = useState(false);

  const fetchUsers = () => {
    setLoading(true);
    api
      .get(`/admin/users?role=${filtre}&hasPaid=${hasPaidFilter}`)
      .then((res) => {
        if (res.ok) {
          setUsers(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [filtre, hasPaidFilter]);

  return (
    <div id="user_admin" className="grid grid-rows-[40px_calc(100vh-134px)] h-full gap-2.5">
      <ModalNewUser isOpen={isNewUserOpen} close={() => setIsNewUserOpen(false)} fetchUsers={fetchUsers} />
      <header className="flex flex-wrap gap-2.5">
        <button className="w-[200px] h-10 flex items-center justify-center gap-2 pl-2 blue-button" onClick={() => setIsNewUserOpen(true)}>
          <MyIcon icon="add" />
          Nouvel Utilisateur
        </button>
        {!loading && <ExportUser users={users} />}
        <div className="">
          <SearchBox searchedInfo={searchedInfo} setSearchedInfo={setSearchedInfo} />
        </div>

        <select value={filtre} onChange={(e) => setFiltre(e.target.value)} className="w-40 h-10 border border-blue-dark shadow-sm focus:outline-none sm:text-sm bg-transparent">
          <default value="all">Filtrer les roles</default>
          <option value="all">Tous</option>
          <option value="cnc">CNC</option>
          <option value="cncUnverified">CNC à valider</option>
          <option value="user">Utilisateur</option>
          <option value="admin">Admin</option>
          <option value="superAdmin">Super Admin</option>
        </select>
        <select
          value={hasPaidFilter}
          onChange={(e) => setHasPaidFilter(e.target.value)}
          className="w-40 h-10 border border-blue-dark shadow-sm focus:outline-none sm:text-sm bg-transparent">
          <option value="">Tous les plans</option>
          <option value="true">Premium</option>
          <option value="false">Gratuit</option>
        </select>
      </header>
      {!loading ? <UsersList _fetch={fetchUsers} users={users} searchedInfo={searchedInfo} /> : <p>Chargement...</p>}
    </div>
  );
};

const ExportUser = ({ users }) => {
  const fileName = "Seco2 - Utilisateurs.csv";
  const headers = [
    { label: "email", key: "email" },
    { label: "Prénom", key: "firstName" },
    { label: "Nom", key: "lastName" },
    { label: "Société", key: "companyName" },
    { label: "Crédits", key: "credits" },
    { label: "Nb Estimations", key: "nbEstim" },
    { label: "Inscription", key: "createdAt" },
  ];
  return (
    <button className="w-[200px] h-10 orange-button flex items-center justify-center gap-2 pl-2">
      <MyIcon icon="document" />
      <CSVLink data={users} filename={fileName} target="_blank" headers={headers}>
        Télécharger
      </CSVLink>
    </button>
  );
};

const UsersList = ({ users, searchedInfo, _fetch }) => {
  if (!users || users.length === 0) {
    return <p>Aucun utilisateur</p>;
  }
  return (
    <div id="user_table_wrapper" className="w-full h-full p-1 bg-white overflow-auto">
      <table id="user_list" className="w-full">
        <thead className="border-b border-blueBorder">
          <tr className="text-left">
            <th scope="col">Date Création</th>
            <th scope="col">Utilisateur</th>
            <th scope="col">Email</th>
            <th scope="col">Société</th>
            <th scope="col">Rôle</th>
            <th scope="col">Nb Estim.</th>
            <th scope="col">Crédits</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, i) => (
            <UserRow _fetch={_fetch} key={i} user={user} i={i} searchedInfo={searchedInfo} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const UserRow = ({ user, i, searchedInfo, _fetch }) => {
  const [role, setRole] = useState(user.role);

  const navigate = useNavigate();

  const date = new Date(user.createdAt);
  if (searchedInfo && !isInfoInArray([user.firstName, user.lastName, user.email, user.role], searchedInfo)) {
    return <></>;
  }
  return (
    <tr key={i} className="cursor-pointer hover:bg-gray-50" onClick={() => navigate(`/admin/users/${user._id}`)}>
      <td>{date.toLocaleDateString()}</td>
      <td>
        <div>
          {`${user.firstName} ${user.lastName}`}
          {user.hasPaid ? <div className="w-fit text-sm px-2 bg-yellow-600 text-white rounded-full">premium</div> : null}
        </div>
      </td>
      <td>{user.email}</td>
      <td>{user.companyName}</td>
      <td onClick={(e) => e.stopPropagation()}>
        <RoleInput role={role} setRole={setRole} />
      </td>
      <td>{user.nbEstim}</td>
      <td onClick={(e) => e.stopPropagation()} className="user_credits">
        {/* <CreditsInput _fetch={_fetch} role={role} user={user} /> */}
      </td>
    </tr>
  );
};

const RoleInput = ({ role, setRole }) => {
  return (
    <select
      value={role}
      onChange={(e) => {
        setRole(e.target.value);
      }}
      className="w-30 h-10 border-0 rounded-md focus:outline-none sm:text-sm hover:bg-backgrounds">
      <option value="cnc">CNC</option>
      <option value="cncUnverified">CNC à valider</option>
      <option value="user">Utilisateur</option>
      <option value="admin">Admin</option>
      <option value="superAdmin">Super Admin</option>
    </select>
  );
};

const CreditsInput = ({ user, role, _fetch }) => {
  const [userCredits, setUserCredits] = useState(user.credits);
  const [modalProps, setModalProps] = useState(null);
  const [isNoCreditsOpen, setIsNoCreditsOpen] = useState(false);
  const { user: admin, setUser: setAdmin } = useStore();

  useEffect(() => {
    setUserCredits(user.credits);
  }, [user.credits]);

  const handleSubmit = (data, actions) => {
    const creditsAdded = data.credits - userCredits;
    const creditsAvailable = admin.credits;
    if (creditsAdded > admin.credits) {
      setModalProps({ creditsAdded, creditsAvailable });
      setIsNoCreditsOpen(true);
      actions.resetForm();
    } else {
      api
        .patch("/auth/" + user._id.toString(), { creditsAdded: creditsAdded, role: role })
        .then(async (res) => {
          if (res.ok) {
            setUserCredits(res.data.credits); // rather than data.credits : concurrency management
            if (admin.role !== "superAdmin") {
              const res = await api.put(`/auth`, { credits: admin.credits - creditsAdded });
              if (res.ok) setAdmin(res.data);
            }
          }
        })
        .catch((err) => console.log(err));
      _fetch();
    }
  };

  return (
    <>
      <ModalNotEnoughCredits isOpen={isNoCreditsOpen} close={() => setIsNoCreditsOpen(false)} modalProps={modalProps} />
      <Formik
        enableReinitialize
        initialValues={{
          credits: userCredits,
        }}
        validateOnBlur={true}
        onSubmit={handleSubmit}>
        <Form className="my-4">
          <TextField name="credits" type="number" min="0" />
          <button className="blue-button w-10 h-6" type="submit">
            Ok
          </button>
        </Form>
      </Formik>
    </>
  );
};

export default Users;
