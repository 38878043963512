import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../services/api";
import useStore from "../../Stores/zustand";

const LoginAs = () => {
  const { setUser } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const id = searchParams.get("id");
    const token = searchParams.get("token");
    api
      .post(`/auth/loginas/${id}`, { token })
      .then((res) => {
        if (res.ok) {
          setUser(res.data);
          navigate("/");
        }
      })
      .catch(() => toast.error("Une erreur est survenue"));
  }, []);

  return <></>;
};

export default LoginAs;
