import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

export const Header = ({ user }) => (
  <header className="w-full h-16 sticky top-0 z-50 bg-white grid grid-cols-3 items-center px-16 border-b border-blue-light">
    {user.role === "cnc" ? (
      <Link to="/estimation/find">
        <img src="/images/logos/Logo_seco2_bleu.png" alt="logo secoya" className="max-w-[100px]" />
      </Link>
    ) : (
      <Link to="/">
        <img src="/images/logos/Logo_seco2_bleu.png" alt="logo secoya" className="max-w-[100px]" />
      </Link>
    )}
    <h1 className="font-bold text-xl text-center">Bienvenue {user.firstName}</h1>
  </header>
);

export const AdminHeader = () => (
  <header className="w-full h-16 sticky top-0 z-50 bg-white grid grid-cols-3 items-center px-16 border-b border-blue-light">
    <Link to="/" className="font-semibold flex items-center">
      <BsChevronLeft className="mr-2" />
      Retour au tableau de bord
    </Link>
    <h1 className="font-bold text-xl text-center">Administration</h1>
  </header>
);
