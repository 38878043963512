import React from "react";
import { Helmet } from "react-helmet";

import { StatisticsBox } from "components/results/StatisticsBox";
import { getTotalEmission, getCO2Equivalence, getTotalAmount } from "../../utils";
import { MainInfosBox } from "components/results/MainInfosBox";
import { referencingInfos } from "../../utils/config";
import api from "../../services/api";

import MyIcon from "components/partials/MyIcon";
import { CSVLink } from "react-csv";

const getCncData = (form) => {
  if (!form) return;
  let data = [];
  form.forEach((category) => {
    category.subCategories?.forEach((subCategory) => {
      data.push({
        category: category.name,
        subCategory: subCategory.name,
        amount: subCategory.amount,
        impact: subCategory.impact,
      });
    });
  });
  return data;
};

const UniqueId = () => {
  const uniqueId = React.useRef(null);
  const [error, setError] = React.useState(false);
  const [estimation, setEstimation] = React.useState(null);

  const getEstimationByUniqueId = async (uniqueId) => {
    if (!uniqueId) return setError(true);
    setError(false);
    setEstimation(null);
    try {
      const response = await api.get(`/estimations/by-unique-id/${uniqueId}`);
      if (response.ok) {
        setEstimation(response.data);
      }
    } catch (error) {
      setError(true);
    }
  };
  const handleEnter = (e) => {
    if (e.key === "Enter") getEstimationByUniqueId(uniqueId.current.value);
  };

  return (
    <section className="bg-white p-4 w-full h-full pr-2 overflow-y-auto">
      <Helmet>
        <title>{referencingInfos.uniqueId.title}</title>
        <meta name="description" content={referencingInfos.uniqueId.description} />
      </Helmet>
      <h2 className="mb-4 font-bold">Entrer un identifiant de rapport</h2>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="flex gap-2.5 w-3/5">
          <input
            type="text"
            onKeyDown={handleEnter}
            placeholder={`S2${new Date().getFullYear()}-63a116g37c5g1c06fc`}
            size={22}
            className="border border-blue-dark rounded-sm w-full"
            ref={uniqueId}
          />
          <button
            type="button"
            className="!w-max flex-0 blue-button"
            onClick={() => {
              getEstimationByUniqueId(uniqueId.current.value);
            }}>
            Valider
          </button>
        </div>

        {error && (
          <div className="text-lg mt-16 text-center">
            {uniqueId.current.value.length === 0 ? (
              "Veuillez entrer un identifiant"
            ) : (
              <div>
                <p>Aucun résultat pour l'id {uniqueId.current.value}</p>
                <p className="mt-4">Cela veut dire qu'il n'y a pas de bilan carbone associé à cet ID.</p>
                <p>Vérifiez qu'il n y a pas d'erreur dans l'ID, sinon rapprochez vous de la société de production pour vérifier auprès d'eux.</p>
              </div>
            )}
          </div>
        )}
        {estimation && (
          <div className="text-lg mt-16 text-center">
            <p className="mb-10">Nous avons trouvé un bilan carbone pour cet ID !</p>
            <StatisticsBox
              movieType={estimation.information.productionType}
              totalAmount={getTotalAmount(estimation.form)}
              equivalenceInfos={getCO2Equivalence(getTotalEmission(estimation.form))}
              totalEmissions={getTotalEmission(estimation.form)}
              emissionsPerEuro={getTotalEmission(estimation.form) / getTotalAmount(estimation.form)}
              scope="general"
            />
            <MainInfosBox estimation={estimation} />
            <ExportData form={estimation?.form} uniqueId={uniqueId} />
          </div>
        )}
      </div>
    </section>
  );
};

const ExportData = ({ form, uniqueId }) => {
  const cncData = getCncData(form);
  const fileName = `Seco2 - Synthèse ${uniqueId.current.value}`;
  const headers = [
    { label: "Poste", key: "category" },
    { label: "Donnée d'activité", key: "subCategory" },
    { label: "Donnée d'entrée", key: "amount" },
    { label: "tCO2e", key: "impact" },
  ];
  return (
    <button className="w-[200px] h-10 orange-button flex items-center justify-center gap-2 pl-2">
      <MyIcon icon="document" />
      <CSVLink data={cncData} filename={fileName} target="_blank" headers={headers} separator={";"}>
        Synthèse .csv
      </CSVLink>
    </button>
  );
};

export default UniqueId;
