import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form } from "formik";
import validator from "validator";
import { toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import api from "../../services/api";
import { Loader } from "../../components/loader";
import Logo from "../../assets/img/logo-bleu.png";
import useStore from "../../Stores/zustand";

const ResetPassword = () => {
  const { setUser } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const validate = (values, setErrors) => {
    const errors = {};
    if ((values.password || "").length < 8) errors.password = "Le mot de passe doit contenir 8 caractères minimum";
    if (!validator.equals(values.password, values.confirmPassword)) errors.confirmPassword = "Les mots de passe ne correspondent pas";
    setErrors(errors);
    return !errors.password && !errors.confirmPassword;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (loading) return;
    if (!validate(values, setErrors)) return;
    setLoading(true);

    api
      .post("/auth/reset-password", { password: values.password, token: searchParams.get("token") })
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          setUser(res.data);
          navigate("/");
        } else {
          setErrors({ password: "Une erreur est survenue" });
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Une erreur est survenue");
      });
  };

  return (
    <div className="flex flex-col items-center">
      <img src={Logo} alt="Seco2" className="w-44" />
      <p className="text-gray-500">Réinitialiser votre mot de passe</p>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        onSubmit={handleSubmit}>
        {({ values, errors, handleChange }) => (
          <Form className="my-4 flex flex-col items-center w-80">
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-1" htmlFor="password">
                Nouveau mot de passe
              </label>
              <div className="relative w-full">
                <input
                  className="input w-full border border-gray-400 pr-10"
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Votre mot de passe"
                />
                {passwordVisible ? (
                  <AiFillEyeInvisible className="absolute right-3 top-1.5 z-10 cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(false)} />
                ) : (
                  <AiFillEye className="absolute right-3 top-1.5 z-10 cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(true)} />
                )}
              </div>
              {errors.password && <div className="flex items-center text-sm text-red-500">{errors.password}</div>}
            </div>
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-1" htmlFor="confirmPassword">
                Confirmer le mot de passe
              </label>
              <div className="relative w-full">
                <input
                  className="input w-full border border-gray-400 pr-10"
                  type={confirmPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirmez votre mot de passe"
                />
                {confirmPasswordVisible ? (
                  <AiFillEyeInvisible className="absolute right-3 top-1.5 z-10 cursor-pointer text-2xl text-blue-dark" onClick={() => setConfirmPasswordVisible(false)} />
                ) : (
                  <AiFillEye className="absolute right-3 top-1.5 z-10 cursor-pointer text-2xl text-blue-dark" onClick={() => setConfirmPasswordVisible(true)} />
                )}
              </div>

              {errors.confirmPassword && <div className="flex items-center text-sm text-red-500">{errors.confirmPassword}</div>}
            </div>

            <button className="blue-button my-8 text-lg" type="submit" disabled={loading}>
              {loading ? <Loader color="white" /> : "Réinitialiser"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ResetPassword;
