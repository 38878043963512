import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BsChevronLeft, BsCheckCircle, BsFillQuestionCircleFill, BsTrash3, BsDownload } from "react-icons/bs";
import fr from "date-fns/locale/fr";
import { DayPicker } from "react-day-picker";
import { toast } from "react-toastify";
import slugify from "slugify";

import api from "../../services/api";
import { Loader } from "../../components/loader";
import { Select } from "../../components/select";
import { ValidateBilanModal } from "./components/validation-modal";
import { HelpModal } from "./components/help-modal";
import { ChooseImportModal } from "./components/import-modal";
import { BILAN_TYPES } from "./utils/static";
import { getProgress, getUnit } from "./utils/bilan";
import { capitalizeFirstLetter } from "../../utils";
import useStore from "../../Stores/zustand";

const Edit = () => {
  const { id } = useParams();
  const { user } = useStore();
  const [search, setSearch] = useState();
  const [bilan, setBilan] = useState();
  const [project, setProject] = useState();
  const [progress, setProgress] = useState();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [isChooseImportOpen, setIsChooseImportOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("help")) setIsHelpModalOpen(true);

    api.get(`/bilan/${id}`).then((res) => {
      if (res.ok) {
        // set bilan and order the categories and subcategories
        const newBilan = { ...res.data.bilan };
        newBilan.matrice = newBilan.matrice.sort((categoryA, categoryB) => {
          if (!categoryA?.code) return 1;
          if (!categoryB?.code) return -1;
          return categoryA?.code - categoryB?.code;
        });
        newBilan.matrice = newBilan.matrice.map((category) => {
          const newCategory = { ...category };
          newCategory.categories.sort((sousCategoryA, sousCategoryB) => {
            if (!sousCategoryA?.code) return 1;
            if (!sousCategoryB?.code) return -1;
            return sousCategoryA?.code - sousCategoryB?.code;
          });
          return newCategory;
        });
        setBilan(newBilan);
        setProject(res.data.project);
      } else {
        toast.error("Une erreur est survenue lors du chargement du bilan");
        navigate("/");
      }
    });
  }, [id]);

  // Debounce function that update bilam on change after 2s
  useEffect(() => {
    if (!bilan) return;
    const newProgress = { total: 0, filledCount: 0, parametersCount: 0 };
    getProgress(bilan.matrice, newProgress);
    const { number, unit } = getUnit(newProgress.total, ["kgCO2eq", "tCO2eq"]);
    newProgress.total = number;
    newProgress.unit = unit;
    setProgress(newProgress);

    const timeout = setTimeout(() => {
      api.put(`/bilan/${id}`, bilan).catch(() => toast.error("Une erreur est survenue lors de la sauvegarde du projet"));
    }, 2000);
    return () => clearTimeout(timeout);
  }, [bilan, id]);

  useEffect(() => {
    if (!project) return;
    const timeout = setTimeout(() => {
      api.put(`/project/${project._id}`, project).catch(() => toast.error("Une erreur est survenue lors de la sauvegarde du projet"));
    }, 2000);
    return () => clearTimeout(timeout);
  }, [project]);

  const handleValidate = async () => {
    if (progress.filledCount !== progress.parametersCount) return setIsValidationModalOpen(true);
    else navigate(`/bilan/${id}/result`);
  };

  if (!bilan || !project) return <Loader />;

  return (
    <div className="h-full flex flex-col relative">
      <ValidateBilanModal isOpen={isValidationModalOpen} onClose={() => setIsValidationModalOpen(false)} bilan={bilan} />
      <HelpModal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)} />
      <ChooseImportModal isOpen={isChooseImportOpen} onClose={() => setIsChooseImportOpen(false)} bilan={bilan} project={project} />

      <header className="absolute top-0 w-full bg-white px-12 h-16">
        <div className="grid grid-cols-3 items-center h-full">
          <Link to="/" className="text-blue-dark flex items-center">
            <BsChevronLeft className="mr-2" />
            <span>Retour au tableau de bord</span>
          </Link>
          <h1 className="font-bold text-xl justify-self-center text-center">
            <div className="text-sm text-gray-600 font-normal">
              Bilan <span className="lowercase">{BILAN_TYPES[bilan.type]}</span>
            </div>
            {project.title}
          </h1>
          <div className="flex items-center gap-6 justify-end">
            <div className="flex items-center text-[#50b2c0]">
              <BsCheckCircle className="mr-2" />
              <p className="text-[#50b2c0]">Sauvegarde automatique</p>
            </div>
            <button className="flex items-center text-blue-dark" onClick={() => setIsHelpModalOpen(true)}>
              <BsFillQuestionCircleFill className="mr-2" />
              <p>Aide</p>
            </button>
          </div>
        </div>
      </header>

      <div className="h-full grid grid-cols-4 pt-16">
        <div className="h-full overflow-y-scroll py-8 pl-8 pr-4">
          <nav className="flex flex-col gap-2">
            {user.hasPaid &&
              (project.files && project.files.length > 0 && !project.fileImportedAt ? (
                <div className="flex flex-col bg-[#6A96A5] p-5">
                  <h2 className="text-lh text-white mb-3">Avantages bilan premium</h2>
                  <div className="bg-blue-mid rounded p-4">
                    <h4 className="font-bold text-white text-sm"> En cours d'import</h4>
                    <p className="text-white text-xs">
                      Vos données sont en train d’être importées. Cela peut prendre jusqu’à 24 heures. Vous recevrez un email de notification lorsque cela sera terminé.
                    </p>
                  </div>
                  <a href="https://calendly.com/charles-gachet-dieuzeide/onboarding-seco2" target="_blank" rel="noreferrer" className="white-button mt-4 w-full">
                    Prendre rendez-vous
                  </a>
                </div>
              ) : (
                <div className="flex flex-col bg-[#6A96A5] rounded p-5">
                  <div className="text-l text-white mb-3">Avantages bilan premium</div>
                  {bilan.matriceName.includes("cnc") && (
                    <button className="blue-button flex items-center justify-center" onClick={() => setIsChooseImportOpen(true)}>
                      <BsDownload className="text-white mr-2" />
                      Importer mon budget
                    </button>
                  )}
                  <a href="https://calendly.com/charles-gachet-dieuzeide/onboarding-seco2" target="_blank" rel="noreferrer">
                    <button className="white-button w-full mt-2">Prendre rendez-vous</button>
                  </a>
                </div>
              ))}

            {progress ? (
              <>
                <div className="bg-white flex flex-col items-center justify-center rounded p-3">
                  <span className="font-semibold">{((progress.filledCount * 100) / progress.parametersCount).toFixed(0)}% complétés</span>
                  <div className="w-full flex h-2 overflow-hidden rounded-sm bg-blue-dark-150 text-xs">
                    <div style={{ width: `${((progress.filledCount * 100) / progress.parametersCount).toFixed(0)}%` }} className="h-full bg-blue-dark" />
                  </div>
                </div>
                <div className="bg-white rounded flex justify-center items-center p-3">
                  <p>
                    Total <span className="ml-3 font-bold">{progress.total.toLocaleString("fr", { maximumFractionDigits: 0 })}</span> {progress.unit}
                  </p>
                </div>
                <button className="blue-button flex justify-center items-center" onClick={handleValidate}>
                  <BsCheckCircle className="mr-2" />
                  <p className="w-[70%] text-white">Valider et accéder à mes résultats</p>
                </button>
              </>
            ) : (
              <Loader />
            )}
            <input type="text" className="input h-12" placeholder="Rechercher par mot clé..." value={search} onChange={(e) => setSearch(e.target.value)} />
            <Nav bilan={bilan} />
          </nav>
        </div>
        <main className="h-full flex flex-col gap-8 py-8 pr-8 pl-4 overflow-y-scroll col-span-3">
          {!search && (
            <section>
              <Project bilan={bilan} project={project} setProject={setProject} />
            </section>
          )}
          <section className="flex-1">
            <Form bilan={bilan} setBilan={setBilan} search={search} />
          </section>
        </main>
      </div>
    </div>
  );
};

const Nav = ({ bilan }) => {
  const [openedCategory, setOpenedCategory] = useState();

  const getCode = (element) => {
    const code = element?.code;
    if (!code) return "";
    return element.code?.replace(/(.{1})/g, "$1.");
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="bg-white rounded overflow-hidden">
        <p className="items-center h-8 text-base leading-8 pl-4 w-full font-bold bg-back-light-blue text-blue-dark">Accès rapide</p>
        <div className="flex flex-col px-3">
          <a href="#informations" className="py-3 flex items-center hover:font-bold">
            <div className="w-[10%] mr-2">
              <p className="w-6 text-center bg-blue-gray rounded"></p>
            </div>
            <h2>Informations du projet</h2>
          </a>
          {bilan.matrice.map((category, i) => {
            const isOpened = openedCategory === i && category.categories.length !== 0;
            return (
              <div key={i}>
                <a
                  key={i}
                  className={`${isOpened ? "pt-3" : "py-3"} flex text-left items-center hover:font-bold border-t border-blue-border w-full`}
                  href={`#${getCode(category)}${slugify(category.name.toLowerCase())}`}
                  onClick={() => setOpenedCategory((prev) => (prev === i ? null : i))}>
                  <div className="w-[10%] mr-2">
                    <p style={{ background: category.color }} className="w-6 text-center rounded">
                      {getCode(category)}
                    </p>
                  </div>
                  <p className="flex-1 truncate text-left">{category.name}</p>
                </a>
                {isOpened && (
                  <div className="flex flex-col pl-4 mt-2 mb-3">
                    {category.categories.map((subCategory, j) => {
                      return (
                        <a key={j} href={`#${getCode(subCategory)}${slugify(subCategory.name.toLowerCase())}`} className="py-2 flex items-center hover:font-bold">
                          <div className="w-[10%] mr-6">
                            <p className="text-center text-xs">{getCode(subCategory)}</p>
                          </div>
                          <p className="truncate text-xs">{subCategory.name}</p>
                        </a>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Project = ({ bilan, project, setProject }) => {
  const handleChange = (e) => {
    setProject({ ...project, [e.target.name]: e.target.value });
  };

  return (
    <div className="w-full bg-white rounded">
      <div className="flex flex-col p-4" id="informations">
        <div className="p-3 bg-blue-gray mb-4 rounded">
          <h2 className="text-xl font-bold ">Informations du projet</h2>
        </div>

        <div className="flex flex-col mb-4">
          <div className="flex items-start justify-between mb-4 text-base">
            <label className="ml-10 mb-2">Titre du film</label>
            <input name="title" className="input w-56 text-right bg-blue-light" value={project.title} onChange={handleChange} />
          </div>
          <div className="flex items-start justify-between mb-4 text-base">
            <label className="ml-10 mb-2">Type de bilan</label>
            <input disabled className="input w-56 text-right bg-blue-light" value={BILAN_TYPES[bilan.type]} />
          </div>
          <div className="flex items-start justify-between mb-4 text-base">
            <label className="ml-10 mb-2">Type d'oeuvre</label>
            <input disabled className="input w-56 text-right bg-blue-light" value={project.type} />
          </div>
          <div className="flex items-start justify-between mb-4 text-base">
            <label className="ml-10 mb-2">Format</label>
            <input disabled className="input w-56 text-right bg-blue-light" value={project.format} />
          </div>
          <div className="flex items-start justify-between mb-4 text-base">
            <label className="ml-10 mb-2">Genre</label>
            <input disabled className="input w-56 text-right bg-blue-light" value={project.genre} />
          </div>
          <div className="flex items-start justify-between mb-4 text-base">
            <label className="ml-10 mb-2">Nom de la production</label>
            <input name="productionName" className="input w-56 text-right bg-blue-light" value={project.productionName} onChange={handleChange} />
          </div>
          <div className="flex items-start justify-between mb-4 text-base">
            <label className="ml-10 mb-2">Lieu(x) de tournage</label>
            <input name="shootingLocation" className="input w-56 text-right bg-blue-light" value={project.shootingLocation} onChange={handleChange} />
          </div>
          <div className="flex items-start justify-between mb-4 text-base">
            <label className="ml-10 mb-2">Début du tournage</label>
            <DateField value={project.shootingStart} onChange={(v) => handleChange({ target: { name: "shootingStart", value: v } })} position="right-0" />
          </div>
          <div className="flex items-start justify-between mb-4 text-base">
            <label className="ml-10 mb-2">Nombre de jours de tournage</label>
            <input type="number" name="shootingDays" className="input w-56 text-right bg-blue-light" value={project.shootingDays} onChange={handleChange} />
          </div>
          <div className="flex items-start justify-between mb-4 text-base">
            <label className="ml-10 mb-2">Budget</label>
            <input type="number" name="budget" className="input w-56 text-right bg-blue-light" value={project.budget} onChange={handleChange} />
          </div>
          <div className="flex items-start justify-between mb-4 text-base">
            <label className="ml-10 mb-2">Responsable estimation carbone</label>
            <input name="referent" className="input w-56 text-right bg-blue-light" value={project.referent} onChange={handleChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

const getMatchingParameters = (matrice, search) => {
  const build = { ...matrice };
  build.parameters = [];
  build.categories = [];

  if (matrice.parameters && matrice.parameters.length > 0) {
    matrice.parameters.forEach((param) => {
      if (param.name.toLowerCase().includes(search.toLowerCase())) build.parameters.push(param);
    });
  }
  if (matrice.categories && matrice.categories.length > 0) {
    matrice.categories.forEach((subCat) => {
      if (subCat.name.toLowerCase().includes(search.toLowerCase())) build.categories.push(subCat);
      else {
        const subBuild = getMatchingParameters(subCat, search);
        if (subBuild) build.categories.push(subBuild);
      }
    });
  }
  if (build.parameters.length === 0 && build.categories.length === 0) return null;
  else return build;
};

const Form = ({ bilan, setBilan, search }) => {
  const [displayedMatrice, setDisplayedMatrice] = useState(bilan.matrice);

  const getCode = (element) => {
    const code = element?.code;
    if (!code) return "";
    return element.code?.replace(/(.{1})/g, "$1.");
  };
  const displayCode = ({ element, category }) => {
    const code = element?.code;
    if (!code) return null;
    return (
      <span style={{ background: category?.color }} className="rounded-md px-2 text-xs">
        {getCode(element)}
      </span>
    );
  };

  useEffect(() => {
    if (!search) return setDisplayedMatrice(bilan.matrice);
    const newMatrice = [];
    bilan.matrice.forEach((category) => {
      const newCategory = getMatchingParameters(category, search);
      if (newCategory) newMatrice.push(newCategory);
    });
    setDisplayedMatrice(newMatrice);
  }, [search, bilan]);

  const handleChange = (value, id, i, j, k, l) => {
    let options;
    console.log("debug", i, j, k, l);
    if (l !== undefined) options = bilan.matrice[i].categories[j].categories[k].parameters[l].options;
    else if (k !== undefined) options = bilan.matrice[i].categories[j].parameters[k].options;
    else if (j !== undefined) options = bilan.matrice[i].parameters[j].options;
    options.forEach((o) => (o._id === id ? (o.value = value) : null));
    setBilan({ ...bilan });
  };

  return (
    <div className="w-full bg-white rounded">
      {displayedMatrice.map((category, i) => {
        return (
          <div key={i} className="flex flex-col p-4" id={`${getCode(category)}${slugify(category.name.toLowerCase())}`}>
            <div style={{ background: category?.color }} className="p-3 rounded mb-4">
              <h2 className="text-xl font-bold ">
                <span style={{ background: category?.color }} className="rounded-md px-2 text-lg">
                  {getCode(category)}
                </span>
                &nbsp;{capitalizeFirstLetter(category.name)}
              </h2>
            </div>
            {category.categories.map((sc, j) => {
              return (
                <div key={j} className="flex flex-col mb-4" id={`${getCode(sc)}${slugify(sc.name.toLowerCase())}`}>
                  <div className="p-2 mb-3 border-b border-blue-border">
                    <h3 className="text-xl font-bold ">
                      {displayCode({ element: sc, category })}&nbsp;{capitalizeFirstLetter(sc.name)}
                    </h3>
                  </div>
                  {sc.categories.map((ssc, k) => {
                    return (
                      <div key={k} className="flex flex-col mb-4" id={`${getCode(ssc)}${slugify(ssc.name.toLowerCase())}`}>
                        <h4 className="text-sm mb-2 font-bold">
                          {displayCode({ element: ssc, category })}&nbsp;{capitalizeFirstLetter(ssc.name)}
                        </h4>
                        {ssc.parameters.map((p, l) => (
                          <Parameter key={l} parameter={p} onChange={(value, index) => handleChange(value, index, i, j, k, l)} category={category} />
                        ))}
                      </div>
                    );
                  })}
                  {sc.parameters.map((p, k) => (
                    <Parameter key={k} parameter={p} onChange={(value, index) => handleChange(value, index, i, j, k)} category={category} />
                  ))}
                </div>
              );
            })}
            {category.parameters.map((p, j) => (
              <Parameter key={j} parameter={p} onChange={(value, index) => handleChange(value, index, i, j)} category={category} />
            ))}
          </div>
        );
      })}
    </div>
  );
};

const DateField = ({ value, onChange, position = "left-0" }) => {
  const [open, setOpen] = useState(false);

  const handleChange = (v) => {
    setOpen(false);
    onChange(v);
  };
  return (
    <div className="relative">
      <button className="w-56 bg-blue-light h-9 rounded-lg text-right px-3" type="button" onClick={() => setOpen(!open)}>
        {value ? <span>{new Date(value).toLocaleString("fr").split(" ")[0]}</span> : <span className="opacity-50">dd/mm/yyyy</span>}
      </button>
      {open && (
        <div className={`absolute top-10 ${position} z-40 bg-white border border-blue-dark rounded`}>
          <DayPicker className="w-full" selectedDays={value} locale={fr} onDayClick={handleChange} />
        </div>
      )}
    </div>
  );
};

const Parameter = ({ category, parameter, onChange }) => {
  const multiple = parameter.options.length > 1;
  const [elements, setElements] = useState(multiple ? parameter.options.filter((o) => o.value).map((o) => o._id) : []);

  const handleAddElement = (e) => {
    const exits = elements.find((el) => el === e._id);
    if (exits) return;
    setElements((prev) => [...prev, e._id]);
  };

  const handleDeleteElement = (e) => {
    setElements((prev) => prev.filter((el) => el !== e));
    onChange(undefined, e);
  };

  const handleChange = (e, id) => {
    if (e.target.value === "") return onChange(undefined, id);
    const value = parseFloat(e.target.value);
    if (isNaN(value)) return;
    onChange(value, id);
  };

  const displayCode = ({ element, category }) => {
    const code = element?.code;
    if (!code) return null;
    return (
      <span style={{ background: category?.color }} className="rounded-md px-2 text-xs">
        {element.code?.replace(/(.{1})/g, "$1.")}
      </span>
    );
  };

  if (!parameter.options[0]) console.log(parameter);

  return (
    <div className="flex items-start justify-between mb-4 text-base">
      <label className="ml-10 mb-2">
        {displayCode({ element: parameter, category })}&nbsp;{capitalizeFirstLetter(parameter.name)}
      </label>
      {!multiple ? (
        <div className="flex items-center">
          <input
            type="number"
            className="input w-56 text-right bg-blue-light"
            value={parameter.options[0].value !== undefined ? parameter.options[0].value : ""}
            onChange={(e) => handleChange(e, parameter.options[0]._id)}
          />
          <div className="w-10 ml-2">{parameter.options[0].unit}</div>
        </div>
      ) : (
        <div className="flex flex-col items-end gap-2">
          {elements.map((element, i) => {
            const index = parameter.options.findIndex((o) => o._id === element);
            if (index === -1) return <></>;
            const value = parameter.options[index].value;
            return (
              <div key={i} className="flex items-center mr-4">
                <label className="mr-4">{parameter.options[index].description}</label>
                <input type="number" className="input w-56 text-right bg-blue-light" value={value !== undefined ? value : ""} onChange={(e) => handleChange(e, element)} />
                <div className="w-10 ml-2">{parameter.options[index].unit}</div>
                <button className="ml-4 text-red-500" onClick={() => handleDeleteElement(element)}>
                  <BsTrash3 />
                </button>
              </div>
            );
          })}
          <div className="w-60">
            <Select
              options={parameter.options.filter((o) => !elements.includes(o._id))}
              onChange={handleAddElement}
              labels={(o) => o.description}
              position="right-0"
              placeholder="Ajouter un élément"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Edit;
