import React from "react";
import MyIcon from "./MyIcon";

const IconButton = ({ icon, text, color, onClick }) => {
  const finalColor = color ? color : "var(--textDarkBlue)";

  return (
    <div className="flex items-center hover:cursor-pointer gap-1" onClick={onClick}>
      <MyIcon color={finalColor} icon={icon} />
      <p className={`${color ? color : "text-blue-deep-dark font-semibold"}`}>{text}</p>
    </div>
  );
};

export default IconButton;
