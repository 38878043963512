import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import validator from "validator";
import { Formik, Form } from "formik";

import TextField from "components/Base/TextField";

import useStore from "Stores/zustand";

//Modals
import ModalPasswordModification from "./components/ModalPasswordModification";
import ModalDeleteAccount from "./components/ModalDeleteAccount";
import ModalBuyingBilanFormule from "./components/ModalBuyingBilanFormule";
import { referencingInfos } from "../../utils/config";
import { STRIPE_LINK_SUBSCRIPTION_FORMULA } from "../../config";

import Formulas from "./Formulas";

import api from "../../services/api";

const Account = () => {
  const [isEqual, setIsEqual] = useState({ user: true, company: true });
  const [isChangePasswordOpen, setIsChangePasswordOpen] = React.useState(false);
  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = React.useState(false);

  const { user, setUser } = useStore();

  const initialValues = {
    user: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    },
    company: {
      companyName: user.companyName,
      companyRole: user.companyRole,
      companyAddress: user.companyAddress,
      companyPostalCode: user.companyPostalCode,
      companyCity: user.companyCity,
      companySIRET: user.companySIRET,
    },
  };

  const validate = (values, setErrors) => {
    const errors = {};
    if (!validator.isEmail(values.email)) errors.email = "Adresse email invalide";
    if (validator.isEmpty(values.firstName)) errors.firstName = "Prénom requis";
    if (validator.isEmpty(values.lastName)) errors.lastName = "Nom requis";
    setErrors(errors);
    return !errors.email && !errors.firstName && !errors.lastName;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;

    api
      .patch(`/auth/${user._id}`, values)
      .then((res) => {
        if (res.ok) {
          setUser(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsEqual({ ...isEqual, user: true });
  };

  const handleChange = (data, type, name) => {
    if (initialValues[type][name] !== data) {
      setIsEqual({ ...isEqual, [type]: false });
    } else {
      setIsEqual({ ...isEqual, [type]: true });
    }
  };

  const handleSubmitCompany = async (data) => {
    const res = await api.put(`/auth`, data);
    setUser(res.data);
  };

  const isUserChanged = (values) => user.firstName !== values.firstName || user.lastName !== values.lastName;

  return (
    <section id="dashboard_box" className="bg-white p-4 pr-2.5 w-full h-full overflow-y-auto space-y-6">
      <Helmet>
        <title>{referencingInfos.account.title}</title>
        <meta name="description" content={referencingInfos.account.description} />
      </Helmet>

      <ModalPasswordModification user={user} isOpen={isChangePasswordOpen} close={() => setIsChangePasswordOpen(false)} />
      <ModalDeleteAccount user={user} isOpen={isDeleteAccountOpen} close={() => setIsDeleteAccountOpen(false)} />

      <Formulas />
      <PaymentHistory />

      <div>
        <h2 className="text-base font-bold leading-5 mt-5">Mes informations</h2>
        <Formik initialValues={{ firstName: user.firstName, lastName: user.lastName, email: user.email }} onSubmit={handleSubmit}>
          {({ values, errors, handleChange }) => (
            <Form className="w-4/5 ml-[20%] my-4" onChange={(e) => handleChange(e.target.value, "user", e.target.name)}>
              <div className="mb-5 grid grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="mb-1" htmlFor="firstName">
                    Prénom*
                  </label>
                  <input className="input" name="firstName" value={values.firstName} onChange={handleChange} />
                  {errors.firstName && <div className="flex items-center text-sm text-red-500">{errors.firstName}</div>}
                </div>
                <div className="flex flex-col">
                  <label className="mb-1" htmlFor="lastName">
                    Nom*
                  </label>
                  <input className="input" name="lastName" value={values.lastName} onChange={handleChange} />
                  {errors.lastName && <div className="flex items-center text-sm text-red-500">{errors.lastName}</div>}
                </div>
              </div>
              <div className="mb-5 flex flex-col">
                <label className="mb-1" htmlFor="email">
                  E-mail*
                </label>
                <input className="input" name="email" value={values.email} onChange={handleChange} disabled />
              </div>
              <div className="flex justify-end items-center mt-7 gap-2.5">
                <button className="empty-button w-[250px]" type="button" width="250px" onClick={() => setIsChangePasswordOpen(true)}>
                  Changer de mot de passe
                </button>
                <button className="blue-button w-[250px]" type="submit" disabled={!isUserChanged(values)}>
                  Valider mes modifications
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div>
        <h2 className="font-bold leading-5 text-base">Ma société</h2>
        <Formik initialValues={initialValues.company} validateOnBlur={true} onSubmit={handleSubmitCompany}>
          <Form className="w-4/5 ml-[20%] my-4" onChange={(e) => handleChange(e.target.value, "company", e.target.name)}>
            <div className="mb-5 flex items-center gap-4">
              <TextField autoComplete="off" label="Mon entreprise" name="companyName" type="text" />
              <TextField autoComplete="off" label="Mon rôle" name="companyRole" type="text" />
            </div>
            <div className="mb-5 flex items-center gap-4">
              <TextField autoComplete="off" label="Adresse" name="companyAddress" type="text" />
            </div>
            <div className="mb-5 flex items-center gap-4">
              <TextField autoComplete="off" label="Code Postal" name="companyPostalCode" type="text" />
              <TextField autoComplete="off" label="Ville" name="companyCity" type="text" />
            </div>
            <div className="mb-5 flex items-center gap-4">
              <TextField autoComplete="off" label="Num TVA / SIRET" name="companySIRET" type="text" />
            </div>
            <div className="flex justify-end items-center mt-7 gap-2.5">
              <button className="w-[250px] empty-button" type="submit" disabled={isEqual.company}>
                Valider mes modifications
              </button>
            </div>
          </Form>
        </Formik>
      </div>
      <p className="my-14 mx-auto text-xs leading-4 underline w-max text-center cursor-pointer" onClick={() => setIsDeleteAccountOpen(true)}>
        Supprimer mon compte
      </p>
    </section>
  );
};

const PaymentHistory = () => {
  const [payments, setPayments] = useState();

  useEffect(() => {
    (async () => {
      try {
        const res = await api.get(`/payment`);
        if (res.ok) setPayments(res.data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <div>
      <h2 className="font-bold leading-5 text-base mb-3">Historique de facturation</h2>
      {payments && !payments.length && <div className="text-sm text-center font-medium py-2">Rien à afficher</div>}

      {payments && !!payments.length && (
        <div id="user_table_wrapper" className="w-full h-full text-xs">
          <table id="user_list" className="w-full">
            <thead className="text-navy-700">
              <tr className="text-left bg-blue-light font-medium border-b border-gray-200">
                <th className="p-3">Nom</th>
                <th className="p-3">Montant</th>
                <th className="p-3">Date</th>
                <th className="p-3">Statut</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {payments.map((payment) => (
                <tr key={payment._id}>
                  <td className="py-4 p-3">{payment.title}</td>
                  <td className="py-4 p-3">{(payment.amount / 100).toFixed(2)}€</td>
                  <td className="py-4 p-3">{new Date(payment.date).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</td>
                  <td className="py-4 p-3 uppercase">{payment.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Account;
