import React, { useState, useEffect } from "react";
import api from "services/api";

//Components
import EstimationsTable from "components/admin/EstimationsTable";
import Statistics from "components/Statistics";
import CategoryResults from "components/results/CategoryResults";
import { MainInfo } from "components/results/MainInfosBox";
import { TreemapSection, DistributionChartSection } from "components/results/ResultsPartials";
import MyIcon from "components/partials/MyIcon";

//Utils
import { getEstimationsInfosForAdmin } from "utils/seco2Utils";

const AdminStats = ({ estimations, estimationsInfos, type, title, isPrinting, printingFormat }) => {
  const results = type ? estimationsInfos.averageEstimation.results : null;
  const nbEstimations = estimations.length;
  const chapterTitlePrecision = estimations.length > 1 ? `(moyenne des ${nbEstimations} projets)` : `(statistiques du projet)`;

  let color = null;
  let logo = null;

  // if (type) {
  //   logo = badgeInfosCorrespondance[type].icon;
  //   title = badgeInfosCorrespondance[type].name;
  //   color = badgeInfosCorrespondance[type].color;
  // }

  const budgetAvg =
    estimations.reduce((acc, estimation) => {
      const budget = estimation.information.budget;
      if (!budget || budget === "" || budget === "-") return acc;
      return acc + parseInt(estimation.information.budget.replaceAll(" ", ""));
    }, 0) / estimations.length;

  return (
    <div className="bg-white p-8 mb-4">
      <h2 className="font-bold text-xl leading-6">{type}</h2>
      <Statistics estimations={estimations} color={color} />
      <div className="mt-0 pt-0">
        <MainInfo parameter="Budget moyen" value={budgetAvg.toLocaleString("fr", { style: "currency", currency: "eur" })} />
        {/* <MainInfo parameter="Durée moyenne" value={`${formatNumber(meanDuration)} jours`} /> */}
      </div>
      <DistributionChartSection estimationsInfos={estimationsInfos} color={color} />
      <EstimationsTable estimations={estimations} color={color} isPrinting={isPrinting} printingFormat={printingFormat} />

      {type && (
        <>
          <CategoryResults className="padding_for_print" title={"Chiffres clés " + chapterTitlePrecision} data={estimationsInfos.averageEstimation.form} />
          <TreemapSection className="padding_for_print" title={"Répartition des émissions " + chapterTitlePrecision} data={estimationsInfos.averageEstimation.form} />
        </>
      )}
    </div>
  );
};

const Stats = () => {
  const [estimations, setEstimations] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [printingFormat, setPrintingFormat] = useState("landscape");

  useEffect(() => {
    api
      .get("/estimations/all/est")
      .then((res) => {
        if (res.ok) setEstimations(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // if (users && users.length === 0) {
  //   return <p>Aucun utilisateur n'est associé à votre compte. Créez votre premier utilisateur !</p>;
  // }
  if (estimations && estimations.length === 0) {
    return <p>Aucun de vos utilisateurs n'a validé d'estimation. Encouragez les à débuter !</p>;
  }

  // if (!users || !estimations) {
  if (!estimations) return <p>Loading</p>;

  const { estimationsInfos, estimationsPerType, estimationsInfosPerType } = getEstimationsInfosForAdmin(estimations);
  const synthesisTitle = "Statistiques de l'ensemble des estimations carbones";

  return (
    <main id="admin_stats" className="w-full overflow-y-auto">
      <AdminStats title="Statistiques générales" estimations={estimations} estimationsInfos={estimationsInfos} isPrinting={isPrinting} printingFormat={printingFormat} />
      {estimationsPerType.map((estimations, i) => (
        <AdminStats
          estimations={estimations}
          estimationsInfos={estimationsInfosPerType[i]}
          type={estimations[0].information.carbonFootprintType}
          isPrinting={isPrinting}
          printingFormat={printingFormat}
          i={i}
          key={`adminStats-${i}`}
        />
      ))}
    </main>
  );
};

export default Stats;
