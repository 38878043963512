import React from "react";
import { BarChart, Label, Bar, XAxis, YAxis, ReferenceLine, Tooltip, ResponsiveContainer } from "recharts";
import { SecoyaTooltip } from "components/results/ResultsPartials";

import { average, median, formatNumber } from "../../utils";

// const findI = (value, array) => {
//   const diffs = array.map((a) => Math.abs(a - value));
//   return array[diffs.indexOf(Math.min(...diffs))];
// };

const getHistogramDatas = (data, options) => {
  const { nbBars } = options;

  const finalData = data.map((v) => Math.round(v / 1000));

  const dataMin = Math.round(Math.min(...finalData));
  const dataMax = Math.round(Math.max(...finalData));
  let step = (dataMax - dataMin) / (nbBars - 1);

  let rangeMin = null;
  let rangeMax = null;
  if (dataMin - step / 2 < 0) {
    rangeMin = 0;
    rangeMax = dataMax + dataMin - rangeMin;
    step = rangeMax / 10;
  } else {
    rangeMin = dataMin - step / 2;
    rangeMax = dataMax + step / 2;
  }

  const med = median(finalData);
  const avg = average(finalData);
  // const stdev = standardDeviation(finalData);

  let xBarLimits = [];
  let nbSteps = 0;

  if (data.length === 1) {
    xBarLimits.push(finalData[0]);
  } else {
    xBarLimits.push(rangeMin);
    while (nbSteps < nbBars) {
      nbSteps++;
      xBarLimits.push(xBarLimits[0] + nbSteps * step);
    }
  }

  // const sd1 = findI(med - stdev, xBarLimits);
  // const sd2 = findI(med + stdev, xBarLimits);
  // const medClosest = findI(med, xBarLimits);

  let barDatas = [];

  if (data.length === 1) {
    barDatas = [
      {
        value: data[0],
        nb: 1,
      },
    ];
  } else {
    // 1. Build an array with all values possible
    const values = [rangeMin + step / 2];
    let i = 0;

    while (values[i] < rangeMax - step / 2) {
      i++;
      values.push(values[0] + i * step);
    }

    // 2. Build the expected array
    values.forEach((value) => {
      const nbValues = finalData.filter((v) => v >= value - step / 2 && v <= value + step / 2).length;
      barDatas.push({
        value: value,
        nb: nbValues,
      });
    });
  }

  return { xBarLimits, barDatas, step, med, avg };
};

const ReferenceLabel = (props) => {
  const { fill, value, textAnchor, fontSize, viewBox, dy, dx } = props;
  const x = viewBox.width + viewBox.x + 5;
  const y = viewBox.y - 6;
  return (
    <text x={x} y={y} dy={dy} dx={dx} fill={fill} fontSize={fontSize || 10} textAnchor={textAnchor}>
      {value}
    </text>
  );
};

const DistributionHistogram = ({ data, unit, color, title }) => {
  const options = {
    nbBars: 10,
  };

  const { xBarLimits, barDatas, step, avg } = getHistogramDatas(data, options);

  const finalColor = color ? color : "var(--backLightBlue)";

  const myTooltip = (props) => {
    if (props && props.payload.length) {
      const payload = props.payload;
      const emissions = payload[0].payload.value;
      const value = payload[0].value;
      const description = `de ${formatNumber(Math.round(emissions - step / 2))} ${unit} à ${formatNumber(Math.round(emissions + step / 2))} ${unit}`;
      const projetString = value > 1 ? "projets" : "projet";
      return <SecoyaTooltip color={payload[0].payload.color} name={description} value={value} unit={projetString} />;
    }
    return "";
  };

  return (
    <ResponsiveContainer height="100%" width="99%">
      <BarChart data={barDatas} barCategoryGap={0}>
        {/* <defs>
          <linearGradient id={uniqueKey} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={finalColor} stopOpacity={0.5} />
            <stop offset="95%" stopColor={finalColor} stopOpacity={0.5} />
          </linearGradient>
        </defs> */}

        {/* <XAxis dataKey="value" stroke="var(--darkgrey)" height={40} fontSize={12} allowDecimals={false} tickFormatter={(tick) => Math.round(tick)} scale="band"> */}
        <XAxis
          dataKey="value"
          stroke="var(--textJaugeBlue)"
          height={50}
          fontSize={12}
          angle={-45}
          // type={data.length === 1 ? false : "number" }
          type={data.length === 1 ? "category" : "number"}
          ticks={xBarLimits}
          domain={[xBarLimits[0], xBarLimits[xBarLimits.length - 1]]}
          tickFormatter={(tick) => Math.round(tick)}
          dy={10}>
          <Label value={title} offset={0} position="insideBottom" style={{ textAnchor: "middle", fill: "var(--textDarkBlue)", fontSize: "12px" }} />
        </XAxis>

        <YAxis stroke="var(--textJaugeBlue)" fontSize={12} allowDecimals={false} type="number" domain={[0, "dataMax"]} padding={{ right: 10 }}>
          <Label value="Nb Résultats" offset={0} position="center" style={{ textAnchor: "middle", fill: "var(--textDarkBlue)", fontSize: "12px" }} angle={-90} />
        </YAxis>

        <Tooltip content={(props) => myTooltip(props)} />

        <Bar type="basis" fillOpacity="1" dataKey="nb" stroke="none" fill={finalColor} />
        {/* <ReferenceLine x={sd1} stroke="var(--turquoise)" strokeWidth={2} strokeDasharray="5 5" />
        <ReferenceLine x={sd2} stroke="var(--turquoise)" strokeWidth={2} strokeDasharray="5 5" /> */}
        <ReferenceLine
          x={avg}
          stroke="var(--textDarkBlue)"
          strokeWidth={2}
          strokeDasharray="5 3"
          label={<ReferenceLabel value={"Moyenne"} fill={"var(--textDarkBlue)"} dy={20} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DistributionHistogram;
