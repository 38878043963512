import React from "react";
import Parameter from "./Parameter";

import ProjectInformation from "./ProjectInformation";

export const getIndex = (indexType, categoryCode, defaultIndex) => {
  switch (indexType) {
    case "defaultIndex":
      return defaultIndex;
    case "categoryCode":
      return categoryCode;
  }
  return "";
};

const EstimationForm = ({ estimation, updateEstimation, saveEstimation, searchedInfo, indexType }) => {
  return (
    <>
      {!searchedInfo && <ProjectInformation estimation={estimation} saveEstimation={saveEstimation} />}
      {estimation?.form?.map((category, categoryIndex) => (
        <div key={categoryIndex} className="firstCategory w-full" id={`firstCategory_${categoryIndex + 1}`}>
          <CategoryTitle title={category.name} depth={1} index={getIndex(indexType, category.code, categoryIndex + 1)} id={convertToSlug(category.name)} color={category.color} />
          {category.parameters &&
            category.parameters.length > 0 &&
            category.parameters
              .filter((p) => p.name.toLowerCase().includes(searchedInfo || ""))
              .map((parameter, id) => <Parameter parameter={parameter} key={id} updateEstimation={updateEstimation} id={id} />)}
          {category.subCategories &&
            category.subCategories.length > 0 &&
            category.subCategories.map((subCategory, subCategoryIndex) => (
              <div key={subCategoryIndex}>
                <CategoryTitle
                  title={subCategory.name}
                  depth={2}
                  index={getIndex(indexType, subCategory.code, `${categoryIndex + 1}.${subCategoryIndex + 1}`)}
                  id={convertToSlug(category.name + subCategory.name)}
                  color={category.color}
                />
                {subCategory.parameters &&
                  subCategory.parameters.length > 0 &&
                  subCategory.parameters
                    .filter((p) => p.name.toLowerCase().includes(searchedInfo || ""))
                    .map((parameter, id) => <Parameter parameter={parameter} key={id} updateEstimation={updateEstimation} id={id} />)}
                {subCategory.subCategories &&
                  subCategory.subCategories.length > 0 &&
                  subCategory.subCategories.map((subSubCategory, subSubCategoryIndex) => (
                    <div key={subSubCategoryIndex}>
                      <CategoryTitle
                        title={subSubCategory.name}
                        depth={3}
                        index={getIndex(indexType, subSubCategory.code, `${categoryIndex + 1}.${subCategoryIndex + 1}.${subSubCategoryIndex + 1}`)}
                        id={convertToSlug(category.name + subCategory.name + subSubCategory.name)}
                        color={category.color}
                      />
                      {subSubCategory.parameters &&
                        subSubCategory.parameters.length > 0 &&
                        subSubCategory.parameters
                          .filter((p) => p.name.toLowerCase().includes(searchedInfo || ""))
                          .map((parameter, id) => <Parameter parameter={parameter} key={id} updateEstimation={updateEstimation} id={id} />)}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      ))}
    </>
  );
};

const CategoryTitle = ({ title, depth, index, id, color }) => {
  const titleBgColor = depth === 1 ? color : "transparent";
  const indexBgColor = depth === 2 ? color : "transparent";
  return (
    <div className={`grid grid-cols-[10%_60%_30%] items-center form_row form_row_${depth}`} id={id} style={{ backgroundColor: titleBgColor }}>
      <p style={{ backgroundColor: indexBgColor }}>{index}</p>
      <p className="pr-1.5">{title}</p>
    </div>
  );
};

export default EstimationForm;
