import React, { useState } from "react";
import validator from "validator";
import { Formik, Form } from "formik";

import InfoBox from "components/Base/InfoBox";

//Form
import TextField from "components/Base/TextField";
import MyIcon from "components/partials/MyIcon";
import { Modal } from "components/modal";
import api from "services/api";
import useStore from "Stores/zustand";

const ModalPasswordModification = ({ user, isOpen, close }) => {
  const [feedback, setFeedback] = useState(null);
  const { setUser } = useStore();

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.oldPassword)) errors.oldPassword = "Ce champ est requis";
    if (validator.isEmpty(values.newPassword)) errors.newPassword = "Ce champ est requis";
    else if (!validator.equals(values.newPassword, values.confirmPassword)) errors.confirmPassword = "Les mots de passe ne correspondent pas";
    setErrors(errors);
    return !errors.oldPassword && !errors.newPassword && !errors.confirmPassword;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    api
      .put(`/auth/change-password`, values)
      .then((res) => {
        if (res.ok) {
          setUser(res.data);
          close();
        } else {
          if (res.message === "INVALID_CREDS") setFeedback({ message: "Mot de passe invalide", type: "error" });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <h2 className="mb-6 font-bold text-2xl leading-8">Changer de mot de passe</h2>
      <p className="font-normal text-base leading-5 mb-4">
        Afin de <strong>changer votre mot de passe</strong>, entrez votre mot de passe actuel puis votre nouveau mot de passe, 2 fois.
      </p>
      {/* {validatedMessage && <p className="validated_message">{validatedMessage}</p>} */}
      {feedback && <InfoBox error={feedback}>{feedback.message}</InfoBox>}
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        onSubmit={handleSubmit}>
        <Form className="my-4">
          <div className="flex items-center mb-5">
            <TextField autoComplete="off" label="Ancien mot de passe" required name="oldPassword" type="password" />
          </div>
          <div className="flex items-center mb-5">
            <TextField autoComplete="off" label="Nouveau mot de passe" required name="newPassword" type="password" />
          </div>
          <div className="flex items-center mb-5">
            <TextField autoComplete="off" required label="Nouveau mot de passe (confirmation)" name="confirmPassword" type="password" />
          </div>
          <div className="mt-10 gap-x-4 ml-auto flex w-2/3">
            <button className="empty-button w-full flex items-center justify-center gap-2 pl-2" type="button" onClick={close}>
              <MyIcon icon="back" />
              Retour
            </button>
            <button className="blue-button w-full" type="submit">
              Valider
            </button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ModalPasswordModification;
