import React, { useState } from "react";
import { BsFillFileEarmarkFill, BsChevronLeft, BsCheckCircle } from "react-icons/bs";

import api from "../../../services/api";
import { Modal } from "../../../components/modal";
import { Loader } from "../../../components/loader";

export const IMPORTS = {
  "cnc-forecast": ["magic-movie", "cnc-devis"],
  "cnc-final": ["magic-movie", "cnc-devis"],
  cnc: ["magic-movie"],
  alpha: [],
  chedar: [],
};

const IMPORTS_LABEL = {
  "magic-movie": "Budget Movie Magic Budgeting XML",
  "cnc-devis": "CNC Devis (csv/excel)",
};

export const ChooseImportModal = ({ isOpen, onClose, project, bilan }) => {
  const [step, setStep] = useState("choose");

  const handleSubmit = (selected) => {
    if (selected === "magic-movie") {
      setStep("magic-movie");
    } else if (selected === "cnc-devis") {
      setStep("cnc");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {{
        choose: <ChooseImport bilan={bilan} handleSubmit={handleSubmit} />,
        "magic-movie": <MagicMovieImport onClose={onClose} project={project} setStep={setStep} />,
        cnc: <CNCImport onClose={onClose} project={project} setStep={setStep} />,
      }[step] || <ChooseImport bilan={bilan} handleSubmit={handleSubmit} />}
    </Modal>
  );
};

const ChooseImport = ({ bilan, handleSubmit }) => {
  const [selected, setSelected] = useState(IMPORTS[bilan.matriceName][0] || "magic-movie");
  return (
    <div>
      <h2 className="mb-6 font-bold text-2xl leading-8">Imports de fichiers externes</h2>
      <p className="font-normal text-base leading-5 mb-4">Quel type de fichier souhaitez-vous importer ?</p>

      <form onSubmit={() => handleSubmit(selected)}>
        {IMPORTS[bilan.matriceName].map((i) => (
          <div key={i} className="space-x-3.5">
            <input
              type="radio"
              id={i}
              className="w-4 h-4 border-blue-dark text-blue-dark focus:ring-indigo-600"
              value={i}
              checked={i === selected}
              onChange={(e) => setSelected(e.target.value)}
            />
            <label htmlFor={i}>{IMPORTS_LABEL[i]}</label>
          </div>
        ))}

        <div className="flex items-center gap-x-4 mt-10">
          <button className="blue-button flex items-center justify-center gap-2 pl-2 w-full" type="submit">
            <BsCheckCircle className="mr-3" />
            Valider
          </button>
        </div>
      </form>
    </div>
  );
};

const MagicMovieImport = ({ onClose, project, setStep }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleSubmit = async () => {
    try {
      if (!file || loading) return;
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      api
        .postFormData(`/project/${project._id.toString()}/import-magic-movie`, formData, {})
        .then((res) => {
          setLoading(false);
          if (res.ok) return setDone(true);
          console.error(res);
        })
        .catch((err) => console.error(err));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <h2 className="mb-6 font-bold text-2xl leading-8">Importez vos données Magic Movie</h2>
      {!done ? (
        <div className="mt-6">
          <p className="font-bold text-base leading-5 mb-4">Magic Movie</p>
          <p className="font-normal text-base leading-5 mb-4">Vous pouvez importer un fichier</p>
          <p className="font-normal text-base leading-5 mb-4">L'import est en mise à jour, déposez votre fichier nous le traiterons dans les 48h</p>
          <label htmlFor="fileInput" className="block text-lg font-semibold text-gray-700">
            Télécharger un fichier
          </label>
          <div className="mt-2">
            <label className="flex items-center w-80 px-4 py-2 bg-white text-sm text-gray-700 rounded-md border border-gray-300 shadow-sm cursor-pointer">
              <BsFillFileEarmarkFill className="mr-4" />
              <span className="ml-2">Choisir un fichier</span>
              <input id="fileInput" className="hidden" type="file" onChange={handleFileUpload} />
            </label>
          </div>
          {file && (
            <div className="mt-4">
              <p className="text-sm text-gray-500">Fichier sélectionné : {file.name}</p>
            </div>
          )}
          <div className="flex items-center gap-x-4 mt-10">
            <button className="empty-button flex items-center justify-center flex-1" type="button" onClick={() => setStep(0)}>
              <BsChevronLeft className="mr-3" />
              Retour
            </button>
            <button className="blue-button flex-1 flex justify-center" type="button" onClick={handleSubmit} disabled={loading}>
              {loading ? <Loader /> : "Importer"}
            </button>
          </div>
        </div>
      ) : (
        <>
          <p>Votre document a été transmis, il sera traité sous 48h</p>
          <button className="blue-button mt-6" onClick={onClose}>
            Terminer
          </button>
        </>
      )}
    </div>
  );
};

const CNCImport = ({ onClose, project, setStep }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleSubmit = async () => {
    try {
      if (!file || loading) return;
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      api
        .postFormData(`/project/${project._id.toString()}/import-cnc`, formData, {})
        .then((res) => {
          setLoading(false);
          if (res.ok) return setDone(true);
          console.error(res);
        })
        .catch((err) => console.error(err));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <h2 className="mb-6 font-bold text-2xl leading-8">Importez vos données budgétaires</h2>
      {!done ? (
        <div className="mt-6">
          <p className="font-bold text-base leading-5 mb-4">CNC Devis (csv/excel)</p>
          <p className="font-normal text-base leading-5 mb-4">Vous pouvez importer un fichier au format csv ou excel</p>
          <label htmlFor="fileInput" className="block text-lg font-semibold text-gray-700">
            Télécharger un fichier
          </label>
          <div className="mt-2">
            <label className="flex items-center w-80 px-4 py-2 bg-white text-sm text-gray-700 rounded-md border border-gray-300 shadow-sm cursor-pointer">
              <BsFillFileEarmarkFill className="mr-4" />
              <span className="ml-2">Choisir un fichier (.csv, .xlsx, .xls)</span>
              <input className="hidden" id="fileInput" type="file" accept=".csv, .xlsx, application/vnd.ms-excel" onChange={handleFileUpload} />
            </label>
          </div>
          {file && (
            <div className="mt-4">
              <p className="text-sm text-gray-500">Fichier sélectionné : {file.name}</p>
            </div>
          )}
          <div className="flex items-center gap-x-4 mt-10">
            <button className="empty-button flex items-center justify-center flex-1" type="button" onClick={() => setStep(0)}>
              <BsChevronLeft className="mr-3" />
              Retour
            </button>
            <button className="blue-button flex-1 flex justify-center" type="button" onClick={handleSubmit} disabled={loading}>
              {loading ? <Loader /> : "Importer"}
            </button>
          </div>
        </div>
      ) : (
        <>
          <p>Votre document a été transmis, il sera traité sous 24h</p>
          <button className="blue-button mt-6" onClick={onClose}>
            Terminer
          </button>
        </>
      )}
    </div>
  );
};
