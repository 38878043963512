import React, { useState, useEffect } from "react";
import { getMostVisibleCategory } from "../../../utils";
import { getIndex } from "./EstimationForm.jsx";
import slugify from "slugify";

const getParametersStats = (category) => {
  let total = 0;
  let answered = 0;
  if (category.parameters && category.parameters.length > 0) {
    total = category.parameters.length;

    answered = category.parameters.filter((parameter) => {
      const filtered = parameter.data.filter((d) => {
        return d.value.length > 0;
      });
      return filtered.length > 0;
    }).length;
  }
  return {
    total,
    answered,
  };
};

const getCategoryData = ({ category, i, subCategories }) => {
  const inputStatistics = subCategories.reduce(
    (inputStats, subCategory) => {
      inputStats.total += subCategory.totalInputs;
      inputStats.answered += subCategory.answeredInputs;
      return inputStats;
    },
    { total: 0, answered: 0 },
  );
  const parametersStats = getParametersStats(category);
  return {
    href: slugify(category.name),
    name: category.name,
    code: category.code || undefined,
    color: category.color,
    index: `${i + 1}`,
    hierarchy: 1,
    totalInputs: inputStatistics.total + parametersStats.total,
    answeredInputs: inputStatistics.answered + parametersStats.answered,
  };
};

const getSubCategoryDataInputs = (subCategory) => {
  const subCategoryData = getParametersStats(subCategory);
  if (subCategory.subCategories && subCategory.subCategories.length > 0) {
    subCategory.subCategories.forEach((subSubCategory) => {
      const subSubCategoryData = getParametersStats(subSubCategory);
      subCategoryData.total += subSubCategoryData.total;
      subCategoryData.answered += subSubCategoryData.answered;
    });
  }
  return { totalInputs: subCategoryData.total, answeredInputs: subCategoryData.answered };
};

const getSubCategoryData = ({ category, subCategory, i, j }) => {
  const { totalInputs, answeredInputs } = getSubCategoryDataInputs(subCategory);
  return {
    href: slugify(category.name + subCategory.name),
    name: subCategory.name,
    color: subCategory.color,
    code: subCategory.code || undefined,
    index: `${i + 1}.${j + 1}`,
    hierarchy: 2,
    parentCategoryName: category.name,
    totalInputs,
    answeredInputs,
  };
};

const getSubCategories = ({ subCategories, i, category }) => {
  return subCategories.map((subCategory, j) => {
    const subCategoryData = getSubCategoryData({ category, subCategory, i, j });
    return subCategoryData;
  });
};

const getCategories = (form) => {
  const categories = form.reduce((listOfCategories, category, i) => {
    const subCategories = category.subCategories && category.subCategories.length > 0 ? getSubCategories({ subCategories: category.subCategories, i, category }) : [];
    const categoryData = getCategoryData({ category, i, subCategories });
    return [...listOfCategories, categoryData, ...subCategories];
  }, []);
  return categories;
};

const NavEstimation = ({ estimation, indexType }) => {
  const [categoryExpanded, setCategoryExpanded] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    let scrolledElement = null;
    do {
      scrolledElement = document.getElementById("form");
    } while (!scrolledElement);
    scrolledElement.addEventListener("scroll", handleScroll);
  }, []);
  const handleScroll = (e) => {
    setScrollPosition(e.target.scrollTop);
  };
  const categories = getCategories(estimation.form);
  const firstCategories = categories.filter((category) => category.hierarchy === 1);
  const categoryIDs = firstCategories.map((category) => `firstCategory_${category.index}`);
  categoryIDs.splice(0, 0, "firstCategory_0");
  const startOffSet = 123;
  const endOffSet = 102;
  const mostVisibleCategory = getMostVisibleCategory(categoryIDs, scrollPosition, startOffSet, endOffSet);
  const [mostVisibleCategoryIndex, setMostVisibleCategoryIndex] = useState(mostVisibleCategory.split("_")[1]);
  useEffect(() => {
    setMostVisibleCategoryIndex(mostVisibleCategory.split("_")[1]);
  }, [mostVisibleCategory]);

  const handleClick = (e) => {
    if (!e.currentTarget.className.includes("nav_row_1")) return;
    const categoryName = e.currentTarget.getAttribute("data-category");
    categoryName === categoryExpanded ? setCategoryExpanded(null) : setCategoryExpanded(categoryName);
    setMostVisibleCategoryIndex(e.currentTarget.id);
  };

  const navInformations = {
    name: "Informations films",
    href: "firstCategory_0",
    color: "#CFDCE3",
    hierarchy: 1,
    totalInputs: 11,
    answeredInputs: Object.keys(estimation.information).filter((key) => estimation.information[key].length > 0).length - 1,
    index: "0",
  };

  return (
    <nav id="nav_estimation" className="bg-white h-full rounded-sm w-full">
      <p id="nav_title" className="bg-back-light-blue h-[33px] font-bold leading-[33px] pl-4 w-full rounded-sm">
        Accès rapide
      </p>
      <div className="h-[calc(100%-33px)] overflow-y-auto px-4 w-full">
        <NavRow
          key="#CFDCE3"
          category={navInformations}
          mostVisibleCategoryIndex={mostVisibleCategoryIndex}
          onClick={handleClick}
          categoryExpanded={categoryExpanded}
          indexType={indexType}
        />
        {categories.map((category, i) => (
          <NavRow key={i} category={category} mostVisibleCategoryIndex={mostVisibleCategoryIndex} onClick={handleClick} categoryExpanded={categoryExpanded} indexType={indexType} />
        ))}
      </div>
    </nav>
  );
};

const NavRow = ({ category, mostVisibleCategoryIndex, onClick, categoryExpanded, indexType }) => {
  const { name, href, color, hierarchy, index, parentCategoryName, answeredInputs, totalInputs } = category;
  if (hierarchy === 2 && parentCategoryName !== categoryExpanded) {
    return <></>;
  }
  const isMostVisibleCategory = hierarchy === 1 && index === mostVisibleCategoryIndex;
  let rowClassName = "flex items-center w-full nav_row_" + hierarchy;
  if (isMostVisibleCategory) {
    rowClassName += " active";
  }
  const categoryProgress = `${answeredInputs} / ${totalInputs}`;
  const progressClassName = answeredInputs < totalInputs ? "uncomplete" : "complete";
  const backgroundColor = isMostVisibleCategory ? color : "transparent";
  const fontWeight = isMostVisibleCategory && "800";

  return (
    <a href={`#${href}`} className={rowClassName} id={index.toString()} onClick={(e) => onClick(e)} data-category={name}>
      <div className="w-[15%] mr-2.5">
        <p className="w-max" style={{ backgroundColor: backgroundColor }}>
          {getIndex(indexType, category.code, index)}
        </p>
      </div>
      <p className="mr-2.5 w-[calc(63%-10px)]" style={{ fontWeight: fontWeight }}>
        {name}
      </p>
      <p style={{ backgroundColor: backgroundColor, fontWeight: fontWeight }} className={`${progressClassName} py-px px-1 rounded-full w-[22%] text-center`}>
        {categoryProgress}
      </p>
    </a>
  );
};

export default NavEstimation;
