import React, { useState } from "react";
import { ResultsSubCategoryTitle } from "components/results/ResultsPartials";
import { DataGrid } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/styles";

const columnsLarge = [
  { field: "name", headerName: "Projet", width: 200, headerClassName: "table_header" },
  { field: "type", headerName: "Type de projet", width: 180, headerClassName: "table_header" },
  { field: "emissions", headerName: "Émissions (tCO2eq)", width: 220, headerClassName: "table_header" },
  { field: "budget", headerName: "Budget (k€)", width: 150, headerClassName: "table_header" },
];

const columnsSmall = [
  { field: "name", headerName: "Projet", width: 125, headerClassName: "table_header", sortable: false },
  { field: "type", headerName: "Type de projet", width: 145, headerClassName: "table_header", sortable: false },
  { field: "emissions", headerName: "Émissions (tCO2)", width: 150, headerClassName: "table_header", sortable: false },
  { field: "budget", headerName: "Budget (k€)", width: 120, headerClassName: "table_header", sortable: false },
];

const EstimationsTable = (props) => {
  const [sortModel, setSortModel] = useState(undefined);

  const { estimations, color, isPrinting } = props;
  const finalColor = color ? color : "var(--backLightBlue)";

  const columns = isPrinting ? columnsSmall : columnsLarge;
  const disableColumnMenu = isPrinting ? true : false;

  const MyDataGrid = withStyles(() => ({
    root: {
      "& .MuiDataGrid-columnHeaderWrapper": {
        backgroundColor: finalColor,
      },
    },
  }))(DataGrid);

  const table = estimations.map((estimation, i) => {
    const totalImpact = estimation.form?.reduce((acc, cur) => acc + cur.impact, 0) || 0;
    const totalAmount = estimation.form?.reduce((acc, cur) => acc + cur.amount, 0) || 0;
    const emissions = Math.round((totalImpact / 1000) * 10) / 10;
    const budget = Math.round((totalAmount / 1000) * 10) / 10;
    return {
      id: i + 1,
      name: estimation.information.title,
      type: estimation.information.carbonFootprintType,
      emissions: emissions,
      budget: budget,
    };
  });

  const onSortModelChange = (params) => {
    setSortModel(params.sortModel);
  };

  return (
    <div className="estimations_table w-full mb-8">
      <ResultsSubCategoryTitle title="Liste des projets" />
      <div className="w-full">
        <MyDataGrid sortModel={sortModel} onSortModelChange={onSortModelChange} rows={table} columns={columns} pageSize={10} autoHeight disableColumnMenu={disableColumnMenu} />
      </div>
    </div>
  );
};

export default EstimationsTable;
