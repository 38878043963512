import React from "react";

const TotalBadge = ({ number }) => {
  if (!number && number !== 0) {
    return (
      <div>
        <p>Totaux :</p>
        <p>Chargement en cours</p>
      </div>
    );
  }

  return (
    <div className="border border-blue-border px-2 flex justify-center h-full items-center">
      <div className="grid grid-cols-[50px_fit-content(100px)_50px] gap-x-1 text-[#0F3D4A]">
        <p>Total</p>
        <b>{(number / 1000).toLocaleString("fr", { maximumFractionDigits: 2 })}</b>
        <p>tCO2eq</p>
      </div>
    </div>
  );
};

export default TotalBadge;
