import React from "react";

const ProgressBar = ({ progress, precision }) => {
  const finalPrecision = precision ? precision : " complétés";
  return (
    <div className="p-2 border border-blueBorder h-full justify-center flex flex-col">
      <p className="flex items-center self-center whitespace-pre justify-center">
        <b className="font-bold text-md">{progress}%</b>
        {finalPrecision}
      </p>
      <div className="flex h-2 overflow-hidden rounded-sm bg-blue-dark-150 text-xs">
        <div style={{ width: `${progress}%` }} className="h-full bg-blue-dark" />
      </div>
    </div>
  );
};

export default ProgressBar;
