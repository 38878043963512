import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { CgCheckO } from "react-icons/cg";

import api from "../../services/api";
import { Select } from "components/select";
import { Loader } from "components/loader";

const TYPES = [
  {
    name: "Cinéma",
    genres: ["Fiction", "Documentaire"],
    formats: ["Long-Métrage", "Court-Métrage"],
    matrices: ["cnc"],
    certified: true,
  },
  {
    name: "Série",
    genres: ["Fiction", "Documentaire"],
    formats: ["Long-Métrage", "Court-Métrage"],
    matrices: ["cnc"],
    certified: true,
  },
  {
    name: "Unitaire",
    genres: ["Fiction", "Documentaire", "Autre"],
    formats: ["Long-Métrage", "Court-Métrage"],
    matrices: ["cnc"],
    certified: true,
  },
  {
    name: "Publicité",
    genres: ["Autre"],
    formats: ["Court-Métrage"],
    matrices: ["chedar", "alpha"],
  },
  {
    name: "Autre",
    genres: ["Fiction", "Documentaire", "Autre"],
    formats: ["Long-Métrage", "Court-Métrage"],
    matrices: ["cnc", "chedar", "alpha"],
  },
];

const MATRICE_NAMES = {
  chedar: "Matrice Chedar",
  alpha: "Matrice Alpha",
  cnc: "Matrice CNC",
};

const CreateBilan = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const handleSubmit = async () => {
    console.log("send", data);
    try {
      const res = await api.post("/project", data);
      navigate(`/bilan/${res.data.forecast._id}?help=true`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="h-full flex flex-col">
      <header className="sticky bg-white h-16 px-12">
        <div id="header_estimation" className="grid grid-cols-3 items-center h-full">
          <Link to="/" className="text-blue-dark flex items-center">
            <BsChevronLeft className="mr-2" />
            <span>Retour au tableau de bord</span>
          </Link>
          <h1 className="font-bold text-xl justify-self-center">Nouveau Bilan Carbone</h1>
          <div />
        </div>
      </header>

      <div className="w-full flex-1 bg-white overflow-y-scroll">
        <div className="flex h-full gap-5">
          <div className="w-1/2 h-full pt-12 pb-20 px-20 flex flex-col">
            <div className="flex items-center mb-10 justify-between">
              <img src="/images/logos/Logo_seco2_bleu.png" alt="logo secoya" className="max-w-[100px]" />
              <p className="text-right text-gray-text text-xl">Créer un nouveau bilan {step}/2</p>
            </div>
            {
              {
                1: <FormStep1 data={data} setData={setData} setStep={setStep} />,
                2: <FormStep2 data={data} setData={setData} setStep={setStep} handleSubmit={handleSubmit} />,
              }[step]
            }
          </div>
          <div className="w-1/2 h-full py-28 px-20 bg-primary-200">
            {
              {
                1: <TextStep1 />,
                2: <TextStep2 data={data} />,
              }[step]
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const FormStep1 = ({ data, setData, setStep }) => {
  const [errors, setErrors] = useState({});

  const handleNext = () => {
    const obj = {};
    if (data.certified === undefined) obj.type = "Veuillez choisir une option";
    setErrors(obj);
    if (Object.keys(obj).length > 0) return;
    setStep(2);
  };

  return (
    <div className="flex flex-col flex-1 justify-between gap-6">
      <div>
        <h2 className="text-lg pb-4 font-bold">A quoi va servir votre bilan carbone ?</h2>
        <div className="flex gap-3 w-full ">
          <button className={`w-full ${data.certified === true ? "blue-button" : "empty-button"} font-normal`} onClick={() => setData((prev) => ({ ...prev, certified: true }))}>
            Je veux calculer mon impact carbone et obtenir une aide du CNC
          </button>
          <button
            className={`w-full p-2 border rounded-lg ${data.certified === false ? "blue-button" : "empty-button"} font-normal`}
            onClick={() => setData((prev) => ({ ...prev, certified: false }))}>
            Je veux juste calculer mon impact carbone
          </button>
        </div>
        {errors.certified && <p className="text-red-500">{errors.certified}</p>}
      </div>

      {data.certified !== undefined && (
        <div className="mt-10 flex justify-end">
          <button className="w-1/4 blue-button" onClick={handleNext}>
            Continuer
          </button>
        </div>
      )}
    </div>
  );
};

const FormStep2 = ({ setData, data, setStep, handleSubmit }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    const obj = {};
    if (!data.title) obj.title = "Veuillez remplir ce champ";
    if (!data.type) obj.type = "Veuillez préciser le type de projet";
    if (!data.genre) obj.genre = "Veuillez préciser le genre de votre projet";
    if (!data.format) obj.format = "Veuillez préciser le format de votre projet";
    setErrors(obj);
    if (Object.keys(obj).length > 0) return;
    setLoading(true);
    await handleSubmit();
    setLoading(false);
  };

  return (
    <div className="flex flex-col flex-1 justify-between">
      <div className="flex flex-col gap-16">
        <div>
          <h2 className="text-lg pb-4 font-bold">Quel est le titre de votre projet ?</h2>
          <input className="input border border-blue-dark w-full" onChange={(e) => setData((prev) => ({ ...prev, title: e.target.value }))} placeholder="Titre du projet" />
          {errors.title && <p className="text-red-500">{errors.title}</p>}
        </div>
        <div>
          <h2 className="text-lg pb-4 font-bold">Quelle est la typologie de votre projet ?</h2>
          <div className="grid grid-cols-3 gap-2">
            <Select
              options={TYPES.filter((e) => (data.certified ? e.certified : true)).map((e) => e.name)}
              placeholder="Type"
              value={data.type}
              onChange={(v) =>
                setData((prev) => {
                  const newData = { ...prev, type: v, genre: undefined, format: undefined };
                  const matrices = TYPES.find((e) => e.name === v).matrices;
                  if (matrices.length === 1 && !prev.certified) newData.matriceName = matrices[0];
                  return newData;
                })
              }
            />
            {data.type && (
              <>
                <Select
                  options={TYPES.find((e) => e.name === data.type).genres}
                  placeholder="Genre"
                  value={data.genre}
                  onChange={(v) => setData((prev) => ({ ...prev, genre: v }))}
                />
                <Select
                  options={TYPES.find((e) => e.name === data.type).formats}
                  placeholder="Format"
                  value={data.format}
                  onChange={(v) => setData((prev) => ({ ...prev, format: v }))}
                />
              </>
            )}
          </div>
          {errors.type && <p className="text-red-500">{errors.type}</p>}
          {errors.genre && <p className="text-red-500">{errors.genre}</p>}
          {errors.format && <p className="text-red-500">{errors.format}</p>}
        </div>
        <div>
          {data.certified === false && data.type && data.genre && data.format && (
            <>
              <h2 className="text-lg pb-4 font-bold">Choisissez la matrice de votre projet ?</h2>
              <div className="w-full">
                <Select
                  options={TYPES.find((e) => e.name === data.type).matrices}
                  placeholder="Matrice"
                  value={data.matriceName}
                  onChange={(v) => setData((prev) => ({ ...prev, matriceName: v }))}
                  labels={(v) => MATRICE_NAMES[v]}
                />
              </div>
            </>
          )}
          {errors.type && <p className="text-red-500">{errors.type}</p>}
          {errors.genre && <p className="text-red-500">{errors.genre}</p>}
          {errors.format && <p className="text-red-500">{errors.format}</p>}
        </div>
      </div>

      <div className="mt-10 flex justify-between">
        <button className="w-1/4 empty-button" onClick={() => setStep(1)}>
          Retour
        </button>
        {data.title && data.type && data.genre && data.format && (
          <button className="w-1/4 blue-button flex justify-center" onClick={handleNext} disabled={loading}>
            {loading ? <Loader color="white" /> : "Continuer"}
          </button>
        )}
      </div>
    </div>
  );
};

const TextStep1 = () => (
  <div className="flex flex-col gap-20 text-blue-dark">
    <div>
      <div className="flex items-center">
        <CgCheckO className="mr-2 text-green-validation" />
        <h3 className="font-bold">Nous sommes homologués par le CNC</h3>
      </div>
      <p className="text-blue-deep-dark font-mainpolice">
        Seco2 est un outil homologué par le CNC. En utilisant Seco2 pour faire votre bilan carbone, vous êtes assuré.e qu’il sera au bon format et qu’il sera accepté afin de
        toucher votre aide.
      </p>
    </div>

    <div>
      <h3 className="font-bold">Là pour une autre démarche ?</h3>
      <p className="text-blue-deep-dark font-mainpolice">
        Seco2 est adapté à tous les acteurs de la production audiovisuelle, qu’ils soient concernés par le CNC ou non. Nous accompagnons tous les types de productions vers
        l’éco-responsabilité et la neutralité carbone.
      </p>
    </div>
  </div>
);

const TextStep2 = ({ data }) => (
  <div className="flex flex-col gap-16 text-blue-dark">
    <div>
      <h3 className="font-bold">Vous ne trouvez pas votre typologie de projet ?</h3>
      <p className="text-blue-deep-dark font-mainpolice">
        Seco2 s’améliore au fur et à mesure pour intégrer le plus de types de productions différentes. Envoyez-nous un message dans la bulle de chat en bas à droite pour nous
        indiquer la vôtre !
      </p>
    </div>
    {data.certified === false && data.type && data.genre && data.format && (
      <div>
        <h3 className="font-bold">Vous ne trouvez pas de matrice qui vous convienne ?</h3>
        <p className="text-blue-deep-dark font-mainpolice">Pour ajout de toute nouvelle matrice, nous sommes à votre écoute : contactez-nous via le chat live !</p>
      </div>
    )}
  </div>
);

export default CreateBilan;
