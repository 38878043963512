import React from "react";
import { BsChevronLeft, BsCheckCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { Modal } from "components/modal";
import api from "../../../services/api";

const fillMatrice = (matrice) => {
  if (matrice.parameters && matrice.parameters.length > 0) {
    matrice.parameters.forEach((param) => {
      param.options.forEach((o) => (o.value === undefined ? (o.value = 0) : null));
    });
  }
  if (matrice.categories && matrice.categories.length > 0) {
    matrice.categories.forEach((subCat) => fillMatrice(subCat));
  }
};

export const ValidateBilanModal = ({ isOpen, onClose, bilan }) => {
  const navigate = useNavigate();
  const handleValidate = async () => {
    bilan.matrice.forEach((cat) => fillMatrice(cat));

    api.put(`/bilan/${bilan._id}`, bilan).then((res) => {
      if (res.ok) {
        navigate(`/bilan/${bilan._id}/result`);
        onClose();
      }
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="mb-6 font-bold text-2xl leading-8">Oups... Certaines cases sont restées vides !</h2>

      <p className="font-normal text-base leading-5 mb-4">
        <strong>Certaines lignes</strong> de données de votre budget sont restées vides.{" "}
      </p>

      <p className="font-normal text-base leading-5 mb-4">
        Si vous n'avez pas de dépenses associées, <strong>souhaitez-vous les remplir automatiquement avec des 0</strong> ?
      </p>

      <div className="mt-10 gap-x-4 flex ml-auto w-2/3">
        <button className="empty-button flex items-center justify-center gap-2 px-2 w-full" onClick={onClose}>
          <BsChevronLeft className="w-4 h-4" />
          Retour
        </button>
        <button className="blue-button flex items-center justify-center gap-2 px-2 w-full" onClick={handleValidate}>
          <BsCheckCircle className="w-4 h-4" />
          Valider
        </button>
      </div>
    </Modal>
  );
};
