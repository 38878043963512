import React from "react";
import Slider from "react-slick";
import { BsChevronLeft } from "react-icons/bs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Modal } from "components/modal";
import Help1 from "../../../assets/img/help-1.png";
import Help2 from "../../../assets/img/help-2.png";
import Help3 from "../../../assets/img/help-3.png";
import Help4 from "../../../assets/img/help-4.png";
import Help5 from "../../../assets/img/help-5.png";
import Help6 from "../../../assets/img/help-6.png";

const IMAGES = [Help1, Help2, Help3, Help4, Help5, Help6];

export const HelpModal = ({ isOpen, onClose }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    dotsClass: "slick-dots help_slider_dots",
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-[56rem]">
        <Slider {...settings}>
          {IMAGES.map((image, i) => (
            <img key={i} src={image} alt="check" />
          ))}
        </Slider>
      </div>
    </Modal>
  );
};
