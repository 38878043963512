import React, { useState } from "react";

import MyIcon from "components/partials/MyIcon";
import InfoBox from "components/Base/InfoBox";
import ProgressBar from "components/ProgressBar";

//Modals

//Material UI
import { DataGrid } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/styles";
import { Modal } from "components/modal";
// import CircularProgress from '@material-ui/core/CircularProgress';

export const ModalImportingData = ({ isOpen, close, progress, isDataAddedToEstim, importResults, dataImportedError, resetImport }) => {
  const text = isDataAddedToEstim
    ? progress > 0
      ? `Vos données budgétaires ont bien été importées.`
      : "Oups... les catégories de Seco2 ne correspondent pas aux catégories du fichier importé. Pas de panique : le remplissage des cases ne prend que quelques minutes !"
    : "Vos données sont en cours d'importation.";

  const [isImportDetailsOpen, setIsImportDetailsOpen] = useState(false);

  const handleValidate = () => {
    resetImport(null);
    close();
  };

  let nbDataInBudget = undefined;
  let nbDataImported = undefined;
  let percentageImported = undefined;

  if (importResults) {
    nbDataImported = importResults.filter((data) => data.found).length;
    nbDataInBudget = importResults.length;
    percentageImported = Math.round((nbDataImported / nbDataInBudget) * 100);
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalImportDetails isOpen={isImportDetailsOpen} close={() => setIsImportDetailsOpen(false)} importResults={importResults} />
      <h2 className="mb-6 font-bold text-2xl leading-8">Import des données</h2>

      {dataImportedError && (
        <>
          <InfoBox error={dataImportedError.error}>{dataImportedError.message}</InfoBox>
          <div className="mt-10 flex justify-end w-full">
            <button className="empty-button flex items-center justify-center gap-2 pl-2 w-[120px]" disabled={!isDataAddedToEstim} onClick={handleValidate}>
              <MyIcon icon="back" />
              Retour
            </button>
          </div>
        </>
      )}

      {!dataImportedError && (
        <>
          <p className="font-bold text-base leading-5 mb-4">{text}</p>
          {importResults && (
            <p>
              {percentageImported}% de votre budget a été importé ({nbDataImported} données / {nbDataInBudget}), {progress}% de votre questionnaire est désormais rempli.
              <br />
              <br />
              Pour plus d'informations sur votre import, cliquez sur "Détails import".
              <br />
              <br />
            </p>
          )}
          {/* {!isDataImported && <CircularProgress disableShrink />} */}
          {isDataAddedToEstim && <ProgressBar progress={percentageImported} precision=" budget importé" />}

          <div className="w-2/3 flex items-center justify-end ml-auto mt-10 gap-2">
            <button className="empty-button w-full" disabled={!isDataAddedToEstim} onClick={() => setIsImportDetailsOpen(true)}>
              Détails import
            </button>
            <button className="blue-button flex items-center justify-center gap-2 pl-2 w-full" disabled={!isDataAddedToEstim} onClick={handleValidate}>
              <MyIcon icon="check" />
              Valider
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

const columns = [
  { field: "category", headerName: "Categorie", width: 150, headerClassName: "table_header" },
  { field: "description", headerName: "Description", width: 300, headerClassName: "table_header" },
  { field: "value", headerName: "Budget (€)", width: 150, headerClassName: "table_header" },
];

const MyDataGrid = withStyles(() => ({
  root: {
    "& .MuiDataGrid-columnHeaderWrapper": {
      backgroundColor: "var(--backLightBlue)",
    },
  },
}))(DataGrid);

const ModalImportDetails = (props) => {
  const { importResults, isOpen, close } = props;
  const table = importResults.map((data, i) => {
    data.id = i;
    return data;
  });
  const dataImported = table.filter((data) => data.found);
  const dataNotImported = table.filter((data) => !data.found);
  const dataGridWidth = columns.reduce((a, v) => a + v.width, 0) + 50;
  return (
    <Modal isOpen={isOpen} onClose={close} className="overflow-auto">
      <h2 className="mb-6 font-bold text-2xl leading-8">Détail de l'import</h2>
      <p>Les données suivantes ont été importées :</p>
      <br />
      <div id="data_imported_grid" style={{ width: dataGridWidth, height: "400px" }}>
        <MyDataGrid rows={dataImported} columns={columns} />
      </div>
      <br />
      <p>Les données suivantes n'ont pu être importées :</p>
      <br />
      <div id="data_imported_grid" style={{ width: dataGridWidth, height: "400px" }}>
        <MyDataGrid rows={dataNotImported} columns={columns} />
      </div>
      <br />
      <p>
        <i>
          Si ces données n'ont pas été importées, c'est a priori pour l'une des trois raisons suivantes : elles ne sont pas des catégories à impact (par exemple : salaires), elles
          doivent être divisées en plusieurs catégories Seco2 ou elles n'ont pas été reconnues. Dans ces deux derniers cas il faut alors les entrer manuellement (nous travaillons à
          accompagner davantage cette étape).
        </i>
      </p>
      <div className="mt-10 flex justify-end w-full">
        <button className="empty-button flex items-center justify-center gap-2 pl-2 w-[120px]" onClick={close}>
          <MyIcon icon="back" />
          Retour
        </button>
      </div>
    </Modal>
  );
};

export default ModalImportingData;
