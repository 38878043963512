import ExcelJS from "exceljs";
import { getAmount, getImpact } from "./bilan";
import { BILAN_TYPES } from "./static";

const BLUECELL = {
  fill: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "0066a1" },
  },
  border: {
    top: { style: "thin", color: { argb: "000000" } },
    left: { style: "thin", color: { argb: "000000" } },
    bottom: { style: "thin", color: { argb: "000000" } },
    right: { style: "thin", color: { argb: "000000" } },
  },
  font: {
    name: "Verdana",
    family: "2",
    size: 11,
    color: { argb: "FFFFFF" },
    bold: true,
  },
  alignment: {
    horizontal: "center",
    vertical: "middle",
  },
};

const BLUECELL_HEADER = {
  fill: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "0066a1" },
  },
  border: {
    top: { style: "thin", color: { argb: "000000" } },
    left: { style: "thin", color: { argb: "000000" } },
    bottom: { style: "thin", color: { argb: "000000" } },
    right: { style: "thin", color: { argb: "000000" } },
  },
  font: {
    name: "Verdana",
    family: "2",
    size: 9,
    color: { argb: "FFFFFF" },
    bold: false,
  },
  alignment: {
    horizontal: "left",
    vertical: "middle",
  },
};

const REDCELL = {
  fill: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "e37258" },
  },
  border: {
    top: { style: "thin", color: { argb: "000000" } },
    left: { style: "thin", color: { argb: "000000" } },
    bottom: { style: "thin", color: { argb: "000000" } },
    right: { style: "thin", color: { argb: "000000" } },
  },
  font: {
    name: "Verdana",
    family: "2",
    size: 11,
    color: { argb: "FFFFFF" },
    bold: true,
  },
  alignment: {
    horizontal: "center",
    vertical: "middle",
  },
};

const GREENCELL = {
  fill: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "92b277" },
  },
  border: {
    top: { style: "thin", color: { argb: "000000" } },
    left: { style: "thin", color: { argb: "000000" } },
    bottom: { style: "thin", color: { argb: "000000" } },
    right: { style: "thin", color: { argb: "000000" } },
  },
  font: {
    name: "Verdana",
    family: "2",
    size: 11,
    color: { argb: "FFFFFF" },
    bold: true,
  },
  alignment: {
    horizontal: "center",
    vertical: "middle",
  },
};

const YELLOWCELL = {
  fill: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "ffc000" },
  },
  border: {
    top: { style: "thin", color: { argb: "000000" } },
    left: { style: "thin", color: { argb: "000000" } },
    bottom: { style: "thin", color: { argb: "000000" } },
    right: { style: "thin", color: { argb: "000000" } },
  },
  font: {
    name: "Verdana",
    family: "2",
    size: 11,
    color: { argb: "000000" },
    bold: true,
  },
  alignment: {
    horizontal: "center",
    vertical: "middle",
    wrapText: true,
  },
};

const NORMALCELL = {
  border: {
    top: { style: "thin", color: { argb: "000000" } },
    left: { style: "thin", color: { argb: "000000" } },
    bottom: { style: "thin", color: { argb: "000000" } },
    right: { style: "thin", color: { argb: "000000" } },
  },
  font: {
    name: "Verdana",
    family: "2",
    size: 11,
    color: { argb: "000000" },
  },
  alignment: {
    vertical: "middle",
    wrapText: true,
  },
};

export const exportExcel = async (project, bilan) => {
  const matrice = bilan.matrice;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Export Data");

  worksheet.getColumn("B").width = 29;
  worksheet.getColumn("C").width = 35;
  worksheet.getColumn("D").width = 40;
  worksheet.getColumn("E").width = 40;
  worksheet.getRow(2).height = 48;

  worksheet.getCell("B2").value = "HOMOLOGATION DES OUTILS DE CALCUL DE L’EMPREINTE CARBONE DES ŒUVRES AUDIOVISUELLES ET CINÉMATOGRAPHIQUES";
  worksheet.mergeCells("B2:G2");
  worksheet.getCell("B2").style = {
    font: {
      name: "Verdana",
      family: "2",
      size: 14,
      color: { argb: "0066a1" },
      bold: true,
    },
    alignment: {
      vertical: "middle",
      wrapText: true,
    },
  };

  worksheet.getCell("B4").value = "Format";
  worksheet.getCell("B4").style = BLUECELL_HEADER;
  worksheet.getCell("C4").value = project.format;
  worksheet.getCell("B5").value = "Genre";
  worksheet.getCell("B5").style = BLUECELL_HEADER;
  worksheet.getCell("C5").value = project.genre;
  worksheet.getCell("B6").value = "Type d'oeuvre";
  worksheet.getCell("B6").style = BLUECELL_HEADER;
  worksheet.getCell("C6").value = project.type;

  worksheet.mergeCells("D8:E8");
  worksheet.getCell("D8").value = project.title;
  worksheet.getCell("D8").style = BLUECELL;

  worksheet.getCell("D9").value = bilan.uniqueId;
  worksheet.getCell("D9").style = BLUECELL;

  worksheet.getCell("E9").value = BILAN_TYPES[bilan.type];
  worksheet.getCell("E9").style = BLUECELL;

  worksheet.getCell("B10").value = "Poste";
  worksheet.getCell("B10").style = BLUECELL;

  worksheet.getCell("C10").value = "Donnée d'activité";
  worksheet.getCell("C10").style = BLUECELL;

  worksheet.getCell("D10").value = "Donnée d'entrée";
  worksheet.getCell("D10").style = REDCELL;

  worksheet.getCell("E10").value = "kgCO2e";
  worksheet.getCell("E10").style = GREENCELL;

  let row = 11;
  let firstRow = row;
  let lastRow = row;
  let categoryTmp = matrice[0].name;
  matrice.forEach((category) => {
    category.categories?.forEach((sc) => {
      worksheet.getCell(`B${row}`).value = category.name;
      worksheet.getCell(`C${row}`).value = sc.name;
      worksheet.getCell(`D${row}`).value = getAmount(sc);
      worksheet.getCell(`E${row}`).value = getImpact(sc);
      worksheet.getCell(`B${row}`).style = YELLOWCELL;
      worksheet.getCell(`C${row}`).style = NORMALCELL;
      worksheet.getCell(`D${row}`).style = NORMALCELL;
      worksheet.getCell(`E${row}`).style = NORMALCELL;

      if (categoryTmp !== category.name) {
        worksheet.mergeCells(`B${firstRow}:B${lastRow}`);
        categoryTmp = category.name;
        firstRow = row;
      }
      lastRow = row;
      row++;
    });
  });
  worksheet.mergeCells(`B${firstRow}:B${lastRow}`);

  const updateDate = new Date(bilan.updatedAt);
  const day = updateDate.getDate();
  const month = updateDate.getMonth() + 1;
  const year = updateDate.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  worksheet.getCell(`B${row + 1}`).value = "Source : Seco2";
  worksheet.getCell(`B${row + 1}`).style = {
    font: {
      name: "Verdana",
      family: "2",
      size: 11,
      color: { argb: "000000" },
      italic: true,
    },
  };
  worksheet.getCell(`B${row + 2}`).value = `Date de mise à jour : ${formattedDay}/${formattedMonth}/${year}`;
  worksheet.getCell(`B${row + 2}`).style = {
    font: {
      name: "Verdana",
      family: "2",
      size: 11,
      color: { argb: "000000" },
      italic: true,
    },
  };

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `Seco2 Bilan ${project.title}.xlsx`;
  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
