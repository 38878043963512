import React from "react";
import { Route, Routes } from "react-router-dom";

import Edit from "./edit";
import Results from "./results";
import New from "./new";
import UniqueId from "./UniqueId";

const Admin = () => {
  return (
    <Routes>
      <Route path="/:estimationId" element={<Edit />} />
      <Route path="/new" element={<New />} />
      <Route path="/find" element={<UniqueId />} />
      <Route path="/:estimationId/results" element={<Results />} />
    </Routes>
  );
};

export default Admin;
