import React from "react";
import { Link } from "react-router-dom";

import useStore from "Stores/zustand";
import plausibleEvent from "../services/plausible";

export default function PayBanner() {
  const { user } = useStore();

  if (user.hasPaid) return <></>;

  return (
    <div>
      {["basic", "premium"].includes(user.formulaRequest) && user.formula === "freemium" ? (
        <div className="p-6 bg-[#F1FEF3] border border-[#73AE7C] rounded-md mb-3 text-[#73AE7C]">
          <div>
            Votre demande de <b>formule payante a bien été envoyée à nos équipes</b>. Elles vont revenir vers vous par email dès que possible.
          </div>
          <div>
            Un email de confirmation vous a été envoyé sur <b>{user.email}</b>
          </div>
        </div>
      ) : null}
      <div className="flex items-center p-14 bg-primary-900 mb-6">
        <div className="text-3xl text-white flex-1 font-bold leading-none">Faites remplir votre bilan automatiquement</div>
        <div className="flex-1 text-right">
          <Link to="/account" onClick={() => plausibleEvent("SeePlans")}>
            <button className="blue-button p-4 w-full max-w-xs">Accédez à nos formules</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
