import { useState } from "react";
import { Formik, Form } from "formik";
import validator from "validator";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../services/api";
import Logo from "../../assets/img/logo-bleu.png";
import { Loader } from "../../components/loader";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(null);

  const validate = (values, setErrors) => {
    const errors = {};
    if (!validator.isEmail(values.email)) errors.email = "Adresse email invalide";
    setErrors(errors);
    return !errors.email;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    setLoading(true);

    api
      .post("/auth/forgot-password", values)
      .then((res) => {
        setLoading(false);
        if (res.ok) setSent(true);
        else setErrors({ email: "Une erreur est survenue" });
      })
      .catch(() => {
        setLoading(false);
        toast.error("Une erreur est survenue");
      });
  };
  return (
    <div className="flex flex-col items-center">
      <img src={Logo} alt="Seco2" className="w-44" />
      <p className="text-gray-500">Connectez-vous</p>
      <p className="font-normal text-center my-4 w-96">Renseignez l'adresse e-mail liée au compte avec lequel vous vous êtes inscrit.</p>

      <Formik initialValues={{ email: "" }} onSubmit={handleSubmit}>
        {({ values, errors, handleChange }) => (
          <Form className="my-4 flex flex-col items-center w-80">
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-1" htmlFor="email">
                E-mail
              </label>
              <input className="input border border-gray-400" name="email" value={values.email} onChange={handleChange} placeholder="john@ugc.fr" />
              {errors.email && <div className="flex items-center text-sm text-red-500">{errors.email}</div>}
            </div>
            {!sent ? (
              <button className="blue-button my-8 text-lg" type="submit" disabled={sent || loading}>
                {loading ? <Loader color="white" /> : "Envoyer"}
              </button>
            ) : (
              <p className="font-bold text-[#15E0A6]">Lien renvoyé !</p>
            )}
          </Form>
        )}
      </Formik>
      <p className="w-full text-left">
        Vous vous souvenez de votre mot de passe ?{" "}
        <Link to="/signin" className="font-bold text-[#2791B5] hover:underline">
          Connectez-vous
        </Link>
      </p>
    </div>
  );
};
export default ForgotPassword;
