import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsCheckCircle, BsChevronLeft, BsPlusCircle, BsGear } from "react-icons/bs";

import useStore from "Stores/zustand";

import api from "services/api";
import { Modal } from "./modal";
import { exportAllEstimations } from "utils/export-cnc";
import { Loader } from "./loader";

export const NavBar = ({ user }) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = React.useState(false);
  const [exportingExcel, setExportingExcel] = React.useState(false);

  const handleExportCnc = async () => {
    setExportingExcel(true);
    try {
      const res = await api.get("/bilan/cnc-export");
      if (res.ok) {
        const buffer = await exportAllEstimations(res.data);
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `extraction-globale-cnc.xlsx`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setExportingExcel(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {user.role !== "cnc" ? (
        <div className="mb-2.5">
          <Link className="blue-button w-full h-14 flex items-center justify-center gap-2" to="/bilan/new">
            <BsPlusCircle className="mr-2" />
            Nouveau Projet
          </Link>
        </div>
      ) : (
        <div className="mb-2.5">
          <button className="blue-button w-full h-14 flex items-center justify-center gap-2" onClick={handleExportCnc} disabled={exportingExcel}>
            {exportingExcel ? <Loader /> : "Télécharger une synthèse"}
          </button>
        </div>
      )}

      <div className="flex flex-col bg-white">
        <LogoutModal open={isLogoutModalOpen} setOpen={setIsLogoutModalOpen} />
        {user.role === "cnc" ? (
          <NavLink to="/estimation/find">Vérifier un bilan carbone</NavLink>
        ) : (
          <NavLink to="/" activate={(l) => l === "/"}>
            Mon tableau de bord
          </NavLink>
        )}
        {(user.hasPaid || user.hasABilanPaid) && (
          <>
            <div className="h-px bg-blue-gray w-[80%] ml-[10%]" />
            <NavLink to="/stats">
              Statistiques
              <span className="text-[10px] text-cyan-600 ml-3">{user.hasPaid ? "FORMULE ABONNEMENT" : "FORMULE BILAN"}</span>
            </NavLink>
          </>
        )}
        <div className="h-px bg-blue-gray w-[80%] ml-[10%]" />
        <NavLink to="/account">Mon compte</NavLink>
        <div className="h-px bg-blue-gray w-[80%] ml-[10%]" />
        <NavLink to="/help">Aide / FAQ</NavLink>
        <div className="h-px bg-blue-gray w-[80%] ml-[10%]" />
        <NavLink to="/contact">Contact / A propos</NavLink>
        <div className="h-px bg-blue-gray w-[80%] ml-[10%]" />
        <button onClick={() => setIsLogoutModalOpen(true)} role="button" className="flex items-center py-6 pr-2 pl-7 hover:font-bold">
          Me déconnecter
        </button>
      </div>
      {(user.role === "admin" || user.role === "superAdmin") && (
        <div className="mt-3">
          <Link to="/admin/users" className="red-button h-14 w-full flex items-center justify-center gap-2">
            <BsGear className="mr-2" />
            Administration
          </Link>
        </div>
      )}
    </>
  );
};

export const AdminNavBar = () => {
  return (
    <div className="flex flex-col bg-white">
      <NavLink to="/admin/users">Utilisateurs</NavLink>
      <div className="h-px bg-blue-gray w-[80%] ml-[10%]" />
      <NavLink to="/admin/userRequests">Demandes Utilisateurs</NavLink>
      <div className="h-px bg-blue-gray w-[80%] ml-[10%]" />
      <NavLink to="/admin/stats">Statistiques</NavLink>
      <div className="h-px bg-blue-gray w-[80%] ml-[10%]" />
    </div>
  );
};

const NavLink = ({ to, children, activate = null }) => {
  const location = useLocation();
  const active = activate ? activate(location.pathname) : location.pathname.includes(to);
  return (
    <Link to={to} className={`${active ? "font-bold" : "font-normal"} flex items-center py-6 px-2 hover:font-bold`}>
      <div className={`w-2 h-6 rounded-xs ${active ? "bg-blue-dark" : "bg-transparent"} mr-3`} />
      {children}
    </Link>
  );
};

const LogoutModal = ({ open, setOpen }) => {
  const { setUser } = useStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    setUser(null);
    api.post(`/auth/logout`);
    setOpen(false);
    navigate("/signin");
  };

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} className="w-2/3 p-16">
      <h2 className="mb-6 font-bold text-2xl leading-8">Êtes-vous sûr.e de vouloir vous déconnecter ?</h2>
      <p>Vous quitterez votre espace personnel mais votre travail y reste enregistré.</p>

      <div className="w-2/3 flex items-center justify-end ml-auto mt-10 gap-2">
        <button className="empty-button flex items-center gap-2 justify-center w-full" onClick={() => setOpen(false)}>
          <BsChevronLeft className="font-semibold" />
          Retour
        </button>

        <button className="blue-button flex items-center gap-2 justify-center w-full" onClick={handleLogout}>
          <BsCheckCircle className="text-white" />
          Confirmer
        </button>
      </div>
    </Modal>
  );
};
