import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

export const Select = ({ options, value, onChange, disabled = false, placeholder = "Sélectionner une option", width = "w-64", position = "left-0", labels = (v) => v }) => (
  <Listbox value={value || ""} onChange={onChange} disabled={disabled}>
    {({ open }) => (
      <>
        <div className="relative w-full">
          <Listbox.Button className="input p-2 border border-blue-dark w-full">
            {value ? <p className="truncate text-left text-sm">{labels(value)}</p> : <p className="truncate opacity-60 text-left text-sm">{placeholder}</p>}
            <div className="absolute inset-y-0 right-0 flex items-center pr-2">{open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}</div>
          </Listbox.Button>

          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className={`absolute z-20 mt-1 max-h-64 ${width} ${position} txt-base overflow-y-scroll border border-blue-dark rounded-md bg-white divide-y divide-blue-light focus:outline-none sm:text-sm`}>
              {options.map((option, i) => (
                <Listbox.Option key={i} value={option}>
                  {({ active }) => (
                    <div className={`${active ? "bg-gray-hover" : "bg-transparent"} select-none list-none px-3 py-2`}>
                      <p className={`block truncate text-sm ${value === option ? "font-semibold" : "font-normal"}`}>{labels(option)}</p>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </>
    )}
  </Listbox>
);
