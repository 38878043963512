import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import validator from "validator";
import { toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import api from "../../services/api";
import { Loader } from "../../components/loader";
import Logo from "../../assets/img/logo-bleu.png";

const Signup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const validate = (values, setErrors) => {
    const errors = {};
    if (!validator.isEmail(values.email)) errors.email = "Adresse email invalide";
    if ((values.password || "").length < 8) errors.password = "Le mot de passe doit contenir 8 caractères minimum";
    if (!values.generalConditions) errors.generalConditions = "Vous devez accepter les conditions générales";
    setErrors(errors);
    return !errors.email && !errors.password && !errors.generalConditions;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (loading) return;
    if (!validate(values, setErrors)) return;
    setLoading(true);

    api
      .post("/auth/signup", values)
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          navigate("/email-sent", { state: { email: values.email } });
        } else {
          if (res.code === "MAIL_ALREADY_USED") setErrors({ email: "Cette adresse email est déjà utilisée" });
          else setErrors({ email: "Une erreur est survenue" });
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Une erreur est survenue");
      });
  };

  return (
    <div className="flex flex-col items-center">
      <img src={Logo} alt="Seco2" className="w-44" />
      <p className="text-gray-500">Inscrivez-vous</p>
      <Formik
        initialValues={{
          email: "",
          password: "",
          generalConditions: false,
        }}
        onSubmit={handleSubmit}>
        {({ values, errors, handleChange }) => (
          <Form className="my-4 flex flex-col items-center w-80">
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-1" htmlFor="email">
                E-mail
              </label>
              <input className="input border border-gray-400" name="email" value={values.email} onChange={handleChange} placeholder="john@ugc.fr" />
              {errors.email && <div className="flex items-center text-sm text-red-500">{errors.email}</div>}
              {values.email?.match(/@cnc\.fr$/) && (
                <p className="mt-1 mb-1">
                  Membre du CNC ? Utilisez{" "}
                  <Link to="/signup-cnc" className="underline">
                    ce lien
                  </Link>
                </p>
              )}
            </div>
            <div className="mb-5 flex flex-col w-full">
              <label className="mb-1" htmlFor="password">
                Mot de passe
              </label>
              <div className="relative w-full">
                <input
                  className="input w-full border border-gray-400 pr-10"
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Votre mot de passe"
                />
                {passwordVisible ? (
                  <AiFillEyeInvisible className="absolute right-3 top-1.5 z-10 cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(false)} />
                ) : (
                  <AiFillEye className="absolute right-3 top-1.5 z-10 cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(true)} />
                )}
              </div>
              {errors.password && <div className="flex items-center text-sm text-red-500">{errors.password}</div>}
            </div>

            <div className="mb-5 flex flex-col w-full">
              <div className="w-full flex items-center">
                <input type="checkbox" name="generalConditions" className="checkbox" onChange={handleChange} />
                <label className="ml-3">
                  J'accepte les{" "}
                  <a href="/cgs.pdf" className="bold cursor-pointer font-bold text-[#2791B5] hover:underline" rel="noreferrer" target="_blank">
                    conditions générales
                  </a>
                </label>
              </div>
              {errors.generalConditions && <div className="flex items-center text-sm text-red-500">{errors.generalConditions}</div>}
            </div>
            <button className="blue-button my-8 text-lg" type="submit" disabled={loading}>
              {loading ? <Loader color="white" /> : "M'inscrire"}
            </button>
          </Form>
        )}
      </Formik>
      <p className="w-full text-left">
        Déjà un compte ?{" "}
        <Link to="/signin" className="font-bold text-[#2791B5] hover:underline">
          Connectez-vous
        </Link>
      </p>
      <p className="w-full text-left">
        Vous êtes un membre du CNC ?{" "}
        <Link to="/signup-cnc" className="font-bold text-[#2791B5] hover:underline">
          Demandez votre accès
        </Link>
      </p>
    </div>
  );
};
export default Signup;
