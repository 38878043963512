import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { deleteSheet } from "./utils";

import NotFound from "./components/partials/NotFound";

import useStore from "./Stores/zustand";

import { AdminNavBar, NavBar } from "./components/navbar";
import { AdminHeader, Header } from "./components/header";
import NPSModal from "./components/NPSModal";

import Signin from "./scenes/auth/signin";
import Signup from "./scenes/auth/signup";
import CncSignup from "./scenes/auth/signup-cnc";
import EmailSent from "./scenes/auth/email-sent";
import VerifyEmail from "./scenes/auth/verify-email";
import ForgotPassword from "./scenes/auth/forgot-password";
import ResetPassword from "./scenes/auth/reset-password";
import LoginAs from "./scenes/auth/loginas";
import Admin from "./scenes/admin";
import Estimation from "./scenes/estimation";
import Bilan from "./scenes/bilan";
import Account from "./scenes/account";
import Stats from "./scenes/Stats";
import Help from "./scenes/help";
import Contact from "./scenes/contact";
import Dashboard from "./scenes/dashboard";

import api from "./services/api";
import { Loader } from "components/loader";
import AuthBg from "./assets/img/auth-bg.jpg";

const AuthLayout = () => {
  const { user } = useStore();
  const location = useLocation();
  if (user && !location.pathname.includes("loginas")) return <Navigate to="/" replace={true} />;
  return (
    <div className="w-screen h-screen flex">
      <section className="w-1/2 bg-white flex justify-center items-center p-12">
        <Outlet />
      </section>
      <section className="w-1/2 flex flex-col items-center justify-center bg-[#E2F3F9] p-12">
        <h1 className="text-3xl font-bold text-center text-blue-dark mb-8">Réalisez le bilan carbone de votre tournage</h1>
        {/* <img src="/images/results_alt.png" alt="Seco2" className="" /> */}
        <img src={AuthBg} alt="Seco2" className="w-[90%] rounded-xl" />
      </section>
    </div>
  );
};

const ProtectedLayout = () => {
  const { user, setUser } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api
      .get("/auth/is-loggedin")
      .then((res) => {
        if (res.ok) {
          setUser(res.data);
        } else {
          setUser(null);
          navigate("/signin");
        }
        setLoading(false);
      })
      .catch(() => setUser(null));
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (!user) return <Navigate to="/signin" replace={true} />;
  if (user.role === "cncUnverified") {
    return (
      <div className="h-full flex justify-center w-full bg-blue-light">
        <div className="bg-white m-6 p-8">
          <h1 className="text-5xl">Compte CNC en cours de validation</h1>
          <p className="mt-5">Votre compte est en cours de validation par l'équipe Secoya.</p>
          <p>Cela peut prendre jusqu'à 48h, vous recevrez un email quand le compte sera prêt.</p>
          <p className="mt-9">
            Si vous n'avez pas de retour au délà des 48h, contactez notre équipe:{" "}
            <a href="mailto:support@flyingsecoya.io" className="cursor-pointer font-bold hover:underline">
              support@flyingsecoya.io
            </a>
          </p>
        </div>
      </div>
    );
  }

  if (user.role !== "cnc" && (!user.firstName || !user.lastName) && location.pathname !== "/") {
    return <Navigate to="/" replace={true} />;
  }
  return (
    <div className="h-screen min-w-[1024px] w-full bg-blue-light">
      <Outlet />
    </div>
  );
};

const MainLayout = () => {
  const { user } = useStore();
  if (!user) return <Navigate to="/signin" replace={true} />;
  return (
    <div className="min-w-[1024px] w-full h-full bg-blue-light">
      <NPSModal />
      <Header user={user} />
      <div className="flex items-start w-full max-w-[1366px] h-full mx-auto">
        <div className="flex flex-col sticky p-4 w-1/4">
          <NavBar user={user} />
        </div>
        <main className="flex-1">
          <div className="pr-8 py-10">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

const AdminLayout = () => {
  const { user } = useStore();
  if (!user || (user.role !== "admin" && user.role !== "superAdmin")) return <Navigate to="/" replace={true} />;
  return (
    <div className="min-w-[1024px] w-full  h-full bg-blue-light">
      <AdminHeader user={user} />
      <div className="flex items-start w-full max-w-[1366px] h-full mx-auto">
        <div className="flex flex-col sticky top-24 px-4 w-1/4">
          <AdminNavBar />
        </div>
        <main className="flex-1 overflow">
          <div className="pr-8 pt-10">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

const App = () => {
  // window.addEventListener("beforeunload", (e) => deleteSheet(e));
  console.log("App");
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signup-cnc" element={<CncSignup />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/email-sent" element={<EmailSent />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/help" element={<Help />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/account" element={<Account />} />
            <Route path="/account/:session_id" element={<Account />} />
          </Route>
          <Route path="/bilan/*" element={<Bilan />} />
          <Route path="/estimation/*" element={<Estimation />} />
          <Route element={<AdminLayout />}>
            <Route path="/admin/*" element={<Admin />} />
          </Route>
        </Route>
        <Route path="/loginas" element={<LoginAs />} />
        <Route path="*" component={NotFound} />
      </Routes>
    </>
  );
};

export default App;
