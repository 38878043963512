import React from "react";
import { useField, Field } from "formik";

const AppInput = (props) => {
  const { label, className, required, disabled, type, id, autoComplete, placeholder, ...restProps } = props;

  const [field, meta] = useField(restProps);

  const errorText = meta.error && meta.touched ? meta.error : "";

  if (type === "textarea") {
    return (
      <div className="leading-5 font-normal flex relative flex-col flex-1">
        <label htmlFor={id || field.name}>
          {label}
          {required ? "*" : ""}
        </label>
        <textarea
          {...field}
          disabled={disabled}
          autoComplete={autoComplete}
          className="AppInput bg-backgrounds font-mainpolice border-none rounded-sm h-[150px] text-base p-2"
          placeholder={placeholder || ""}
        />
        {errorText && <p className="text-red-validation top-full absolute text-sm font-semibold">{errorText}</p>}
      </div>
    );
  }

  if (type === "checkbox") {
    return (
      <>
        <Field {...field} type="checkbox" checked={field.value} />
        {errorText && <p className="text-red-validation top-full absolute text-sm font-semibold">{errorText}</p>}
      </>
    );
  }

  if (type === "radio") {
    return (
      <>
        <Field {...field} value={props.value} type="radio" />
        {errorText && <p className="text-red-validation top-full absolute text-sm font-semibold">{errorText}</p>}
      </>
    );
  }

  return (
    <div className="flex flex-col leading-5 relative font-normal flex-1">
      <label htmlFor={id || field.name}>
        {label}
        {required ? "*" : ""}
      </label>
      <input
        {...field}
        disabled={disabled}
        autoComplete={autoComplete}
        className={`bg-off-white font-mainpolice border-none rounded-sm h-9 text-base font-normal p-2 text-inherit disabled:bg-back-light-blue disabled:text-back-light-blue ${
          className || ""
        }`}
        placeholder={placeholder || ""}
        type={type || "text"}
        {...(props.min ? { min: props.min } : {})}
      />
      {errorText && (
        <p
          // className="TextField__helper-text--error"
          className="text-red-validation top-full absolute text-sm font-semibold">
          {errorText}
        </p>
      )}
    </div>
  );
};

export default AppInput;
