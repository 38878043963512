import React from "react";
import { badgeInfosCorrespondance, getShortName } from "../../utils/config";
import { formatNumber, formatAndConvertNumber } from "../../utils";

const MovieBadge = ({ movieType, color }) => {
  const badgeInfos = badgeInfosCorrespondance[getShortName(movieType)];
  const number = badgeInfos.name;
  return <StatisticBadge badgeInfos={badgeInfos} number={number} color={color} />;
};

const StatisticBadge = (props) => {
  const { type, number, unit, color, badgeInfos } = props;
  const { icon, unit: unitFromBadge, precision, number: numberFromBadge } = badgeInfos ? badgeInfos : badgeInfosCorrespondance[type];

  const finalUnit = unit ? unit : unitFromBadge;
  const backgroundColor = color ? color : "var(--backLightBlue)";
  const finalNumber = typeof number !== "undefined" ? formatNumber(number) : numberFromBadge;
  const className = type === "equivalence" ? "equivalence" : "not-equivalence";

  return (
    <div className={className} style={{ backgroundColor: backgroundColor }}>
      <div className="wrapper-card-dashboard">
        <img src={`/images/icons/${icon}.svg`} alt={icon} className="row-span-2" />
      </div>
      <div>
        <p>
          {finalNumber} <span>{finalUnit}</span>
        </p>
        <p>{precision}</p>
        {type === "equivalence" && <span className="text-center text-xs leading-3 italic bg-blue-mid py-px px-2.5 mt-px">en équivalence carbone</span>}
      </div>
    </div>
  );
};

const StatisticsBox = ({ movieType, totalAmount, equivalenceInfos, totalEmissions, color, scope }) => {
  const { number: emissions, unit: emissionsUnit } = formatAndConvertNumber(totalEmissions, "emissions");
  const { number: amount, unit: amountUnit } = formatAndConvertNumber(totalAmount, "amount");
  return (
    <>
      {totalAmount > 0 && (
        <section className="grid grid-cols-4 items-center gap-5">
          {movieType && <MovieBadge movieType={movieType} color={color} />}
          <StatisticBadge number={emissions} unit={emissionsUnit} color={color} type={"totalEmissions_" + scope} />
          <StatisticBadge number={amount} unit={amountUnit} color={color} type={"totalAmount_" + scope} />
          <StatisticBadge badgeInfos={equivalenceInfos} color={color} type={"equivalence"} />
        </section>
      )}
      {totalAmount === 0 && (
        <section className="grid grid-cols-2 gap-5">
          <StatisticBadge number={emissions} unit={emissionsUnit} color={color} type={"totalEmissions_" + scope} />
        </section>
      )}
    </>
  );
};

export { StatisticBadge, StatisticsBox };
