import React, { useState } from "react";
import api from "services/api";
import TextField from "components/Base/TextField";
import { Formik, Form } from "formik";

import useStore from "Stores/zustand";
import InfoBox from "components/Base/InfoBox";
import MyIcon from "components/partials/MyIcon";
import { Modal } from "components/modal";

const ModalNewUser = ({ isOpen, close, fetchUsers }) => {
  const { user, setUser } = useStore();

  const [error, setError] = useState(null);

  const handleSubmit = (data, actions) => {
    if (data.credits > user.credits) {
      setError({ status: 400, message: "Crédits insuffisants" });
    } else {
      api
        .post("/admin/user", { ...data, adminId: user._id })
        .then(async (res) => {
          if(res.ok){
            const newCredits = user.credits - data.credits;
            const res2 = await api.put(`/auth`, { credits: newCredits });
            if(res2.ok){
              setUser(res2);
            }
            setError({ message: res.message });
            fetchUsers();
            actions.resetForm();
          } 
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <h2 className="mb-6 font-bold text-2xl leading-8">Nouvel Utilisateur</h2>
      <p className="text-base leading-5 font-normal mb-4">
        Entrez les informations de votre nouvel utilisateur.
        <br />
        <br />
        Un email lui sera envoyé pour lui signifier l'ouverture de son compte avec
        <br />
        un mot de passe généré aléatoirement.
      </p>

      {error && <InfoBox error={error}>{error.message}</InfoBox>}

      <Formik
        initialValues={{
          email: "",
          firstName: "",
          lastName: "",
          role: "user",
          credits: 0,
        }}
        validateOnBlur={true}
        onSubmit={handleSubmit}>
        <Form id="signin" className="my-4">
          {user.role === "superAdmin" && (
            <>
              <p>Rôle de l'utilisateur</p>
              <div className="flex items-center mb-5">
                <label>
                  <TextField name="role" type="radio" value="user" />
                  User
                </label>
                <label>
                  <TextField name="role" type="radio" value="admin" />
                  Admin
                </label>
              </div>
            </>
          )}

          <div className="flex items-center mb-5">
            <TextField label="E-mail*" type="email" name="email" />
          </div>
          <div className="flex items-center mb-5 gap-5">
            <TextField label="Prénom*" name="firstName" type="text" />
            <TextField label="Nom*" name="lastName" type="text" />
          </div>
          <div className="flex items-center mb-5">
            <TextField label={`Nombre de crédits* (crédits disponibles : ${user.credits})`} name="credits" type="number" />
          </div>

          <div className="mt-10 w-2/3 flex items-center justify-end gap-4 ml-auto">
            <button className="empty-button flex items-center justify-center gap-2 pl-2 w-full" onClick={close}>
              <MyIcon icon="back" />
              Retour
            </button>
            <button className="blue-button flex items-center justify-center gap-2 pl-2 w-full" type="submit">
              <MyIcon icon="check" />
              Valider
            </button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ModalNewUser;
