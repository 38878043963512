import React from "react";
import { PieChart, Pie as PieRechart, Cell, Tooltip as TooltipRechart, ResponsiveContainer } from "recharts";

const Tooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const legend = payload[0].payload.legend ? payload[0].payload.legend : payload[0].value.toFixed(2);
    return (
      <div className="bg-white text-black opacity-80 p-2 min-w-32 text-center">
        <p className="text-sm whitespace-nowrap">
          {payload[0].name}: <b>{legend}</b>
        </p>
      </div>
    );
  }

  return null;
};

export const Pie = ({ data, dataKey = "value", nameKey = "name", cx = "50%", cy = "50%", outerRadius = "100%", innerRadius = "50%", paddingAngle = 0.2 }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <PieRechart data={data} dataKey={dataKey} nameKey={nameKey} cx={cx} cy={cy} outerRadius={outerRadius} innerRadius={innerRadius} paddingAngle={paddingAngle}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </PieRechart>
        <TooltipRechart content={<Tooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};
